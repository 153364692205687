<template>
  <section class="subscribe">
    <div class="container">
      <div class="subscribe-wrapper">
        <div class="subscribe-text">
          <h2>Subscribe for Exclusive Access to Free Templates!</h2>
          <form @submit.prevent="subscribeUser" >
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Enter your email address"
                v-model="subscribe.email"
              />

              <span class="input-group-text">
                <button type="submit" :disabled="isLoading">
                  Subscribe
                  <p
                    v-if="isLoading"
                    class="spinner-border spinner-border-sm ms-1 mb-0"
                    role="status"
                    aria-hidden="true"
                  ></p>
                </button>
              </span>
            </div>
            <p class="text-danger">
              {{ errors.email && errors.email.join(", ") }}
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "SubscribePage",
  data() {
    return {
      subscribe: {
        email: "",
      },
      errors: {},
      isLoading: false,
    };
  },
  methods: {
    subscribeUser() {
      this.isLoading = true;
      axios
        .post(process.env.VUE_APP_API_URL + "/subscribe", this.subscribe)
        .then((response) => {
          response.data;
          this.subscribe.email = "";
          this.errors = {};
          this.showToast("Subscribe successful!");
          this.isLoading = false;

          setTimeout(() => {
           window.location.reload();
          }, 1200);
        })
        .catch((errors) => {
          this.errors = errors.response.data.errors;
          this.isLoading = false;
          toast("Subscribe Failed", {
            autoClose: 1000,
            type: "error",
            position: "top-right",
            pauseOnHover: false,
            closeOnClick: false,
          });
        });
    },
    showToast(message) {
      toast(message, {
        autoClose: 1000,
        type: "success",
        position: "top-right",
        pauseOnHover: false,
        closeOnClick: false,
      });
    },
  },
};
</script>