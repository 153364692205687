<template>
    <div class="row">
        <div class="col-xl-4 col-lg-5 col-md-12">
            <div class="login-form-wrapper forgot-form-wrapper">
                <a class="login-logo" href="/">
                    <img src="../../assets/logo.png" alt="logoss" />
                </a>
                <div class="login-from">
                    <h3>
                        Reset Your Password and Regain Access in a Few Simple
                        Steps.
                    </h3>

                    <form @submit.prevent="sendResetLink" novalidate>
                        <div class="mb-3">
                            <label class="form-label">Email</label>
                            <input
                                type="email"
                                class="form-control"
                                placeholder="Enter username or email"
                                v-model="email"
                            />
                        </div>

                        <button
                            type="submit"
                            class="btn btn-primary submitBtn"
                            :disabled="isLoading"
                        >
                            Send reset link
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        </button>
                    </form>

                    <p class="not-login">
                        Remembered your password?
                        <router-link to="/login">Login here</router-link>
                    </p>
                </div>

                <div class="login-footer">
                    © 2024 All Rights Reserved, Bellboost
                </div>
            </div>
        </div>
        <div
            class="col-xl-8 col-lg-7 col-md-12 login-bg-img forgot-bg-img"
        ></div>
    </div>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";

export default {
    data() {
        return {
            email: "",
            isLoading: false,
        };
    },
    methods: {
        sendResetLink() {
            this.isLoading = true;
            // const token = JSON.parse(localStorage.getItem("TOKEN"));
            axios
                .post(process.env.VUE_APP_API_URL + "/forgot-password", {
                    email: this.email,
                    // headers: {
                    //     Authorization: `Bearer ${token.token}`,
                    //     "Content-Type": "application/json",
                    // },
                })
                .then(() => {
                    toast("Reset link sent to your email.", {
                        autoClose: 3000,
                        type: "success",
                    });
                    this.isLoading = false;
                })
                .catch(() => {
                    this.isLoading = false;

                    toast("Unable to send reset link.", {
                        autoClose: 3000,
                        type: "error",
                    });
                });
        },
    },
};
</script>
