<template>
    <span>
        <section class="edit-template" style="border-top: 1px solid #ececec">
            <div class="container m-auto">
                <div class="edit-template-haeder">
                    <div>
                        <h2 class="section-heading mb">
                            {{ transformTitle($route.query.title) }}
                        </h2>
                    </div>

                    <div>
                        <button
                            type="button"
                            class="mx-2"
                            @click="openShareModal"
                            :disabled="loading"
                        >
                            <svg
                                width="13"
                                height="16"
                                viewBox="0 0 13 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M5.92556 0.234232C6.07793 0.0842531 6.28455 0 6.5 0C6.71545 0 6.92207 0.0842531 7.07444 0.234232L9.51194 2.63426C9.65994 2.78514 9.74184 2.98722 9.73998 3.19698C9.73813 3.40674 9.65268 3.6074 9.50204 3.75572C9.3514 3.90405 9.14761 3.98819 8.93458 3.99001C8.72154 3.99183 8.5163 3.9112 8.36306 3.76547L7.3125 2.73106V10.3999C7.3125 10.6121 7.2269 10.8156 7.07452 10.9656C6.92215 11.1157 6.71549 11.1999 6.5 11.1999C6.28451 11.1999 6.07785 11.1157 5.92548 10.9656C5.7731 10.8156 5.6875 10.6121 5.6875 10.3999V2.73106L4.63694 3.76547C4.4837 3.9112 4.27846 3.99183 4.06542 3.99001C3.85239 3.98819 3.6486 3.90405 3.49796 3.75572C3.34732 3.6074 3.26187 3.40674 3.26002 3.19698C3.25816 2.98722 3.34006 2.78514 3.48806 2.63426L5.92556 0.234232ZM0 7.19991C0 6.77556 0.171205 6.36858 0.475951 6.06852C0.780698 5.76846 1.19402 5.59989 1.625 5.59989H3.25C3.46549 5.59989 3.67215 5.68418 3.82452 5.83421C3.9769 5.98424 4.0625 6.18772 4.0625 6.3999C4.0625 6.61207 3.9769 6.81556 3.82452 6.96559C3.67215 7.11562 3.46549 7.19991 3.25 7.19991H1.625V14.4H11.375V7.19991H9.75C9.53451 7.19991 9.32785 7.11562 9.17548 6.96559C9.0231 6.81556 8.9375 6.61207 8.9375 6.3999C8.9375 6.18772 9.0231 5.98424 9.17548 5.83421C9.32785 5.68418 9.53451 5.59989 9.75 5.59989H11.375C11.806 5.59989 12.2193 5.76846 12.524 6.06852C12.8288 6.36858 13 6.77556 13 7.19991V14.4C13 14.8243 12.8288 15.2313 12.524 15.5314C12.2193 15.8314 11.806 16 11.375 16H1.625C1.19402 16 0.780698 15.8314 0.475951 15.5314C0.171205 15.2313 0 14.8243 0 14.4V7.19991Z"
                                    fill="#7C2CE7"
                                />
                            </svg>
                            Share
                        </button>
                        <button
                            type="button"
                            @click="openModal"
                            :disabled="loading"
                        >
                            <svg
                                width="18"
                                height="15"
                                viewBox="0 0 18 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M4.59364 3.76539L4.48544 3.6524L4.3771 3.76527L0.891787 7.39613L0.792076 7.5L0.891787 7.60387L4.3771 11.2347L4.48544 11.3476L4.59364 11.2346L5.32313 10.4729L5.42234 10.3693L5.32328 10.2656L2.68258 7.5L5.32328 4.73445L5.42234 4.63071L5.32313 4.52711L4.59364 3.76539ZM13.6229 3.76527L13.5146 3.6524L13.4064 3.76539L12.6769 4.52711L12.5777 4.63071L12.6767 4.73445L15.3174 7.5L12.6767 10.2656L12.5777 10.3693L12.6769 10.4729L13.4064 11.2346L13.5146 11.3476L13.6229 11.2347L17.1082 7.60387L17.2079 7.5L17.1082 7.39613L13.6229 3.76527ZM6.23907 13.9544L6.17662 14.15H6.38197H7.46809H7.57766L7.61098 14.0456L11.7609 1.04562L11.8234 0.85H11.618H10.5319H10.4223L10.389 0.954384L6.23907 13.9544Z"
                                    fill="#7C2CE7"
                                    stroke="#7C2CE7"
                                    stroke-width="0.3"
                                />
                            </svg>

                            Embed
                        </button>
                    </div>
                </div>

                <div class="row" ref="embedRef">
                    <div class="col-lg-8 col-md-12 d-flex align-items-center">
                        <div
                            class="template mx-auto d-flex align-items-center justify-content-center"
                            ref="downloadRef"
                            style="
                                border: 2px solid #e9ebff;
                                padding: 16px;
                                border-radius: 8px;
                            "
                        >
                            <div
                                class="template-img"
                                id="imgBox"
                                ref="imgBoxRef"
                                :style="{
                                    backgroundImage: `url('${currentImage}')`,
                                    width: imgBoxWidth + 'px',
                                    height: imgBoxHeight + 'px',
                                }"
                                v-if="!loading"
                            >
                                <div
                                    class="image-area"
                                    ref="showIndex"
                                    :style="{
                                        width: uploadImageWidth + 'px',
                                        height: uploadImageHeight + 'px',
                                        top: uploadImageTop + 'px',
                                        left: uploadImageLeft + 'px',
                                    }"
                                    v-if="selectedImageSrc !== null"
                                >
                                    <div class="wrapper" ref="wrapper">
                                        <div class="flip" ref="imgFlip">
                                            <img
                                                class="selectedImage drag"
                                                :src="selectedImageSrc"
                                                alt="Uploaded Image"
                                                ref="imgDrag"
                                                @mousedown="startDrag"
                                                @mouseup="stopDrag"
                                                @click="onDrag"
                                                @touchstart="startTouch"
                                                @touchend="stopTouch"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="image-area-clone"
                                    ref="showIndex"
                                    :style="{
                                        width: uploadImageWidth + 'px',
                                        height: uploadImageHeight + 'px',
                                        top: uploadImageTop + 'px',
                                        left: uploadImageLeft + 'px',
                                    }"
                                >
                                    <div
                                        class="wrapper-clone"
                                        ref="wrappers"
                                        v-if="selectedImageSrc !== null"
                                    >
                                        <div
                                            @mousedown="startDrag"
                                            @mouseup="stopDrag"
                                            @click="onDrag"
                                            @touchstart="startTouch"
                                            @touchend="stopTouch"
                                            style="width: 100%; height: 100%"
                                        ></div>
                                    </div>
                                </div>
                                <div
                                    class="image-text-drag"
                                    ref="cardDragWrapper"
                                    v-if="cardText !== ''"
                                >
                                    <div
                                        class="image-text"
                                        ref="cardDrag"
                                        @mousedown="startDragText"
                                        @mouseup="stopDragText"
                                        @click="onDragText"
                                        @touchstart="startTouchText"
                                        @touchend="stopTouchText"
                                        :style="{ backgroundColor: bgColor }"
                                    >
                                        <div
                                            class="text"
                                            :style="{ color: textColor }"
                                            ref="textFontS"
                                            v-html="formattedCardText"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="text-center my-5">
                                <div
                                    class="spinner-border text-primary"
                                    role="status"
                                >
                                    <span class="visually-hidden"
                                        >Loading...</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="d-flex align-items-start banner-tabs">
                            <div
                                class="nav flex-column nav-pills"
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                            >
                                <button
                                    class="nav-link active"
                                    id="v-pills-templates-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-templates"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-templates"
                                    aria-selected="true"
                                >
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M14 7C15.6569 7 17 5.65685 17 4C17 2.34315 15.6569 1 14 1C12.3431 1 11 2.34315 11 4C11 5.65685 12.3431 7 14 7Z"
                                            stroke="#7C2CE7"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M4 17C5.65685 17 7 15.6569 7 14C7 12.3431 5.65685 11 4 11C2.34315 11 1 12.3431 1 14C1 15.6569 2.34315 17 4 17Z"
                                            stroke="#7C2CE7"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M11 11H17V16C17 16.2652 16.8946 16.5196 16.7071 16.7071C16.5196 16.8946 16.2652 17 16 17H12C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16V11ZM1 1H7V6C7 6.26522 6.89464 6.51957 6.70711 6.70711C6.51957 6.89464 6.26522 7 6 7H2C1.73478 7 1.48043 6.89464 1.29289 6.70711C1.10536 6.51957 1 6.26522 1 6V1Z"
                                            stroke="#7C2CE7"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                    <p>Templates</p>
                                    <img
                                        class="upload-icon"
                                        src="../assets/tap.gif"
                                        :class="{
                                            none:
                                                selectedImageSrc === null ||
                                                currentImage !== null,
                                        }"
                                    />
                                </button>
                                <button
                                    class="nav-link"
                                    id="v-pills-upload-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-upload"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-upload"
                                    aria-selected="false"
                                    @click="handleUploadClick"
                                    v-if="!loading"
                                >
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M17 9.00195C19.175 9.01395 20.353 9.11095 21.121 9.87895C22 10.758 22 12.172 22 15V16C22 18.829 22 20.243 21.121 21.122C20.243 22 18.828 22 16 22H8C5.172 22 3.757 22 2.879 21.122C2 20.242 2 18.829 2 16V15C2 12.172 2 10.758 2.879 9.87895C3.647 9.11095 4.825 9.01395 7 9.00195"
                                            stroke="#555555"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M12 15V2M12 2L15 5.5M12 2L9 5.5"
                                            stroke="#555555"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                    <p>Upload</p>
                                    <img
                                        class="upload-icon"
                                        src="../assets/tap.gif"
                                        :class="{
                                            none: selectedImageSrc !== null,
                                        }"
                                    />
                                </button>
                                <button
                                    class="nav-link"
                                    id="v-pills-adjust-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-adjust"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-adjust"
                                    aria-selected="false"
                                    @click="removeHand"
                                   
                                >
                                    <svg
                                        width="18"
                                        height="14"
                                        viewBox="0 0 18 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M10.878 3.75004H1C0.801088 3.75004 0.610322 3.67103 0.46967 3.53037C0.329018 3.38972 0.25 3.19896 0.25 3.00004C0.25 2.80113 0.329018 2.61037 0.46967 2.46971C0.610322 2.32906 0.801088 2.25004 1 2.25004H10.878C11.0333 1.81135 11.3207 1.43157 11.7007 1.16296C12.0807 0.894352 12.5346 0.750122 13 0.750122C13.4654 0.750122 13.9193 0.894352 14.2993 1.16296C14.6793 1.43157 14.9667 1.81135 15.122 2.25004H17C17.1989 2.25004 17.3897 2.32906 17.5303 2.46971C17.671 2.61037 17.75 2.80113 17.75 3.00004C17.75 3.19896 17.671 3.38972 17.5303 3.53037C17.3897 3.67103 17.1989 3.75004 17 3.75004H15.122C14.9667 4.18874 14.6793 4.56852 14.2993 4.83713C13.9193 5.10574 13.4654 5.24997 13 5.24997C12.5346 5.24997 12.0807 5.10574 11.7007 4.83713C11.3207 4.56852 11.0333 4.18874 10.878 3.75004ZM17 11.75C17.1989 11.75 17.3897 11.671 17.5303 11.5304C17.671 11.3897 17.75 11.199 17.75 11C17.75 10.8011 17.671 10.6104 17.5303 10.4697C17.3897 10.3291 17.1989 10.25 17 10.25H7.122C6.96673 9.81135 6.67931 9.43157 6.29929 9.16296C5.91928 8.89435 5.46536 8.75012 5 8.75012C4.53464 8.75012 4.08072 8.89435 3.70071 9.16296C3.32069 9.43157 3.03327 9.81135 2.878 10.25H1C0.801088 10.25 0.610322 10.3291 0.46967 10.4697C0.329018 10.6104 0.25 10.8011 0.25 11C0.25 11.199 0.329018 11.3897 0.46967 11.5304C0.610322 11.671 0.801088 11.75 1 11.75H2.878C3.03281 12.1892 3.32006 12.5695 3.70013 12.8385C4.0802 13.1075 4.53436 13.252 5 13.252C5.46564 13.252 5.9198 13.1075 6.29987 12.8385C6.67994 12.5695 6.96719 12.1892 7.122 11.75H17Z"
                                            fill="#7C2CE7"
                                        />
                                    </svg>

                                    <p>Adjust</p>
                                    <img
                                        v-if="canShow"
                                        class="upload-icon"
                                        src="../assets/tap.gif"
                                        :class="{
                                            none:
                                                selectedImageSrc === null ||
                                                currentImage === null ||
                                                formattedCardText.length > 0,
                                        }"
                                    />
                                </button>
                                <button
                                    class="nav-link"
                                    id="v-pills-text-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-text"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-text"
                                    aria-selected="false"
                                    @click="removeHandText"
                                >
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5 6C5 5.46957 5.21071 4.96086 5.58579 4.58579C5.96086 4.21071 6.46957 4 7 4H17C17.5304 4 18.0391 4.21071 18.4142 4.58579C18.7893 4.96086 19 5.46957 19 6V7C19 7.26522 18.8946 7.51957 18.7071 7.70711C18.5196 7.89464 18.2652 8 18 8C17.7348 8 17.4804 7.89464 17.2929 7.70711C17.1054 7.51957 17 7.26522 17 7V6H13V18H14C14.2652 18 14.5196 18.1054 14.7071 18.2929C14.8946 18.4804 15 18.7348 15 19C15 19.2652 14.8946 19.5196 14.7071 19.7071C14.5196 19.8946 14.2652 20 14 20H10C9.73478 20 9.48043 19.8946 9.29289 19.7071C9.10536 19.5196 9 19.2652 9 19C9 18.7348 9.10536 18.4804 9.29289 18.2929C9.48043 18.1054 9.73478 18 10 18H11V6H7V7C7 7.26522 6.89464 7.51957 6.70711 7.70711C6.51957 7.89464 6.26522 8 6 8C5.73478 8 5.48043 7.89464 5.29289 7.70711C5.10536 7.51957 5 7.26522 5 7V6Z"
                                            fill="#555555"
                                        />
                                    </svg>

                                    <p>Text</p>

                                    <!-- v-if="formattedCardText.length < 0" -->
                                    <!-- ref="showHand" -->
                                    <img
                                        class="upload-icon none"
                                        src="../assets/tap.gif"
                                        :class="{
                                            none:
                                                selectedImageSrc === null ||
                                                currentImage === null ||
                                                formattedCardText.length > 0,
                                        }"
                                    />
                                </button>
                                <button
                                    class="nav-link"
                                    id="v-pills-reset-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-reset"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-reset"
                                    aria-selected="false"
                                >
                                    <svg
                                        width="22"
                                        height="21"
                                        viewBox="0 0 22 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M4.078 6.48696C5.05072 4.80817 6.6004 3.5401 8.43848 2.91885C10.2766 2.29761 12.2778 2.36554 14.0695 3.10999C15.8613 3.85444 17.3214 5.22467 18.178 6.96555C19.0347 8.70644 19.2294 10.6993 18.7261 12.5731C18.2227 14.4469 17.0556 16.0739 15.4419 17.1512C13.8282 18.2285 11.878 18.6826 9.95441 18.4291C8.03082 18.1755 6.26494 17.2315 4.98557 15.7728C3.70621 14.3141 3.00055 12.4402 3 10.5"
                                            stroke="#555555"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M8 6.5H4V2.5"
                                            stroke="#555555"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>

                                    <p>Reset All</p>
                                </button>

                            </div>
                            <div class="tab-content tabContent" id="tabContent">
                                <div
                                    class="tab-pane fade show active"
                                    id="v-pills-templates"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-templates-tab"
                                    tabindex="0"
                                >
                                    <div
                                        class="template_img"
                                        id="productImagesContainer"
                                        v-if="
                                            bannersWithSameCategory || !loading
                                        "
                                    >
                                        <img
                                            v-for="(
                                                image, index
                                            ) in bannersWithSameCategory"
                                            :key="index"
                                            :src="image.banner"
                                            :alt="image.title"
                                            :class="[
                                                'img_slider',
                                                {
                                                    selected:
                                                        index === currentIndex,
                                                },
                                            ]"
                                            @click="
                                                changeImage(index, image.id)
                                            "
                                        />
                                    </div>
                                    <div v-else class="text-center my-3">
                                        <div
                                            class="spinner-border text-primary"
                                            role="status"
                                        >
                                            <span class="visually-hidden"
                                                >Loading...</span
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="tab-pane fade"
                                    id="v-pills-upload"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-upload-tab"
                                    tabindex="0"
                                >
                                    <img
                                        id="selectedImage"
                                        :src="selectedImageSrc"
                                        alt="Uploaded Image"
                                        width="100"
                                        v-if="selectedImageSrc !== null"
                                    />
                                    <input
                                        type="file"
                                        ref="fileInput"
                                        @change="handleFileChange"
                                        style="display: none"
                                    />
                                </div>
                                <div
                                    class="tab-pane fade"
                                    id="v-pills-adjust"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-adjust-tab"
                                    tabindex="0"
                                >
                                    <div class="adjust-btn" v-if="isDesktop">
                                        <div>
                                            <p>Zoom</p>

                                            <button
                                                type="button"
                                                class="reset-btn"
                                                @click="reset('zoom')"
                                            >
                                                <svg
                                                    width="11"
                                                    height="11"
                                                    viewBox="0 0 11 11"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10.9582 5.49996C10.9582 8.37652 8.6264 10.7083 5.74984 10.7083C2.87327 10.7083 0.541504 8.37652 0.541504 5.49996C0.541504 2.6234 2.87327 0.291626 5.74984 0.291626V1.33329C4.7522 1.33341 3.78771 1.69147 3.03169 2.3424C2.27566 2.99333 1.77828 3.89392 1.62996 4.88047C1.48163 5.86702 1.6922 6.87404 2.22339 7.71851C2.75459 8.56297 3.57114 9.18881 4.52464 9.48228C5.47814 9.77574 6.50529 9.71735 7.41939 9.31772C8.33349 8.91809 9.07386 8.20374 9.50593 7.30452C9.938 6.4053 10.0331 5.3809 9.77392 4.41751C9.51474 3.45412 8.91851 2.61571 8.09359 2.05465V3.41663H7.05192V0.291626H10.1769V1.33329H8.87484C9.52199 1.81815 10.0472 2.44718 10.4089 3.17045C10.7705 3.89372 10.9586 4.69132 10.9582 5.49996Z"
                                                        fill="#7C2CE7"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                @click="zoomIn"
                                            >
                                                +
                                            </button>
                                            <button
                                                type="button"
                                                @click="zoomOut"
                                            >
                                                -
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        class="adjust-btn"
                                        :class="isRotateShow ? 'd-none' : ''"
                                    >
                                        <div>
                                            <p>Rotation</p>
                                            <button
                                                type="button"
                                                class="reset-btn"
                                                @click="reset('rotation')"
                                                v-if="isDesktop"
                                            >
                                                <svg
                                                    width="11"
                                                    height="11"
                                                    viewBox="0 0 11 11"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10.9582 5.49996C10.9582 8.37652 8.6264 10.7083 5.74984 10.7083C2.87327 10.7083 0.541504 8.37652 0.541504 5.49996C0.541504 2.6234 2.87327 0.291626 5.74984 0.291626V1.33329C4.7522 1.33341 3.78771 1.69147 3.03169 2.3424C2.27566 2.99333 1.77828 3.89392 1.62996 4.88047C1.48163 5.86702 1.6922 6.87404 2.22339 7.71851C2.75459 8.56297 3.57114 9.18881 4.52464 9.48228C5.47814 9.77574 6.50529 9.71735 7.41939 9.31772C8.33349 8.91809 9.07386 8.20374 9.50593 7.30452C9.938 6.4053 10.0331 5.3809 9.77392 4.41751C9.51474 3.45412 8.91851 2.61571 8.09359 2.05465V3.41663H7.05192V0.291626H10.1769V1.33329H8.87484C9.52199 1.81815 10.0472 2.44718 10.4089 3.17045C10.7705 3.89372 10.9586 4.69132 10.9582 5.49996Z"
                                                        fill="#7C2CE7"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <div class="w-100">
                                            <div
                                                class="d-block"
                                                style="width: 100%"
                                            >
                                                <input
                                                    type="range"
                                                    min="-180"
                                                    max="180"
                                                    value="0"
                                                    class="slider"
                                                    ref="rotateSlider"
                                                />
                                                <div
                                                    ref="rotateValueDisplay"
                                                    class="value-display"
                                                >
                                                    0°
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    !isDesktop && !isRotateShow
                                                "
                                            >
                                                <button
                                                    type="button"
                                                    class="mobile-rotate-btn"
                                                    @click="closeRotate"
                                                >
                                                    <i class="fa fa-check"></i>
                                                </button>
                                                <button
                                                    type="button"
                                                    class="reset-btn mobile-rotate-btn"
                                                    @click="reset('rotation')"
                                                >
                                                    <svg
                                                        width="11"
                                                        height="11"
                                                        viewBox="0 0 11 11"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M10.9582 5.49996C10.9582 8.37652 8.6264 10.7083 5.74984 10.7083C2.87327 10.7083 0.541504 8.37652 0.541504 5.49996C0.541504 2.6234 2.87327 0.291626 5.74984 0.291626V1.33329C4.7522 1.33341 3.78771 1.69147 3.03169 2.3424C2.27566 2.99333 1.77828 3.89392 1.62996 4.88047C1.48163 5.86702 1.6922 6.87404 2.22339 7.71851C2.75459 8.56297 3.57114 9.18881 4.52464 9.48228C5.47814 9.77574 6.50529 9.71735 7.41939 9.31772C8.33349 8.91809 9.07386 8.20374 9.50593 7.30452C9.938 6.4053 10.0331 5.3809 9.77392 4.41751C9.51474 3.45412 8.91851 2.61571 8.09359 2.05465V3.41663H7.05192V0.291626H10.1769V1.33329H8.87484C9.52199 1.81815 10.0472 2.44718 10.4089 3.17045C10.7705 3.89372 10.9586 4.69132 10.9582 5.49996Z"
                                                            fill="#7C2CE7"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="adjust-btn" v-if="isDesktop">
                                        <div>
                                            <p>Transform</p>
                                            <button
                                                type="button"
                                                class="reset-btn"
                                                @click="reset('transform')"
                                            >
                                                <svg
                                                    width="11"
                                                    height="11"
                                                    viewBox="0 0 11 11"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10.9582 5.49996C10.9582 8.37652 8.6264 10.7083 5.74984 10.7083C2.87327 10.7083 0.541504 8.37652 0.541504 5.49996C0.541504 2.6234 2.87327 0.291626 5.74984 0.291626V1.33329C4.7522 1.33341 3.78771 1.69147 3.03169 2.3424C2.27566 2.99333 1.77828 3.89392 1.62996 4.88047C1.48163 5.86702 1.6922 6.87404 2.22339 7.71851C2.75459 8.56297 3.57114 9.18881 4.52464 9.48228C5.47814 9.77574 6.50529 9.71735 7.41939 9.31772C8.33349 8.91809 9.07386 8.20374 9.50593 7.30452C9.938 6.4053 10.0331 5.3809 9.77392 4.41751C9.51474 3.45412 8.91851 2.61571 8.09359 2.05465V3.41663H7.05192V0.291626H10.1769V1.33329H8.87484C9.52199 1.81815 10.0472 2.44718 10.4089 3.17045C10.7705 3.89372 10.9586 4.69132 10.9582 5.49996Z"
                                                        fill="#7C2CE7"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                @click="FlipX"
                                            >
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M0.000258562 14.789C0.00356271 14.9203 0.0437665 15.0481 0.116272 15.1577C0.188777 15.2673 0.290657 15.3542 0.410259 15.4086L6.61026 18.223C6.71694 18.2715 6.83408 18.2924 6.95095 18.2838C7.06782 18.2753 7.18067 18.2375 7.27916 18.174C7.37764 18.1105 7.45861 18.0233 7.51465 17.9204C7.57068 17.8175 7.59998 17.7022 7.59986 17.585L7.58986 2.4136C7.58975 2.28407 7.55371 2.15712 7.48574 2.04686C7.41777 1.9366 7.32055 1.84736 7.20488 1.78907C7.08921 1.73078 6.95964 1.70572 6.83058 1.71669C6.70152 1.72766 6.57803 1.77422 6.47386 1.8512L0.894658 5.9738C0.813656 6.03367 0.746529 6.11032 0.697861 6.19851C0.649193 6.2867 0.62013 6.38435 0.612658 6.4848L0.00185856 14.7192C0.000157861 14.7425 -0.00037614 14.7657 0.000258562 14.789ZM1.43426 14.3358L1.98506 6.9088L6.19046 3.8008L6.19906 16.499L1.43426 14.3358ZM9.49986 20H10.4999V18H9.49986V20ZM9.49986 17H10.4999V15H9.49986V17ZM9.49986 14H10.4999V12H9.49986V14ZM9.49986 11H10.4999V9H9.49986V11ZM9.49986 8H10.4999V6H9.49986V8ZM9.49986 5H10.4999V3H9.49986V5ZM9.49986 2H10.4999V0H9.49986V2ZM12.3999 17.5852C12.3998 17.7024 12.4291 17.8177 12.4851 17.9206C12.5411 18.0234 12.622 18.1106 12.7205 18.1741C12.819 18.2376 12.9318 18.2754 13.0486 18.2839C13.1655 18.2925 13.2826 18.2716 13.3893 18.2232L19.5893 15.4086C19.7089 15.3542 19.8108 15.2673 19.8834 15.1577C19.9559 15.0481 19.9961 14.9204 19.9995 14.789C20.0001 14.7657 19.9996 14.7423 19.9979 14.719L19.3871 6.4848C19.3796 6.38435 19.3505 6.2867 19.3019 6.19851C19.2532 6.11032 19.1861 6.03367 19.1051 5.9738L13.5259 1.8512C13.4217 1.77422 13.2982 1.72766 13.1691 1.71669C13.0401 1.70572 12.9105 1.73078 12.7948 1.78907C12.6792 1.84736 12.5819 1.9366 12.514 2.04686C12.446 2.15712 12.41 2.28407 12.4099 2.4136L12.3999 17.5852Z"
                                                        fill="#555555"
                                                    />
                                                </svg>
                                            </button>
                                            <button
                                                type="button"
                                                @click="FlipY"
                                            >
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g
                                                        clip-path="url(#clip0_28_1738)"
                                                    >
                                                        <path
                                                            d="M0 10.5H2V9.49998H0V10.5ZM3 10.5H5V9.49998H3V10.5ZM6 10.5H8V9.49998H6V10.5ZM9 10.5H11V9.49998H9V10.5ZM12 10.5H14V9.49998H12V10.5ZM15 10.5H17V9.49998H15V10.5ZM18 10.5H20V9.49998H18V10.5ZM5.211 0.000380632C5.07965 0.00368478 4.95189 0.0438886 4.84232 0.116394C4.73274 0.188899 4.64579 0.290779 4.5914 0.410381L1.777 6.61038C1.72852 6.71706 1.7076 6.83421 1.71615 6.95108C1.7247 7.06794 1.76246 7.18079 1.82595 7.27928C1.88945 7.37777 1.97665 7.45874 2.07957 7.51477C2.18248 7.5708 2.29782 7.6001 2.415 7.59998L17.5864 7.58998C17.7159 7.58987 17.8429 7.55383 17.9531 7.48586C18.0634 7.4179 18.1526 7.32067 18.2109 7.205C18.2692 7.08933 18.2943 6.95976 18.2833 6.8307C18.2723 6.70164 18.2258 6.57815 18.1488 6.47398L14.0262 0.894781C13.9663 0.813778 13.8897 0.746651 13.8015 0.697983C13.7133 0.649315 13.6156 0.620252 13.5152 0.612781L5.2808 0.00198063C5.25751 0.000279931 5.23435 -0.00025407 5.211 0.000380632ZM5.6642 1.43438L13.0912 1.98518L16.1992 6.19058L3.501 6.19918L5.6642 1.43438ZM2.4148 12.4C2.29765 12.3999 2.18234 12.4292 2.07945 12.4852C1.97656 12.5412 1.88937 12.6222 1.82588 12.7206C1.76239 12.8191 1.72463 12.9319 1.71605 13.0487C1.70748 13.1656 1.72837 13.2827 1.7768 13.3894L4.5914 19.5894C4.64576 19.709 4.7327 19.8109 4.84228 19.8835C4.95185 19.956 5.07963 19.9963 5.211 19.9996C5.23435 20.0002 5.25771 19.9997 5.281 19.998L13.5152 19.3872C13.6156 19.3797 13.7133 19.3506 13.8015 19.302C13.8897 19.2533 13.9663 19.1862 14.0262 19.1052L18.1488 13.526C18.2258 13.4218 18.2723 13.2983 18.2833 13.1693C18.2943 13.0402 18.2692 12.9106 18.2109 12.795C18.1526 12.6793 18.0634 12.5821 17.9531 12.5141C17.8429 12.4461 17.7159 12.4101 17.5864 12.41L2.4148 12.4Z"
                                                            fill="#555555"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath
                                                            id="clip0_28_1738"
                                                        >
                                                            <rect
                                                                width="20"
                                                                height="20"
                                                                fill="white"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        class="adjuct-btn-wrapper"
                                        v-if="!isDesktop && isRotateShow"
                                    >
                                        <button type="button" @click="zoomIn">
                                            <svg
                                                width="18"
                                                height="19"
                                                viewBox="0 0 18 19"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M17.25 17.8L13.2689 13.8189M13.2689 13.8189C13.9499 13.1379 14.4901 12.3295 14.8586 11.4397C15.2272 10.55 15.4169 9.59638 15.4169 8.63333C15.4169 7.67028 15.2272 6.71665 14.8586 5.82691C14.4901 4.93717 13.9499 4.12872 13.2689 3.44774C12.5879 2.76676 11.7795 2.22658 10.8898 1.85804C10 1.48949 9.04639 1.2998 8.08334 1.2998C7.12029 1.2998 6.16667 1.48949 5.27692 1.85804C4.38718 2.22658 3.57874 2.76676 2.89776 3.44774C1.52245 4.82305 0.749817 6.68836 0.749817 8.63333C0.749817 10.5783 1.52245 12.4436 2.89776 13.8189C4.27306 15.1942 6.13837 15.9668 8.08334 15.9668C10.0283 15.9668 11.8936 15.1942 13.2689 13.8189ZM8.08334 5.88333V11.3833M5.33334 8.63333H10.8333"
                                                    stroke="#555555"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>

                                            Zoom in
                                        </button>
                                        <button type="button" @click="zoomOut">
                                            <svg
                                                width="19"
                                                height="20"
                                                viewBox="0 0 19 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M8.62501 16.9666C12.9283 16.9666 16.4167 13.4783 16.4167 9.17497C16.4167 4.87168 12.9283 1.3833 8.62501 1.3833C4.32172 1.3833 0.833344 4.87168 0.833344 9.17497C0.833344 13.4783 4.32172 16.9666 8.62501 16.9666Z"
                                                    stroke="#555555"
                                                    stroke-width="1.5"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M5.875 9.1748H11.375M14.2267 14.7766L18.1157 18.6655"
                                                    stroke="#555555"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Zoom out
                                        </button>
                                        <button
                                            type="button"
                                            @click="showRotate"
                                        >
                                            <svg
                                                width="22"
                                                height="23"
                                                viewBox="0 0 22 23"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g
                                                    clip-path="url(#clip0_32_2235)"
                                                >
                                                    <path
                                                        d="M6.8475 20.249C3.85 18.819 1.705 15.9131 1.375 12.4665H0C0.4675 18.1131 5.18833 22.5498 10.9542 22.5498C11.165 22.5498 11.3575 22.5315 11.5592 22.5223L8.06667 19.0206L6.8475 20.249ZM11.0458 0.549805C10.835 0.549805 10.6425 0.568138 10.4408 0.586471L13.9333 4.07897L15.1525 2.8598C18.15 4.28064 20.295 7.18647 20.625 10.6331H22C21.5325 4.98647 16.8117 0.549805 11.0458 0.549805ZM14.6667 13.3831H16.5V7.88314C16.5 7.39691 16.3068 6.93059 15.963 6.58678C15.6192 6.24296 15.1529 6.0498 14.6667 6.0498H9.16667V7.88314H14.6667V13.3831ZM7.33333 15.2165V4.21647H5.5V6.0498H3.66667V7.88314H5.5V15.2165C5.5 15.7027 5.69315 16.169 6.03697 16.5128C6.38079 16.8567 6.8471 17.0498 7.33333 17.0498H14.6667V18.8831H16.5V17.0498H18.3333V15.2165H7.33333Z"
                                                        fill="#555555"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath
                                                        id="clip0_32_2235"
                                                    >
                                                        <rect
                                                            width="22"
                                                            height="22"
                                                            fill="white"
                                                            transform="translate(0 0.549805)"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>

                                            Rotation
                                        </button>
                                        <button type="button" @click="FlipX">
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M0.000258562 14.789C0.00356271 14.9203 0.0437665 15.0481 0.116272 15.1577C0.188777 15.2673 0.290657 15.3542 0.410259 15.4086L6.61026 18.223C6.71694 18.2715 6.83408 18.2924 6.95095 18.2838C7.06782 18.2753 7.18067 18.2375 7.27916 18.174C7.37764 18.1105 7.45861 18.0233 7.51465 17.9204C7.57068 17.8175 7.59998 17.7022 7.59986 17.585L7.58986 2.4136C7.58975 2.28407 7.55371 2.15712 7.48574 2.04686C7.41777 1.9366 7.32055 1.84736 7.20488 1.78907C7.08921 1.73078 6.95964 1.70572 6.83058 1.71669C6.70152 1.72766 6.57803 1.77422 6.47386 1.8512L0.894658 5.9738C0.813656 6.03367 0.746529 6.11032 0.697861 6.19851C0.649193 6.2867 0.62013 6.38435 0.612658 6.4848L0.00185856 14.7192C0.000157861 14.7425 -0.00037614 14.7657 0.000258562 14.789ZM1.43426 14.3358L1.98506 6.9088L6.19046 3.8008L6.19906 16.499L1.43426 14.3358ZM9.49986 20H10.4999V18H9.49986V20ZM9.49986 17H10.4999V15H9.49986V17ZM9.49986 14H10.4999V12H9.49986V14ZM9.49986 11H10.4999V9H9.49986V11ZM9.49986 8H10.4999V6H9.49986V8ZM9.49986 5H10.4999V3H9.49986V5ZM9.49986 2H10.4999V0H9.49986V2ZM12.3999 17.5852C12.3998 17.7024 12.4291 17.8177 12.4851 17.9206C12.5411 18.0234 12.622 18.1106 12.7205 18.1741C12.819 18.2376 12.9318 18.2754 13.0486 18.2839C13.1655 18.2925 13.2826 18.2716 13.3893 18.2232L19.5893 15.4086C19.7089 15.3542 19.8108 15.2673 19.8834 15.1577C19.9559 15.0481 19.9961 14.9204 19.9995 14.789C20.0001 14.7657 19.9996 14.7423 19.9979 14.719L19.3871 6.4848C19.3796 6.38435 19.3505 6.2867 19.3019 6.19851C19.2532 6.11032 19.1861 6.03367 19.1051 5.9738L13.5259 1.8512C13.4217 1.77422 13.2982 1.72766 13.1691 1.71669C13.0401 1.70572 12.9105 1.73078 12.7948 1.78907C12.6792 1.84736 12.5819 1.9366 12.514 2.04686C12.446 2.15712 12.41 2.28407 12.4099 2.4136L12.3999 17.5852Z"
                                                    fill="#555555"
                                                />
                                            </svg>
                                        </button>
                                        <button type="button" @click="FlipY">
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g
                                                    clip-path="url(#clip0_28_1738)"
                                                >
                                                    <path
                                                        d="M0 10.5H2V9.49998H0V10.5ZM3 10.5H5V9.49998H3V10.5ZM6 10.5H8V9.49998H6V10.5ZM9 10.5H11V9.49998H9V10.5ZM12 10.5H14V9.49998H12V10.5ZM15 10.5H17V9.49998H15V10.5ZM18 10.5H20V9.49998H18V10.5ZM5.211 0.000380632C5.07965 0.00368478 4.95189 0.0438886 4.84232 0.116394C4.73274 0.188899 4.64579 0.290779 4.5914 0.410381L1.777 6.61038C1.72852 6.71706 1.7076 6.83421 1.71615 6.95108C1.7247 7.06794 1.76246 7.18079 1.82595 7.27928C1.88945 7.37777 1.97665 7.45874 2.07957 7.51477C2.18248 7.5708 2.29782 7.6001 2.415 7.59998L17.5864 7.58998C17.7159 7.58987 17.8429 7.55383 17.9531 7.48586C18.0634 7.4179 18.1526 7.32067 18.2109 7.205C18.2692 7.08933 18.2943 6.95976 18.2833 6.8307C18.2723 6.70164 18.2258 6.57815 18.1488 6.47398L14.0262 0.894781C13.9663 0.813778 13.8897 0.746651 13.8015 0.697983C13.7133 0.649315 13.6156 0.620252 13.5152 0.612781L5.2808 0.00198063C5.25751 0.000279931 5.23435 -0.00025407 5.211 0.000380632ZM5.6642 1.43438L13.0912 1.98518L16.1992 6.19058L3.501 6.19918L5.6642 1.43438ZM2.4148 12.4C2.29765 12.3999 2.18234 12.4292 2.07945 12.4852C1.97656 12.5412 1.88937 12.6222 1.82588 12.7206C1.76239 12.8191 1.72463 12.9319 1.71605 13.0487C1.70748 13.1656 1.72837 13.2827 1.7768 13.3894L4.5914 19.5894C4.64576 19.709 4.7327 19.8109 4.84228 19.8835C4.95185 19.956 5.07963 19.9963 5.211 19.9996C5.23435 20.0002 5.25771 19.9997 5.281 19.998L13.5152 19.3872C13.6156 19.3797 13.7133 19.3506 13.8015 19.302C13.8897 19.2533 13.9663 19.1862 14.0262 19.1052L18.1488 13.526C18.2258 13.4218 18.2723 13.2983 18.2833 13.1693C18.2943 13.0402 18.2692 12.9106 18.2109 12.795C18.1526 12.6793 18.0634 12.5821 17.9531 12.5141C17.8429 12.4461 17.7159 12.4101 17.5864 12.41L2.4148 12.4Z"
                                                        fill="#555555"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath
                                                        id="clip0_28_1738"
                                                    >
                                                        <rect
                                                            width="20"
                                                            height="20"
                                                            fill="white"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div
                                    class="tab-pane fade"
                                    id="v-pills-text"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-text-tab"
                                    tabindex="0"
                                >
                                    <div
                                        class="adjust-btn"
                                        v-if="isDesktop || isShowTextField"
                                    >
                                        <p>Text</p>
                                        <div class="w-100">
                                            <div class="d-block w-100">
                                                <textarea
                                                    class="form-control"
                                                    placeholder="Your text here"
                                                    @input="handleText"
                                                    maxlength="200"
                                                    v-if="isDesktop"
                                                ></textarea>
                                                <input
                                                    @input="handleText"
                                                    v-if="!isDesktop"
                                                    type="text"
                                                    style="
                                                        height: 40px;
                                                        border: 1px solid
                                                            #7c2ce7;
                                                    "
                                                    class="form-control"
                                                    placeholder="Your text here"
                                                />
                                            </div>
                                            <div
                                                v-if="!isDesktop && isTextShow"
                                            >
                                                <button
                                                    type="button"
                                                    class="mobile-rotate-btn"
                                                    @click="closeText"
                                                >
                                                    <i class="fa fa-check"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="adjust-btn"
                                        :class="isTextShow ? 'd-none' : ''"
                                    >
                                        <div>
                                            <p>Text Size</p>
                                            <button
                                                type="button"
                                                class="reset-btn"
                                                @click="reset('textSize')"
                                                v-if="isDesktop"
                                            >
                                                <svg
                                                    width="11"
                                                    height="11"
                                                    viewBox="0 0 11 11"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10.9582 5.49996C10.9582 8.37652 8.6264 10.7083 5.74984 10.7083C2.87327 10.7083 0.541504 8.37652 0.541504 5.49996C0.541504 2.6234 2.87327 0.291626 5.74984 0.291626V1.33329C4.7522 1.33341 3.78771 1.69147 3.03169 2.3424C2.27566 2.99333 1.77828 3.89392 1.62996 4.88047C1.48163 5.86702 1.6922 6.87404 2.22339 7.71851C2.75459 8.56297 3.57114 9.18881 4.52464 9.48228C5.47814 9.77574 6.50529 9.71735 7.41939 9.31772C8.33349 8.91809 9.07386 8.20374 9.50593 7.30452C9.938 6.4053 10.0331 5.3809 9.77392 4.41751C9.51474 3.45412 8.91851 2.61571 8.09359 2.05465V3.41663H7.05192V0.291626H10.1769V1.33329H8.87484C9.52199 1.81815 10.0472 2.44718 10.4089 3.17045C10.7705 3.89372 10.9586 4.69132 10.9582 5.49996Z"
                                                        fill="#7C2CE7"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <div class="w-100">
                                            <div
                                                class="d-block"
                                                style="width: 100%"
                                            >
                                                <input
                                                    type="range"
                                                    min="3"
                                                    max="200"
                                                    value="16"
                                                    class="slider"
                                                    ref="fontSizeSlider"
                                                />
                                                <div
                                                    ref="fontSizeValueDisplay"
                                                    class="value-display"
                                                >
                                                    16px
                                                </div>
                                            </div>
                                            <div
                                                v-if="!isDesktop && !isTextShow"
                                            >
                                                <button
                                                    type="button"
                                                    class="mobile-rotate-btn"
                                                    @click="closeTextSize"
                                                >
                                                    <i class="fa fa-check"></i>
                                                </button>
                                                <button
                                                    type="button"
                                                    class="reset-btn mobile-rotate-btn"
                                                    @click="reset('textSize')"
                                                >
                                                    <svg
                                                        width="11"
                                                        height="11"
                                                        viewBox="0 0 11 11"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M10.9582 5.49996C10.9582 8.37652 8.6264 10.7083 5.74984 10.7083C2.87327 10.7083 0.541504 8.37652 0.541504 5.49996C0.541504 2.6234 2.87327 0.291626 5.74984 0.291626V1.33329C4.7522 1.33341 3.78771 1.69147 3.03169 2.3424C2.27566 2.99333 1.77828 3.89392 1.62996 4.88047C1.48163 5.86702 1.6922 6.87404 2.22339 7.71851C2.75459 8.56297 3.57114 9.18881 4.52464 9.48228C5.47814 9.77574 6.50529 9.71735 7.41939 9.31772C8.33349 8.91809 9.07386 8.20374 9.50593 7.30452C9.938 6.4053 10.0331 5.3809 9.77392 4.41751C9.51474 3.45412 8.91851 2.61571 8.09359 2.05465V3.41663H7.05192V0.291626H10.1769V1.33329H8.87484C9.52199 1.81815 10.0472 2.44718 10.4089 3.17045C10.7705 3.89372 10.9586 4.69132 10.9582 5.49996Z"
                                                            fill="#7C2CE7"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="adjust-btn"
                                        v-if="isDesktop || isShowTextFieldColor"
                                    >
                                        <div>
                                            <p>Text Color</p>
                                            <button
                                                type="button"
                                                class="reset-btn"
                                                @click="reset('textColor')"
                                            >
                                                <svg
                                                    width="11"
                                                    height="11"
                                                    viewBox="0 0 11 11"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10.9582 5.49996C10.9582 8.37652 8.6264 10.7083 5.74984 10.7083C2.87327 10.7083 0.541504 8.37652 0.541504 5.49996C0.541504 2.6234 2.87327 0.291626 5.74984 0.291626V1.33329C4.7522 1.33341 3.78771 1.69147 3.03169 2.3424C2.27566 2.99333 1.77828 3.89392 1.62996 4.88047C1.48163 5.86702 1.6922 6.87404 2.22339 7.71851C2.75459 8.56297 3.57114 9.18881 4.52464 9.48228C5.47814 9.77574 6.50529 9.71735 7.41939 9.31772C8.33349 8.91809 9.07386 8.20374 9.50593 7.30452C9.938 6.4053 10.0331 5.3809 9.77392 4.41751C9.51474 3.45412 8.91851 2.61571 8.09359 2.05465V3.41663H7.05192V0.291626H10.1769V1.33329H8.87484C9.52199 1.81815 10.0472 2.44718 10.4089 3.17045C10.7705 3.89372 10.9586 4.69132 10.9582 5.49996Z"
                                                        fill="#7C2CE7"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <div class="align-items-center">
                                            <p class="display-text">
                                                Select Color
                                            </p>
                                            <input
                                                type="color"
                                                id="favcolor"
                                                class="favcolor"
                                                name="favcolor"
                                                :value="textColor"
                                                @input="updateColor"
                                            />
                                        </div>
                                        <div
                                            v-if="
                                                !isDesktop &&
                                                isShowTextFieldColor
                                            "
                                        >
                                            <button
                                                type="button"
                                                class="mobile-rotate-btn"
                                                @click="closeTextColor"
                                            >
                                                <i class="fa fa-check"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        class="adjust-btn my-3"
                                        v-if="isDesktop || isShowTextFieldColor"
                                    >
                                        <div>
                                            <p>Background Color</p>
                                            <button
                                                type="button"
                                                class="reset-btn"
                                                @click="reset('bgColor')"
                                            >
                                                <svg
                                                    width="11"
                                                    height="11"
                                                    viewBox="0 0 11 11"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10.9582 5.49996C10.9582 8.37652 8.6264 10.7083 5.74984 10.7083C2.87327 10.7083 0.541504 8.37652 0.541504 5.49996C0.541504 2.6234 2.87327 0.291626 5.74984 0.291626V1.33329C4.7522 1.33341 3.78771 1.69147 3.03169 2.3424C2.27566 2.99333 1.77828 3.89392 1.62996 4.88047C1.48163 5.86702 1.6922 6.87404 2.22339 7.71851C2.75459 8.56297 3.57114 9.18881 4.52464 9.48228C5.47814 9.77574 6.50529 9.71735 7.41939 9.31772C8.33349 8.91809 9.07386 8.20374 9.50593 7.30452C9.938 6.4053 10.0331 5.3809 9.77392 4.41751C9.51474 3.45412 8.91851 2.61571 8.09359 2.05465V3.41663H7.05192V0.291626H10.1769V1.33329H8.87484C9.52199 1.81815 10.0472 2.44718 10.4089 3.17045C10.7705 3.89372 10.9586 4.69132 10.9582 5.49996Z"
                                                        fill="#7C2CE7"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <div class="align-items-center">
                                            <p class="display-text">
                                                Select Color
                                            </p>
                                            <input
                                                type="color"
                                                id="favcolor"
                                                class="favcolor"
                                                name="favcolor"
                                                :value="bgColor"
                                                @input="updateBgColor"
                                            />
                                        </div>

                                        <div class="my-3">
                                            <p class="display-text">
                                                Transpparet Color
                                            </p>

                                            <div class="form-check form-switch">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    @change="toggleTransparent"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                !isDesktop &&
                                                isShowTextFieldColor
                                            "
                                        >
                                            <button
                                                type="button"
                                                class="mobile-rotate-btn"
                                                @click="closeTextColor"
                                            >
                                                <i class="fa fa-check"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div
                                        class="adjuct-btn-wrapper"
                                        v-if="
                                            !isDesktop &&
                                            isTextShow &&
                                            !allTextClose
                                        "
                                    >
                                        <button
                                            type="button"
                                            @click="showTextField"
                                        >
                                            <svg
                                                width="19"
                                                height="19"
                                                viewBox="0 0 19 19"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M17.7499 17.8L13.7689 13.8189M13.7689 13.8189C14.4498 13.1379 14.99 12.3295 15.3586 11.4397C15.7271 10.55 15.9168 9.59638 15.9168 8.63333C15.9168 7.67028 15.7271 6.71665 15.3586 5.82691C14.99 4.93717 14.4498 4.12872 13.7689 3.44774C13.0879 2.76676 12.2794 2.22658 11.3897 1.85804C10.5 1.48949 9.54633 1.2998 8.58328 1.2998C7.62023 1.2998 6.6666 1.48949 5.77686 1.85804C4.88712 2.22658 4.07868 2.76676 3.39769 3.44774C2.02239 4.82305 1.24976 6.68836 1.24976 8.63333C1.24976 10.5783 2.02239 12.4436 3.39769 13.8189C4.773 15.1942 6.63831 15.9668 8.58328 15.9668C10.5282 15.9668 12.3936 15.1942 13.7689 13.8189ZM8.58328 5.88333V11.3833M5.83328 8.63333H11.3333"
                                                    stroke="#555555"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Text
                                        </button>
                                        <button
                                            type="button"
                                            @click="showTextSize"
                                        >
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M9.12504 16.9666C13.4283 16.9666 16.9167 13.4783 16.9167 9.17497C16.9167 4.87168 13.4283 1.3833 9.12504 1.3833C4.82175 1.3833 1.33337 4.87168 1.33337 9.17497C1.33337 13.4783 4.82175 16.9666 9.12504 16.9666Z"
                                                    stroke="#555555"
                                                    stroke-width="1.5"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M6.375 9.1748H11.875M14.7267 14.7766L18.6157 18.6655"
                                                    stroke="#555555"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Text Size
                                        </button>
                                        <button
                                            type="button"
                                            @click="showTextColor"
                                        >
                                            <svg
                                                width="23"
                                                height="23"
                                                viewBox="0 0 23 23"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M7.3475 20.249C4.35 18.819 2.205 15.9131 1.875 12.4665H0.5C0.9675 18.1131 5.68833 22.5498 11.4542 22.5498C11.665 22.5498 11.8575 22.5315 12.0592 22.5223L8.56667 19.0206L7.3475 20.249ZM11.5458 0.549805C11.335 0.549805 11.1425 0.568138 10.9408 0.586471L14.4333 4.07897L15.6525 2.8598C18.65 4.28064 20.795 7.18647 21.125 10.6331H22.5C22.0325 4.98647 17.3117 0.549805 11.5458 0.549805ZM15.1667 13.3831H17V7.88314C17 7.39691 16.8068 6.93059 16.463 6.58678C16.1192 6.24296 15.6529 6.0498 15.1667 6.0498H9.66667V7.88314H15.1667V13.3831ZM7.83333 15.2165V4.21647H6V6.0498H4.16667V7.88314H6V15.2165C6 15.7027 6.19315 16.169 6.53697 16.5128C6.88079 16.8567 7.3471 17.0498 7.83333 17.0498H15.1667V18.8831H17V17.0498H18.8333V15.2165H7.83333Z"
                                                    fill="#555555"
                                                />
                                            </svg>
                                            Text Color
                                        </button>
                                    </div>
                                </div>

                                <div
                                    class="tab-pane fade"
                                    id="v-pills-reset"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-reset-tab"
                                    tabindex="0"
                                >
                                    <div class="reset-div">
                                        <p v-if="isDesktop">
                                            Click the button to reset all the
                                            changes you’ve made.
                                        </p>

                                        <button
                                            :style="{
                                                width: !isDesktop ? '100%' : '',
                                            }"
                                            type="button"
                                            @click="resetChanges"
                                        >
                                            Reset All
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="download-banner" >
                            <button type="button" @click="handleDownload" :disabled="isDownloading"
                                :class="{
                                    'btn-disabled': selectedImageSrc === null,
                                }">
                                
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M17 9.00201C19.175 9.01401 20.353 9.11101 21.121 9.87901C22 10.758 22 12.172 22 15V16C22 18.829 22 20.243 21.121 21.122C20.243 22 18.828 22 16 22H8C5.172 22 3.757 22 2.879 21.122C2 20.242 2 18.829 2 16V15C2 12.172 2 10.758 2.879 9.87901C3.647 9.11101 4.825 9.01401 7 9.00201"
                                        stroke="white"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                    />
                                    <path
                                        d="M12 1.99994L12 14.9999M12 14.9999L9 11.4999M12 14.9999L15 11.4999"
                                        stroke="white"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>

                                <span> Download </span>
                                <span
                                    v-if="isDownloading"
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                <img
                                    class="upload-icon"
                                    ref="downloadHand"
                                    v-if="!canShowDownload"
                                    src="../assets/tap.gif"
                                    :class="{
                                        none:
                                            selectedImageSrc === null ||
                                            currentImage === null,
                                    }"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <CategoryPage
            :imageId="$route.query.id"
            @related-template-clicked="changeBannerImage"
        />

        <EmbedModal ref="embedModalRef" :embedCode="embedCode" />
        <shareModal ref="shareModalRef" :embedCode="embedCode" />
    </span>
</template>

<script>
import axios from "axios";
import html2canvas from "html2canvas";
import CategoryPage from "./CategoryPage.vue";
import EmbedModal from "./component/EmbedModal.vue";
import shareModal from "./component/shareModal.vue";
import { Modal } from "bootstrap";

export default {
    name: "EditTemplate",

    components: {
        CategoryPage,
        EmbedModal,
        shareModal,
    },

    data() {
        return {
            currentIndex: 0,
            selectedImageSrc: null,
            banners: [],
            currentImage: "",
            uploadImageWidth: "",
            uploadImageHeight: "",
            uploadImageTop: "",
            uploadImageLeft: "",
            imgBoxWidth: 0,
            imgBoxHeight: 0,
            domRendered: false,
            loading: true,
            rotateValue: 0,
            fontSizeValue: 16,
            scale: 1,
            flipX: 1,
            flipY: 1,
            cardText: "",
            textColor: "#000",
            bgColor: "#f7d031",
            originalBgColor: "#f7d031",
            isTransparent: false,
            isDownloading: false,
            canShow: true,
            canShowDownload: true,
            isDesktop: true,
            isRotateShow: false,
            isTextShow: false,
            allTextClose: false,
            isShowTextField: false,
            isShowTextFieldColor: false,
            embedCode: "",
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        openModal() {
            const id = parseInt(this.$route.query.id);
            const title = encodeURIComponent(this.$route.query.title);
            const foundBanner = this.banners.find((item) => item.id === id);
            this.banner = foundBanner;

            if (this.banner) {
                this.embedCode = `<iframe src="${window.location.origin}/embed?title=${title}&id=${this.banner.id}" width="100%" height="400" frameborder="0" allowfullscreen></iframe>`;
            }
            const modalEl = this.$refs.embedModalRef.$el;
            const modalInstance = new Modal(modalEl);
            modalInstance.show();
        },
        openShareModal() {
            const id = parseInt(this.$route.query.id);
            const title = encodeURIComponent(this.$route.query.title);
            const foundBanner = this.banners.find((item) => item.id === id);
            this.banner = foundBanner;

            if (this.banner) {
                this.embedCode = `${window.location.origin}/edit?title=${title}&id=${this.banner.id}`;
            }
            const modalEl = this.$refs.shareModalRef.$el;
            const modalInstance = new Modal(modalEl);
            modalInstance.show();
        },
        closeTextColor() {
            this.allTextClose = false;
            this.isShowTextFieldColor = false;
        },
        closeTextSize() {
            this.allTextClose = false;
            this.isTextShow = true;
        },
        closeText() {
            this.allTextClose = false;
            this.isShowTextField = false;
        },
        showTextColor() {
            this.isShowTextFieldColor = true;
            this.allTextClose = true;
        },
        showTextSize() {
            this.isTextShow = false;
            this.allTextClose = true;
        },
        showTextField() {
            this.allTextClose = true;
            this.isShowTextField = true;
        },
        showRotate() {
            this.isRotateShow = false;
        },
        closeRotate() {
            this.isRotateShow = true;
        },
        reset(type) {
            if (type === "zoom") {
                this.scale = 1;
                this.updateScale();
            } else if (type === "rotation") {
                this.rotateValue = 0;
                const rotateValueDisplay = this.$refs.rotateValueDisplay;
                const rotateSlider = this.$refs.rotateSlider;
                rotateValueDisplay.textContent = "0°";
                rotateSlider.value = 0;
                this.updateScale();
            } else if (type === "transform") {
                this.flipX = 1;
                this.flipY = 1;
                this.updateFlip();
            } else if (type === "textSize") {
                this.fontSizeValue = 16;
                const fontSizeSlider = this.$refs.fontSizeSlider;
                const fontSizeValueDisplay = this.$refs.fontSizeValueDisplay;
                fontSizeSlider.value = 16;
                fontSizeValueDisplay.textContent = `16px`;
                this.updateFont();
            } else if (type === "textColor") {
                this.textColor = "#000";
                this.updateColor();
            } else if (type === "bgColor") {
                this.bgColor = "#f7d031";
                this.isTransparent = false;
                this.originalBgColor = "#f7d031";
                this.updateBgColor();
            }
        },
        removeHand() {
            this.canShow = false;
        },
        removeHandText() {
            this.canShowDownload = false;
        },
        changeBannerImage(item) {
            let index = this.bannersWithSameCategory.findIndex(
                (banner) => banner.id === item.id
            );
            if (index === -1) {
                index = this.bannersWithSameCategory.findIndex(
                    (banner) => banner.id === item.id
                );
                const selectedBanner = this.banners.find(
                    (banner) => banner.id === item.id
                );
                if (selectedBanner) {
                    this.currentImage = selectedBanner.banner;
                    // this.currentImage = "data:image/png;base64," + selectedBanner.banner;

                    this.banner = selectedBanner;

                    this.updateImageDimensions(selectedBanner);
                    const category = this.banner.category;
                    this.bannersWithSameCategory = this.banners.filter(
                        (item) => item.category === category
                    );
                }
            }
            if (index !== -1) {
                this.bannersWithSameCategory.unshift(
                    this.bannersWithSameCategory.splice(index, 1)[0]
                );
                this.currentIndex = 0;
                const selectedBanner = this.bannersWithSameCategory[0];
                if (selectedBanner) {
                    this.currentImage = selectedBanner.banner;
                    // this.currentImage = "data:image/png;base64," + selectedBanner.banner;
                    this.banner = selectedBanner;
                    this.updateImageDimensions(selectedBanner);
                    this.selectedImageSrc = null;
                }
            }
        },
        changeImage(index, id) {
            this.currentIndex = index;
            const selectedBanner = this.bannersWithSameCategory.find(
                (banner) => banner.id === id
            );
            if (selectedBanner) {
                // this.currentImage = "data:image/png;base64," + selectedBanner.banner;
                this.currentImage = selectedBanner.banner;
                this.banner = selectedBanner;
                this.$route.query.title = selectedBanner.title;
                this.updateImageDimensions(selectedBanner);
            }
        },
        transformTitle(title) {
            return title
                .replace(/\b\w/g, (char) => char.toUpperCase())
                .replace(/-/g, "  ");
        },
        handleWindowResize() {
            // const id = parseInt(this.$route.query.id);
            // const foundBanner = this.banners.find((item) => item.id === id);
            // if (foundBanner) {
            //   this.updateImageDimensions(foundBanner);
            // }
            if (this.banner) {
                this.updateImageDimensions(this.banner);
            }
            if (window.innerWidth >= 992) {
                this.isDesktop = true;
                this.isRotateShow = false;
                this.isTextShow = false;
            } else if (window.innerWidth < 992) {
                this.isDesktop = false;
                this.isRotateShow = true;
                this.isTextShow = true;
            }
        },
        async fetchData() {
            const userData = localStorage.getItem("USER");
            const user = userData ? JSON.parse(userData) : null;
            const userID = user ? user.id : null;
            const id = parseInt(this.$route.query.id);

            try {
                const url =
                    process.env.VUE_APP_API_URL +
                    `/edit-post?post_id=${id}` +
                    (userID ? `&user_id=${userID}` : "");
                const response = await axios.get(url);
                if (response.status === 200) {
                    const posts = response.data.category[0].posts;
                    this.banners = posts;
                    this.loading = false;
                    const foundBanner = this.banners.find(
                        (item) => item.id === id
                    );

                    if (foundBanner) {
                        this.currentImage = foundBanner.banner;
                        this.$nextTick(() => {
                            this.domRendered = true;
                        });
                        this.banner = foundBanner;
                        const category = this.banner.category;
                        this.bannersWithSameCategory = this.banners.filter(
                            (item) => item.category === category
                        );
                        const index = this.bannersWithSameCategory.findIndex(
                            (item) => item.id === id
                        );
                        if (index !== -1) {
                            const [sameIdBanner] =
                                this.bannersWithSameCategory.splice(index, 1);
                            this.bannersWithSameCategory.unshift(sameIdBanner);
                            this.currentIndex = 0;
                        }
                        this.updateImageDimensions(foundBanner);
                    } else {
                        console.log("Banner with ID", id, "not found.");
                    }
                }
            } catch (error) {
                console.error("Error retrieving data:", error);
            }
        },
        updateImageDimensions(banner) {
            if (this.domRendered && this.$refs.downloadRef) {
                const imgBoxElement = this.$refs.downloadRef;
                const bannerImage = new Image();
                bannerImage.src = banner.banner;
                bannerImage.onload = () => {
                    const naturalWidth = bannerImage.naturalWidth;
                    const naturalHeight = bannerImage.naturalHeight;

                    if (banner.bannerWidth < 719 && banner.bannerWidth > 416) {
                        this.imgBoxWidth = banner.bannerWidth;
                        this.imgBoxHeight = banner.bannerHeight;
                        this.uploadImageWidth =
                            (banner.profileRectWidth / naturalWidth) *
                            this.imgBoxWidth;
                        this.uploadImageHeight =
                            (banner.profileRectHeight / naturalHeight) *
                            this.imgBoxHeight;
                        this.uploadImageTop =
                            (banner.profileRectY / naturalHeight) *
                            this.imgBoxHeight;
                        this.uploadImageLeft =
                            (banner.profileRectX / naturalWidth) *
                            this.imgBoxWidth;

                        if (window.innerWidth < 1200) {
                            this.imgBoxWidth = imgBoxElement.clientWidth - 32;
                            this.imgBoxHeight = this.imgBoxWidth * 0.8;
                            this.uploadImageWidth =
                                (banner.profileRectWidth / naturalWidth) *
                                this.imgBoxWidth;
                            this.uploadImageHeight =
                                (banner.profileRectHeight / naturalHeight) *
                                this.imgBoxHeight;
                            this.uploadImageTop =
                                (banner.profileRectY / naturalHeight) *
                                this.imgBoxHeight;
                            this.uploadImageLeft =
                                (banner.profileRectX / naturalWidth) *
                                this.imgBoxWidth;
                        }
                    } else if (
                        banner.bannerWidth <= 415 &&
                        banner.bannerHeight <= 415
                    ) {
                        if (window.innerWidth < 1400) {
                            if (naturalWidth < 719) {
                                this.imgBoxWidth = naturalWidth;
                                this.imgBoxHeight = naturalHeight;

                                if (
                                    window.innerWidth < 1200 &&
                                    window.innerWidth > 992
                                ) {
                                    this.uploadImageLeft =
                                        banner.profileRectX - 10;
                                } else {
                                    this.uploadImageLeft = banner.profileRectX;
                                }
                                this.uploadImageWidth = banner.profileRectWidth;
                                this.uploadImageHeight =
                                    banner.profileRectHeight;
                                this.uploadImageTop = banner.profileRectY;

                                if (window.innerWidth < 768) {
                                    this.imgBoxWidth =
                                        imgBoxElement.clientWidth - 32;
                                    this.imgBoxHeight = this.imgBoxWidth * 0.8;
                                    this.uploadImageWidth =
                                        (banner.profileRectWidth /
                                            naturalWidth) *
                                        this.imgBoxWidth;
                                    this.uploadImageHeight =
                                        (banner.profileRectHeight /
                                            naturalHeight) *
                                        this.imgBoxHeight;
                                    this.uploadImageTop =
                                        (banner.profileRectY / naturalHeight) *
                                        this.imgBoxHeight;
                                    this.uploadImageLeft =
                                        (banner.profileRectX / naturalWidth) *
                                        this.imgBoxWidth;
                                }
                            } else {
                                if (naturalHeight <= 415) {
                                    this.imgBoxWidth =
                                        imgBoxElement.clientWidth - 32;
                                    this.imgBoxHeight = this.imgBoxWidth * 0.5;
                                } else {
                                    this.imgBoxWidth =
                                        imgBoxElement.clientWidth - 32;
                                    this.imgBoxHeight = this.imgBoxWidth * 1;
                                }
                                this.uploadImageWidth =
                                    (banner.profileRectWidth / naturalWidth) *
                                    this.imgBoxWidth;
                                this.uploadImageHeight =
                                    (banner.profileRectHeight / naturalHeight) *
                                    this.imgBoxHeight;
                                this.uploadImageTop =
                                    (banner.profileRectY / naturalWidth) *
                                    this.imgBoxWidth;
                                this.uploadImageLeft =
                                    (banner.profileRectX / naturalWidth) *
                                    this.imgBoxWidth;
                            }
                        } else {
                            if (naturalWidth < 719) {
                                this.imgBoxWidth = naturalWidth;
                                this.imgBoxHeight = naturalHeight;
                                this.uploadImageWidth =
                                    (banner.profileRectWidth / naturalWidth) *
                                    this.imgBoxWidth;
                                this.uploadImageHeight =
                                    (banner.profileRectHeight / naturalHeight) *
                                    this.imgBoxHeight;
                                this.uploadImageTop =
                                    (banner.profileRectY / naturalHeight) *
                                    this.imgBoxHeight;
                                this.uploadImageLeft =
                                    (banner.profileRectX / naturalWidth) *
                                    this.imgBoxWidth;
                            } else {
                                if (naturalHeight <= 415) {
                                    this.imgBoxWidth =
                                        imgBoxElement.clientWidth - 32;
                                    this.imgBoxHeight = naturalHeight - 16;
                                    this.uploadImageWidth =
                                        (banner.profileRectWidth /
                                            naturalWidth) *
                                        this.imgBoxWidth;
                                    this.uploadImageHeight =
                                        (banner.profileRectHeight /
                                            naturalHeight) *
                                        this.imgBoxHeight;
                                    this.uploadImageTop =
                                        (banner.profileRectY / naturalWidth) *
                                        this.imgBoxWidth;
                                    this.uploadImageLeft =
                                        (banner.profileRectX / naturalWidth) *
                                        this.imgBoxWidth;
                                } else {
                                    this.imgBoxWidth =
                                        imgBoxElement.clientWidth - 32;
                                    this.imgBoxHeight = this.imgBoxWidth * 1;
                                    this.uploadImageWidth =
                                        (banner.profileRectWidth /
                                            naturalWidth) *
                                        this.imgBoxWidth;
                                    this.uploadImageHeight =
                                        (banner.profileRectHeight /
                                            naturalHeight) *
                                        this.imgBoxHeight;
                                    this.uploadImageTop =
                                        (banner.profileRectY / naturalWidth) *
                                        this.imgBoxWidth;
                                    this.uploadImageLeft =
                                        (banner.profileRectX / naturalWidth) *
                                        this.imgBoxWidth;
                                }
                            }
                        }
                    } else if (
                        banner.bannerWidth > 719 &&
                        banner.bannerHeight < 400
                    ) {
                        this.imgBoxWidth = imgBoxElement.clientWidth - 32;
                        // this.imgBoxHeight = banner.bannerHeight - 32;
                        this.imgBoxHeight = this.imgBoxWidth * 0.5;

                        this.uploadImageWidth =
                            (banner.profileRectWidth / banner.bannerWidth) *
                            this.imgBoxWidth;
                        this.uploadImageHeight =
                            (banner.profileRectHeight / banner.bannerHeight) *
                            this.imgBoxHeight;
                        // this.uploadImageHeight = banner.profileRectHeight - 16;
                        // this.uploadImageTop = banner.profileRectY ;
                        this.uploadImageTop =
                            (banner.profileRectY / banner.bannerHeight) *
                            this.imgBoxHeight;
                        this.uploadImageLeft =
                            (banner.profileRectX / banner.bannerWidth) *
                            this.imgBoxWidth;

                        if (window.innerWidth < 767) {
                            this.imgBoxWidth = imgBoxElement.clientWidth - 32;
                            this.imgBoxHeight = this.imgBoxWidth * 0.5;
                            this.uploadImageWidth =
                                (banner.profileRectWidth / banner.bannerWidth) *
                                this.imgBoxWidth;
                            this.uploadImageHeight =
                                (banner.profileRectHeight /
                                    banner.bannerHeight) *
                                this.imgBoxHeight;
                            this.uploadImageTop =
                                (banner.profileRectY / banner.bannerHeight) *
                                this.imgBoxHeight;
                            this.uploadImageLeft =
                                (banner.profileRectX / banner.bannerWidth) *
                                this.imgBoxWidth;
                        }
                    } else if (banner.bannerWidth > 719) {
                        this.imgBoxWidth = imgBoxElement.clientWidth - 32;
                        this.imgBoxHeight = this.imgBoxWidth * 1;
                        this.uploadImageWidth =
                            (banner.profileRectWidth / banner.bannerWidth) *
                            this.imgBoxWidth;
                        this.uploadImageHeight =
                            (banner.profileRectHeight / banner.bannerHeight) *
                            this.imgBoxHeight;
                        this.uploadImageTop =
                            (banner.profileRectY / banner.bannerWidth) *
                            this.imgBoxWidth;
                        this.uploadImageLeft =
                            (banner.profileRectX / banner.bannerWidth) *
                            this.imgBoxWidth;
                    }
                };
            }
        },
        handleFileChange(event) {
            const file = event.target.files[0];

            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.selectedImageSrc = e.target.result;

                    // rest adjust value

                    this.rotateValue = 0;
                    const rotateValueDisplay = this.$refs.rotateValueDisplay;
                    const rotateSlider = this.$refs.rotateSlider;

                    if (rotateValueDisplay) {
                        rotateValueDisplay.textContent = "0°";
                    }
                    if (rotateSlider) {
                        rotateSlider.value = 0;
                    }

                    this.scale = 1;
                    this.flipX = 1;
                    this.flipY = 1;
                    this.updateScale();
                    this.updateFlip();
                    this.resetPosition(this.$refs.wrapper);
                    this.resetPosition(this.$refs.wrappers);

                    const img = new Image();
                    img.src = this.selectedImageSrc;

                    img.onload = () => {
                        const rect =
                            this.$refs.showIndex.getBoundingClientRect();
                        this.wrapperWidth = rect.width;
                        this.wrapperHeight = rect.height;
                        this.newElementWidth = this.wrapperWidth + "px";
                        this.newElementHeight = this.wrapperHeight + "px";

                        const parentRect =
                            this.$refs.showIndex.parentElement.getBoundingClientRect();
                        this.newElementTop =
                            ((rect.top - parentRect.top) / parentRect.height) *
                                100 +
                            "%";
                        this.newElementLeft =
                            ((rect.left - parentRect.left) / parentRect.width) *
                                100 +
                            "%";
                    };
                };
                reader.readAsDataURL(file);
            }
        },
        resetPosition(elementRef) {
            if (elementRef && elementRef.style) {
                elementRef.style.left = "0px";
                elementRef.style.top = "0px";
            }
        },
        handleUploadClick() {
            this.$refs.fileInput.click();
        },
        // drag card image

        onDrag(event) {
            // this.updatePosition(this.$refs.wrapper, event);
            // this.updatePosition(this.$refs.wrappers, event);

            if (event.type.startsWith("mouse")) {
                this.updatePosition(this.$refs.wrapper, event);
                this.updatePosition(this.$refs.wrappers, event);
            } else if (event.type.startsWith("touch")) {
                this.updateTouchPosition(this.$refs.wrapper, event);
                this.updateTouchPosition(this.$refs.wrappers, event);
            }
        },

        updatePosition(elementRef, event) {
            const movementX =
                event.movementX ||
                event.mozMovementX ||
                event.webkitMovementX ||
                0;
            const movementY =
                event.movementY ||
                event.mozMovementY ||
                event.webkitMovementY ||
                0;

            const getStyle = window.getComputedStyle(elementRef);
            const leftVal = parseInt(getStyle.left);
            const topVal = parseInt(getStyle.top);
            elementRef.style.left = `${leftVal + movementX}px`;
            elementRef.style.top = `${topVal + movementY}px`;
        },
        updateTouchPosition(elementRef, event) {
            if (!this.lastTouchX || !this.lastTouchY) {
                this.lastTouchX = event.touches[0].clientX;
                this.lastTouchY = event.touches[0].clientY;
            }
            const movementX = event.touches[0].clientX - this.lastTouchX;
            const movementY = event.touches[0].clientY - this.lastTouchY;

            const getStyle = window.getComputedStyle(elementRef);
            const leftVal = parseInt(getStyle.left);
            const topVal = parseInt(getStyle.top);
            elementRef.style.left = `${leftVal + movementX}px`;
            elementRef.style.top = `${topVal + movementY}px`;

            this.lastTouchX = event.touches[0].clientX;
            this.lastTouchY = event.touches[0].clientY;
        },
        startDrag() {
            this.$refs.imgDrag.classList.add("active");
            window.addEventListener("mousemove", this.onDrag);
        },
        stopDrag() {
            this.$refs.imgDrag.classList.remove("active");
            window.removeEventListener("mousemove", this.onDrag);
        },
        startTouch(event) {
            this.$refs.imgDrag.classList.add("active");
            this.lastTouchX = event.touches[0].clientX;
            this.lastTouchY = event.touches[0].clientY;
            window.addEventListener("touchmove", this.onDrag);
        },
        stopTouch() {
            this.$refs.imgDrag.classList.remove("active");
            window.removeEventListener("touchmove", this.onDrag);
            this.lastTouchX = null;
            this.lastTouchY = null;
        },
        zoomIn() {
            this.scale = Math.min(4, this.scale + 0.1);
            this.updateScale();
        },
        zoomOut() {
            this.scale = Math.max(0.5, this.scale - 0.1);
            this.updateScale();
        },
        FlipX() {
            this.flipX = this.flipX === -1 ? 1 : -1;
            this.updateFlip();
        },
        FlipY() {
            this.flipY = this.flipY === 1 ? -1 : 1;
            this.updateFlip();
        },
        updateScale() {
            const imgDrag = this.$refs.imgDrag;
            if (imgDrag) {
                imgDrag.style.transform = `scale(${this.scale}) rotate(${this.rotateValue}deg) `;
            }
        },
        updateFlip() {
            const imgFlip = this.$refs.imgFlip;
            if (imgFlip) {
                imgFlip.style.transform = `scaleX(${this.flipX}) scaleY(${this.flipY})`;
            }
        },
        updateFont() {
            const textFontS = this.$refs.textFontS;
            if (textFontS) {
                textFontS.style.fontSize = `${this.fontSizeValue}px`;
            }
        },
        updateColor(event) {
            if (event && event.target && event.target.value) {
                this.textColor = event.target.value;
            }
        },
        updateBgColor(event) {
            this.isTransparent = false;
            if (event && event.target && event.target.value) {
                this.bgColor = event.target.value;
                this.originalBgColor = event.target.value;
            }
        },
        toggleTransparent() {
            this.isTransparent = !this.isTransparent;
            if (this.isTransparent) {
                this.bgColor = "transparent";
            } else {
                this.bgColor = this.originalBgColor;
            }
        },
        handleText(event) {
            this.cardText = event.target.value;

            // this.$refs.showHand.classList.add("none");
        },

        resetChanges() {
            // rest  value

            this.rotateValue = 0;
            const rotateValueDisplay = this.$refs.rotateValueDisplay;
            const rotateSlider = this.$refs.rotateSlider;
            rotateValueDisplay.textContent = "0°";
            rotateSlider.value = 0;
            this.fontSizeValue = 16;
            const fontSizeSlider = this.$refs.fontSizeSlider;
            const fontSizeValueDisplay = this.$refs.fontSizeValueDisplay;
            fontSizeSlider.value = 16;
            fontSizeValueDisplay.textContent = `16px`;
            this.scale = 1;
            this.flipX = 1;
            this.flipY = 1;
            this.updateScale();
            this.updateFlip();
            this.textColor = "#000";
            this.bgColor = "#f7d031";
            this.originalBgColor = "#f7d031";
            this.isTransparent = false;
            this.updateColor();
            this.updateBgColor();
            this.updateFont();
            this.resetPosition(this.$refs.wrapper);
            this.resetPosition(this.$refs.wrappers);
            this.resetPosition(this.$refs.cardDragWrapper);
        },
        onDragText(event) {
            if (event.type.startsWith("mouse")) {
                this.updatePositionText(this.$refs.cardDragWrapper, event);
            } else if (event.type.startsWith("touch")) {
                this.updateTouchPositionText(this.$refs.cardDragWrapper, event);
            }
        },
        updatePositionText(elementRef, event) {
            const movementX =
                event.movementX ||
                event.mozMovementX ||
                event.webkitMovementX ||
                0;
            const movementY =
                event.movementY ||
                event.mozMovementY ||
                event.webkitMovementY ||
                0;

            const getStyle = window.getComputedStyle(elementRef);
            const leftVal = parseInt(getStyle.left);
            const topVal = parseInt(getStyle.top);

            elementRef.style.left = `${leftVal + movementX}px`;
            elementRef.style.top = `${topVal + movementY}px`;
        },
        updateTouchPositionText(elementRef, event) {
            if (!this.lastTouchXText || !this.lastTouchYText) {
                this.lastTouchXText = event.touches[0].clientX;
                this.lastTouchYText = event.touches[0].clientY;
            }
            const movementX = event.touches[0].clientX - this.lastTouchXText;
            const movementY = event.touches[0].clientY - this.lastTouchYText;

            const getStyle = window.getComputedStyle(elementRef);
            const leftVal = parseInt(getStyle.left);
            const topVal = parseInt(getStyle.top);
            elementRef.style.left = `${leftVal + movementX}px`;
            elementRef.style.top = `${topVal + movementY}px`;

            this.lastTouchXText = event.touches[0].clientX;
            this.lastTouchYText = event.touches[0].clientY;
        },
        startDragText() {
            window.addEventListener("mousemove", this.onDragText);
        },
        stopDragText() {
            window.removeEventListener("mousemove", this.onDragText);
        },
        startTouchText(event) {
            this.lastTouchXText = event.touches[0].clientX;
            this.lastTouchYText = event.touches[0].clientY;
            window.addEventListener("touchmove", this.onDragText);
        },
        stopTouchText() {
            window.removeEventListener("touchmove", this.onDragText);
            this.lastTouchXText = null;
            this.lastTouchYText = null;
        },
        stopDragTextOnWindowClick() {
            window.removeEventListener("mousemove", this.onDragText);
            window.removeEventListener("mousemove", this.onDrag);
        },

        // download
        async handleDownload() {
            const bannerParent = this.$refs.downloadRef;
            const bannerClild = this.$refs.imgBoxRef;

            this.isDownloading = true;

            if (!bannerParent) {
                console.error("Could not find bannerParent element.");
                this.isDownloading = false;
                return;
            }

            const originalWidth = bannerClild.clientWidth;
            const originalHeight = bannerClild.clientHeight;
            // const originalHeightParent = bannerParent.clientHeight;
            const originalWidthParent = bannerParent.clientWidth;


            const originalStyles = {
                height: bannerParent.style.height,
                width: bannerParent.style.width,
                border: bannerParent.style.border,
                padding: bannerParent.style.padding,
            };

            if(originalHeight < 450){
                bannerParent.style.height = `${originalHeight}px`;
            }
            bannerParent.style.border = `0px`;
            bannerParent.style.padding = `0px`;
            this.imgBoxWidth = originalWidthParent - 32



            if (originalWidth < 719) {
                bannerParent.style.width = `${originalWidth}px`;
            }
            if (this.banner) {
                this.updateImageDimensions(this.banner);
            }
            try {
                await this.$nextTick();

                const canvas = await html2canvas(bannerParent);
                const dataURL = canvas.toDataURL("image/jpg");
                const link = document.createElement("a");
                link.href = dataURL;

                const id = parseInt(this.$route.query.id);
                const foundBanner = this.banners.find((item) => item.id === id);
                this.banner = foundBanner;
                const title = this.banner.title;
                const singleWordTitle = title.split(" ")[0];
                link.download = `${singleWordTitle}_banner.jpg`;
                link.click();
                var userAgent = navigator.userAgent || navigator.vendor || window.opera;
                if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
                      setTimeout(() => {
                        window.location.reload();
                    }, 4000);
                }else{
                     setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }

               
            } catch (error) {
                console.error("Error generating canvas:", error);
            } finally {
                bannerParent.style.height = originalStyles.height;
                bannerParent.style.width = originalStyles.width;
                bannerParent.style.border = originalStyles.border;
                bannerParent.style.padding = originalStyles.padding;
                if (originalWidth > 719) {
                    this.imgBoxWidth = originalWidthParent  + 32;
                }
                this.isDownloading = false;
                this.selectedImageSrc = null;
                // this.updateImageDimensions(this.banner);

            }
        },
    },
    mounted() {
        if (window.innerWidth >= 992) {
            this.isDesktop = true;
            this.isRotateShow = false;
            this.isTextShow = false;
        } else if (window.innerWidth < 992) {
            this.isDesktop = false;
            this.isRotateShow = true;
            this.isTextShow = true;
        }

        this.$nextTick(() => {
            window.addEventListener("resize", this.handleWindowResize);
        });
        const rotateSlider = this.$refs.rotateSlider;
        const rotateValueDisplay = this.$refs.rotateValueDisplay;

        if (rotateSlider) {
            rotateSlider.addEventListener("input", () => {
                this.rotateValue = rotateSlider.value;
                rotateValueDisplay.textContent = `${this.rotateValue}°`;
                this.updateScale();
            });
        }

        // Slider initialization for font size
        const fontSizeSlider = this.$refs.fontSizeSlider;
        const fontSizeValueDisplay = this.$refs.fontSizeValueDisplay;
        fontSizeSlider.addEventListener("input", () => {
            this.fontSizeValue = fontSizeSlider.value;
            fontSizeValueDisplay.textContent = `${this.fontSizeValue}px`;
            this.updateFont();
        });
    },
    watch: {
        domRendered: {
            immediate: true,
            handler() {
                // const id = parseInt(this.$route.query.id);
                // const foundBanner = this.banners.find((item) => item.id === id);
                // if (foundBanner) {
                //   this.updateImageDimensions(foundBanner);
                // }
                if (this.banner) {
                    this.updateImageDimensions(this.banner);
                }
            },
        },
    },
    computed: {
        formattedCardText() {
            return this.cardText.replace(/\n/g, "<br>");
        },
    },

    beforeUnmount() {
        window.removeEventListener("click", this.stopDragTextOnWindowClick);
        window.removeEventListener("resize", this.handleWindowResize);
    },
};
</script>
