<template>
    <div class="left-sidebar">
        <ul class="nav">
            <div class="logo-sidebar">
                <router-link to="/admin/dashboard">
                    <img
                        src="../../assets/logo-white.png"
                        alt="logoss"
                        width="140"
                        height="35"
                    />
                </router-link>
            </div>
            <li class="nav-item">
                <router-link
                    to="/admin/dashboard"
                    class="nav-link"
                    :class="{ active: $route.path === '/admin/dashboard' }"
                >
                    Dashboard
                </router-link>
            </li>
             <li class="nav-item">
                <router-link
                    to="/admin/custom-code"
                    class="nav-link"
                    :class="{ active: $route.path === '/admin/custom-code' }"
                >
                    Custom Code
                </router-link>
            </li>
            <li class="nav-item">
                <div
                    class="dropdowns post-dropdown"
                    :class="{
                        active: isDropdownActive(
                            '/admin/all-posts',
                            '/admin/add-post'
                        ),
                    }"
                >
                    <span class="nav-link dropdown-link" style="cursor: pointer"
                        >Post</span
                    >
                    <ul class="dropdown-list">
                        <li>
                            <router-link
                                to="/admin/all-posts"
                                class="nav-link"
                                :class="{
                                    active: $route.path === '/admin/all-posts',
                                }"
                                >All Post</router-link
                            >
                        </li>
                        <li>
                            <router-link
                                to="/admin/add-post"
                                class="nav-link"
                                :class="{
                                    active: $route.path === '/admin/add-post',
                                }"
                                >Add New</router-link
                            >
                        </li>
                    </ul>
                </div>
            </li>
            <li class="nav-item">
                <div
                    class="dropdowns category-dropdown"
                    :class="{
                        active: isDropdownActive(
                            '/admin/all-categories',
                            '/admin/add-category'
                        ),
                    }"
                >
                    <span class="nav-link dropdown-link" style="cursor: pointer"
                        >Banner Categories</span
                    >
                    <ul class="dropdown-list">
                        <li>
                            <router-link
                                to="/admin/all-categories"
                                class="nav-link"
                                :class="{
                                    active:
                                        $route.path === '/admin/all-categories',
                                }"
                                >All Categories</router-link
                            >
                        </li>
                        <li>
                            <router-link
                                to="/admin/add-category"
                                class="nav-link"
                                :class="{
                                    active:
                                        $route.path === '/admin/add-category',
                                }"
                                >Add New</router-link
                            >
                        </li>
                    </ul>
                </div>
            </li>
            <li class="nav-item">
                <div
                    class="dropdowns explore-dropdown"
                    :class="{
                        active: isDropdownActive(
                            '/admin/all-explore',
                            '/admin/add-explore'
                        ),
                    }"
                >
                    <span class="nav-link dropdown-link" style="cursor: pointer"
                        >Explores</span
                    >
                    <ul class="dropdown-list">
                        <li>
                            <router-link
                                to="/admin/all-explore"
                                class="nav-link"
                                :class="{
                                    active:
                                        $route.path === '/admin/all-explore',
                                }"
                                >All Explore</router-link
                            >
                        </li>
                        <li>
                            <router-link
                                to="/admin/add-explore"
                                class="nav-link"
                                :class="{
                                    active:
                                        $route.path === '/admin/add-explore',
                                }"
                                >Add New</router-link
                            >
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
        <div class="login-list" @click="removeToken">
            <router-link to="/" class="nav-link"> Logout </router-link>
        </div>
    </div>
    <div class="mobile-header" :class="headerBoxshadow ? 'boxShadow' : ''">
        <div>
            <button type="button" @click="openModal">
                <svg
                    width="32"
                    height="24"
                    viewBox="0 0 32 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M31.5 12C31.5 11.6022 31.342 11.2206 31.0607 10.9393C30.7794 10.658 30.3978 10.5 30 10.5H2C1.60218 10.5 1.22064 10.658 0.93934 10.9393C0.658035 11.2206 0.5 11.6022 0.5 12C0.5 12.3978 0.658035 12.7794 0.93934 13.0607C1.22064 13.342 1.60218 13.5 2 13.5H30C30.3978 13.5 30.7794 13.342 31.0607 13.0607C31.342 12.7794 31.5 12.3978 31.5 12ZM31.5 2C31.5 1.60218 31.342 1.22064 31.0607 0.93934C30.7794 0.658035 30.3978 0.5 30 0.5H2C1.60218 0.5 1.22064 0.658035 0.93934 0.93934C0.658035 1.22064 0.5 1.60218 0.5 2C0.5 2.39782 0.658035 2.77936 0.93934 3.06066C1.22064 3.34196 1.60218 3.5 2 3.5H30C30.3978 3.5 30.7794 3.34196 31.0607 3.06066C31.342 2.77936 31.5 2.39782 31.5 2ZM31.5 22C31.5 21.6022 31.342 21.2206 31.0607 20.9393C30.7794 20.658 30.3978 20.5 30 20.5H2C1.60218 20.5 1.22064 20.658 0.93934 20.9393C0.658035 21.2206 0.5 21.6022 0.5 22C0.5 22.3978 0.658035 22.7794 0.93934 23.0607C1.22064 23.342 1.60218 23.5 2 23.5H30C30.3978 23.5 30.7794 23.342 31.0607 23.0607C31.342 22.7794 31.5 22.3978 31.5 22Z"
                        fill="#7C2CE7"
                    />
                </svg>
            </button>
        </div>
        <div>
            <router-link to="/">
                <img
                    src="../../assets/logo.png"
                    alt="logoss"
                    width="140"
                    height="35"
                />
            </router-link>
        </div>
        <div class="ms-auto">
            <div class="d-flex align-items-center gap-2">
                <h6 id="user-name" class="mb-0 text-white"></h6>
                <div id="user-name-wrapper" class="user-name">
                    <span
                        id="user-name-span"
                        class="text-white fw-bold text-capitalize"
                        >{{ userData.charAt(0) }}</span
                    >
                </div>
            </div>
        </div>
    </div>

    <div class="topHeader" :class="headerBoxshadow ? 'boxShadow' : ''">
        <div class="route-name">
            {{ adminPageName }}
        </div>
        <div class="ms-auto">
            <div class="d-flex align-items-center gap-2">
                <h6 id="user-name" class="mb-0 text-white"></h6>
                <div id="user-name-wrapper" class="user-name">
                    <span
                        id="user-name-span"
                        class="text-white fw-bold text-capitalize"
                        >{{ userData.charAt(0) }}</span
                    >
                </div>
            </div>
        </div>
    </div>

    <NavModel ref="navModalRef" />
</template>

<script>
import NavModel from "../NavModel.vue";
import { Modal } from "bootstrap";

export default {
    name: "SidebarAdmin",
    data() {
        return {
            headerBoxshadow: false,
        };
    },
    components: {
        NavModel,
    },

    computed: {
        userData() {
            const user = JSON.parse(localStorage.getItem("USER"));
            return user && user.name;
        },
        adminPageName() {
            const pathSegments = this.$route.path.split("/");
            const adminIndex = pathSegments.indexOf("admin");
            if (adminIndex !== -1 && pathSegments.length > adminIndex + 1) {
                const segment = pathSegments[adminIndex + 1];
                return segment
                    .replace(/-/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase());
            }
            return "";
        },
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);

        var dropdownLinks = document.querySelectorAll(".dropdown-link");

        dropdownLinks.forEach(function (link) {
            link.addEventListener("click", function () {
                var dropdown = this.closest(".dropdowns");
                dropdown.classList.toggle("active");
            });
        });

        var navLinks = document.querySelectorAll(".modal-route");

        navLinks.forEach(function (link) {
            link.addEventListener("click", function () {
                var modal = document.querySelectorAll(".modal.show");

                if (modal) {
                    modal.forEach((modal) => {
                        setTimeout(() => {
                            modal.setAttribute("data-bs-dismiss", "modal");
                        }, 1000);
                    });
                }
            });
        });
    },

    methods: {
        removeToken() {
            localStorage.removeItem("TOKEN");
            localStorage.removeItem("USER");
            localStorage.removeItem("ROLE");
            setTimeout(() => {
                window.location.reload();
            }, 250);
        },
        isDropdownActive(...routes) {
            return routes.some((route) => this.$route.path.startsWith(route));
        },
        openModal() {
            const modalEl = this.$refs.navModalRef.$el;
            const modalInstance = new Modal(modalEl);
            modalInstance.show();
        },

        handleScroll() {
            if (window.scrollY > 27) {
                this.headerBoxshadow = true;
            } else {
                this.headerBoxshadow = false;
            }
        },
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>
