<template>
    <div>
        <h3>Reset Password</h3>
        <form @submit.prevent="resetPassword">
            <!-- <input type="hidden" v-model="token" /> -->
            <div class="mb-3">
                <label class="form-label">Email</label>
                <input type="email" class="form-control" v-model="email" />
            </div>
            <div class="mb-3">
                <label class="form-label">Password</label>
                <input
                    type="password"
                    class="form-control"
                    v-model="password"
                />
            </div>
            <div class="mb-3">
                <label class="form-label">Confirm Password</label>
                <input
                    type="password"
                    class="form-control"
                    v-model="password_confirmation"
                />
            </div>
            <button type="submit" class="btn btn-primary">
                Reset Password
            </button>
        </form>
    </div>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";

export default {
    data() {
        return {
            email: "",
            password: "",
            password_confirmation: "",
        };
    },
    methods: {
        resetPassword() {
            // const token = JSON.parse(localStorage.getItem("TOKEN"));

            axios
                .post(process.env.VUE_APP_API_URL + "/reset-password", {
                    // token: token,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    // headers: {
                    //     Authorization: `Bearer ${token.token}`,
                    //     "Content-Type": "application/json",
                    // },
                })
                .then(() => {
                    toast("Password has been successfully reset.", {
                        autoClose: 3000,
                        type: "success",
                    });
                    this.$router.push("/login");
                })
                .catch(() => {
                    toast("Failed to reset password.", {
                        autoClose: 3000,
                        type: "error",
                    });
                });
        },
    },
};
</script>
