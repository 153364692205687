<template>
    <div
        class="modal fade custom-modal"
        id="embedModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="section-subheading">Share File</h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body pt-0">
                    <div class="social-share">
                        <ShareNetwork
                            network="facebook"
                            :url="embedCode"
                            title="Share templates our Friends"
                            hashtags="BellBoost"
                        >
                            <i class="fab fa-facebook"></i> 
                            <span>facebook</span>
                        </ShareNetwork>
                        <!-- <a
                            :href="`https://www.instagram.com/?url=${encodeURIComponent(
                                embedCode
                            )}`"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i class="fab fa-instagram"></i>
                        </a> -->

                        <ShareNetwork
                            network="twitter"
                            :url="embedCode"
                            title="Share templates our Friends"
                            hashtags="BellBoost"
                        >
                            <i class="fa-brands fa-twitter"></i>
                            <span>twitter</span>
                        </ShareNetwork>
                        <ShareNetwork
                            network="linkedin"
                            :url="embedCode"
                            title="Share templates our Friends"
                            hashtags="BellBoost"
                        >
                            <i class="fa-brands fa-linkedin"></i>
                            <span>linkedin</span>
                        </ShareNetwork>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ShareNetwork } from "vue-social-sharing";

export default {
    name: "EmbedModel",
    components: {
        ShareNetwork,
    },
    props: {
        embedCode: {
            type: String,
            required: true,
        },
    },
};
</script>
