<template>
    <div
        class="modal fade header-modal"
        id="navModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
                <div class="modal-header">
                    <img src="../assets/logo.png" alt="logo" />
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <div>
                        <ul>
                            <li class="nav-item modal-route">
                                <router-link
                                    class="nav-link"
                                    :class="{ active: $route.path === '/' }"
                                    to="/"
                                    v-if="!isAdmin"
                                    >Home</router-link
                                >
                                <router-link
                                    class="nav-link"
                                    to="/admin/dashboard"
                                    :class="{
                                        active:
                                            $route.path === '/admin/dashboard',
                                    }"
                                    v-if="isAdmin"
                                    >Dashobard</router-link
                                >
                            </li>
                            <li class="nav-item modal-route">
                                <router-link
                                    class="nav-link"
                                    to="/banner/holidays"
                                    v-if="!isAdmin"
                                    :class="{
                                        active:
                                            $route.path === '/banner/holidays',
                                    }"
                                    >Holidays</router-link
                                >
                            </li>

                            <li class="nav-item modal-route">
                                <router-link
                                    class="nav-link"
                                    :class="{
                                        active:
                                            $route.path === '/banner/birthday',
                                    }"
                                    v-if="!isAdmin"
                                    to="/banner/birthday"
                                    >Birthday</router-link
                                >
                            </li>
                            <li class="nav-item modal-route">
                                <router-link
                                    class="nav-link"
                                    :class="{
                                        active:
                                            $route.path ===
                                            '/banner/anniversary',
                                    }"
                                    v-if="!isAdmin"
                                    to="/banner/anniversary"
                                    >Anniversary</router-link
                                >
                            </li>
                            <li class="nav-item modal-route">
                                <router-link
                                    class="nav-link"
                                    :class="{
                                        active: $route.path === '/banner/psl',
                                    }"
                                    v-if="!isAdmin"
                                    to="/banner/psl"
                                    >PSL</router-link
                                >
                            </li>
                            <li class="nav-item modal-route">
                                <router-link
                                    class="nav-link"
                                    :class="{
                                        active: $route.path === '/contact',
                                    }"
                                    v-if="!isAdmin"
                                    to="/contact"
                                    >Contact Us</router-link
                                >
                            </li>
                            <li class="nav-item" v-if="isAdmin">
                                <div
                                    class="dropdowns post-dropdown"
                                    :class="{
                                        active: isDropdownActive(
                                            '/admin/all-posts',
                                            '/admin/add-post'
                                        ),
                                    }"
                                >
                                    <span
                                        class="nav-link dropdown-link"
                                        style="cursor: pointer"
                                        >Post</span
                                    >
                                    <ul class="dropdown-list">
                                        <li class="modal-route">
                                            <router-link
                                                to="/admin/all-posts"
                                                class="nav-link"
                                                :class="{
                                                    active:
                                                        $route.path ===
                                                        '/admin/all-posts',
                                                }"
                                                >All Post</router-link
                                            >
                                        </li>
                                        <li class="modal-route">
                                            <router-link
                                                to="/admin/add-post"
                                                class="nav-link"
                                                :class="{
                                                    active:
                                                        $route.path ===
                                                        '/admin/add-post',
                                                }"
                                                >Add New</router-link
                                            >
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li class="nav-item" v-if="isAdmin">
                                <div
                                    class="dropdowns category-dropdown"
                                    :class="{
                                        active: isDropdownActive(
                                            '/admin/all-categories',
                                            '/admin/add-category'
                                        ),
                                    }"
                                >
                                    <span
                                        class="nav-link dropdown-link"
                                        style="cursor: pointer"
                                        >Banner Categories</span
                                    >
                                    <ul class="dropdown-list">
                                        <li class="modal-route">
                                            <router-link
                                                to="/admin/all-categories"
                                                class="nav-link"
                                                :class="{
                                                    active:
                                                        $route.path ===
                                                        '/admin/all-categories',
                                                }"
                                                >All Categories</router-link
                                            >
                                        </li>
                                        <li class="modal-route">
                                            <router-link
                                                to="/admin/add-category"
                                                class="nav-link"
                                                :class="{
                                                    active:
                                                        $route.path ===
                                                        '/admin/add-category',
                                                }"
                                                >Add New</router-link
                                            >
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li class="nav-item" v-if="isAdmin">
                                <div
                                    class="dropdowns explore-dropdown"
                                    :class="{
                                        active: isDropdownActive(
                                            '/admin/all-explore',
                                            '/admin/add-explore'
                                        ),
                                    }"
                                >
                                    <span
                                        class="nav-link dropdown-link"
                                        style="cursor: pointer"
                                        >Explores</span
                                    >
                                    <ul class="dropdown-list">
                                        <li class="modal-route">
                                            <router-link
                                                to="/admin/all-explore"
                                                class="nav-link"
                                                :class="{
                                                    active:
                                                        $route.path ===
                                                        '/admin/all-explore',
                                                }"
                                                >All Explore</router-link
                                            >
                                        </li>
                                        <li class="modal-route">
                                            <router-link
                                                to="/admin/add-explore"
                                                class="nav-link"
                                                :class="{
                                                    active:
                                                        $route.path ===
                                                        '/admin/add-explore',
                                                }"
                                                >Add New</router-link
                                            >
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <div v-if="!isLoggedIn" class="header-btn">
                            <router-link to="/login" class="btn"
                                >Login</router-link
                            >
                            <router-link to="/signUp" class="btn"
                                >Sign up</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NavModel",
    mounted() {
        var dropdownLinks = document.querySelectorAll(".dropdown-link");
        dropdownLinks.forEach(function (link) {
            link.addEventListener("click", function () {
              // setTimeout(() => {
                
                var dropdown = this.closest(".dropdowns");
                  dropdown.classList.toggle("active-menu");
                // }, 500);
            });
        });
    },
    computed: {
        isLoggedIn() {
            return localStorage.getItem("USER");
        },
        isDropdownActive() {
            // return routes.some((route) => this.$route.path.startsWith(route));
            return (...routes) =>
                routes.some((route) =>
                    String(this.$route.path).startsWith(route)
                );
        },
        isAdmin() {
            const role = localStorage.getItem("ROLE");
            const parseRole = JSON.parse(role);
            const path = this.$route.path;

            return parseRole && path && path.startsWith("/admin");
        },
    },
};
</script>

<style lang="scss"></style>
