<template>
    <section class="card-type">
        <div class="container">
            <div
                class="card-type-wrapper"
                :class="!ExploreData.length ? 'justify-content-center' : ''"
            >
                <div class="row" v-if="ExploreData.length">
                    <div
                        class="col-lg-6 col-md-12 d-flex flex-column justify-content-center card-text"
                    >
                        <div
                            class="card-type-text"
                            v-for="(item, index) in ExploreData"
                            :key="index"
                        >
                            <h2>{{ item.title }}</h2>
                            <p>{{ item.description }}</p>
                            <router-link :to="'/' + item.link">
                                Explore more <i class="fa fa-arrow-up"></i>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 cards-col">
                        <div
                            class="card-image-wrapper"
                            v-for="(item, index) in ExploreData"
                            :key="index"
                        >
                            <div
                                v-if="item.image_1"
                                class="card-image card-image-1"
                            >
                                <img
                                    :src="imageUrl + '/explore/' + item.image_1"
                                    alt="card"
                                />
                            </div>
                            <div
                                v-if="item.image_2"
                                class="card-image card-image-2"
                            >
                                <img
                                    :src="imageUrl + '/explore/' + item.image_2"
                                    alt="card"
                                />
                            </div>
                            <div
                                v-if="item.image_3"
                                class="card-image card-image-3"
                            >
                                <img
                                    :src="imageUrl + '/explore/' + item.image_3"
                                    alt="card"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <h3 v-else class="text-center text-white section-heading mt-5">
                    Data not found
                </h3>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";

export default {
    name: "ExplorePage",
    data() {
        return {
            ExploreData: [],
            imageUrl: process.env.VUE_APP_IMAGE_URL,
        };
    },
    methods: {
        async fetchData() {
            try {
                const response = await axios.get(
                    process.env.VUE_APP_API_URL + "/feature-explore"
                );
                if (response.status === 200) {
                    this.ExploreData = response.data.explore;
                }
            } catch (error) {
                console.error("Error retrieving data:", error);
            }
        },
    },
    created() {
        this.fetchData();
    },
};
</script>
