<template>
    <div class="main-section dashboard-section">
        <form
            @submit.prevent="submitForm"
            class="addPost-form mb-4 col-8"
            enctype="multipart/form-data"
        >
            <div class="mb-3">
                <label class="form-label">Add Title</label>

                <textarea
                    class="form-control"
                    style="resize: none"
                    cols="30"
                    rows="7"
                    v-model="formData.custom_code"
                ></textarea>
            </div>

            <button type="submit" class="btn btn-primary" :disabled="isLoading">
                Submit
                <span
                    v-if="isLoading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
            </button>
        </form>
    </div>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
    name: "CustomCode",

    data() {
        return {
            formData: {
                custom_code: "",
            },
            isLoading:false
        };
    },

    methods: {
        submitForm() {
            this.isLoading = true;
            const token = JSON.parse(localStorage.getItem("TOKEN"));

            axios
                .post(
                    process.env.VUE_APP_API_URL + "/save/custom_code",
                    this.formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token.token}`,
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .then(() => {
                    this.showToast("Code Add successfully", "success");

                    this.isLoading = false;
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                })
                .catch((error) => {
                    this.isLoading = false;

                    if (error.response && error.response.data.errors) {
                        this.errorMessages = Object.values(
                            error.response.data.errors
                        ).flat();
                        this.showToast(
                            "Error Adding: " +
                                this.errorMessages.join(", "),
                            "error"
                        );
                    } else {
                        console.error("Error submitting form:", error);
                    }
                });
        },
        showToast(message, type) {
            toast(message, {
                autoClose: 1000,
                type: type,
                position: "top-right",
                pauseOnHover: false,
                closeOnClick: false,
            });
        },
    },
};
</script>
