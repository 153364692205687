<template>
    <div class="main-section">
        <div class="mb-3 px-3 d-flex align-items-center gap-4 card-table">
            <h3>All Explore</h3>
            <button class="btn btn-primary" @click="goToAddPost">
                Add New Explore
            </button>
        </div>
        <div
            class="card-table position-relative px-3"
            :class="isLoading ? 'pb-5' : ''"
        >
            <table
                id="allcategory"
                class="table table-striped"
                style="width: 100%"
            >
                <thead>
                    <tr>
                        <th>title</th>
                        <th style="text-align: left">image</th>
                        <th style="text-align: left">status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody v-if="!isLoading">
                    <tr
                        v-for="(exp, index) in explore.data"
                        :key="index"
                        valign="middle"
                    >
                        <td>{{ exp.title }}</td>
                        <td style="text-align: left">
                            <img
                                :src="imageUrl + '/explore/' + exp.image_1"
                                alt="img"
                                width="60px"
                            />
                            <img
                                :src="imageUrl + '/explore/' + exp.image_2"
                                alt="img"
                                width="60px"
                            />
                            <img
                                :src="imageUrl + '/explore/' + exp.image_3"
                                alt="img"
                                width="60px"
                            />
                        </td>
                        <td style="text-align: left">
                            <span
                                style="border-radius: 5px"
                                :class="getStatusClass(exp.status)"
                            >
                                {{ exp.status == 1 ? "active" : "inactive" }}
                            </span>
                        </td>
                        <td>
                            <router-link
                                :to="{
                                    name: 'UpdateExplore',
                                    params: { id: exp.id },
                                }"
                                class="btn btn-success btn-sm me-2"
                            >
                                Update
                            </router-link>
                            <button
                                @click="deleteExplore(exp.id)"
                                class="btn btn-danger btn-sm"
                            >
                                Delete
                            </button>
                        </td>
                    </tr>
                </tbody>
                <div
                    v-else
                    class="d-flex justify-content-center align-items-center position-absolute"
                    style="height: 44px; left: 50%; transform: translateX(-50%)"
                >
                    <span
                        class="spinner-border spinner-border"
                        role="status"
                        aria-hidden="true"
                        style="color: #7c2ce7"
                    ></span>
                </div>
                <tfoot :style="isLoading ? 'transform: translateY(41px);' : ''">
                    <tr>
                        <th>title</th>
                        <th style="text-align: left">image</th>
                        <th style="text-align: left">status</th>
                        <th>Action</th>
                    </tr>
                </tfoot>
            </table>
            <div
                :style="isLoading ? 'transform: translateY(31px);' : ''"
                class="d-flex justify-content-between w-100 align-items-center table-pagination"
            >
                <div>
                    Showing {{ startPosition }} to {{ explore.to }} of
                    {{ explore.total }} entries
                </div>
                <Bootstrap5Pagination
                    :data="explore"
                    @pagination-change-page="fetchExplore"
                    class="mb-0"
                />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { Bootstrap5Pagination } from "laravel-vue-pagination";

export default {
    data() {
        return {
            explore: [],
            successMessage: "",
            isLoading: false,
            imageUrl: process.env.VUE_APP_IMAGE_URL,
        };
    },
    components: {
        Bootstrap5Pagination,
    },
    async mounted() {
        await this.fetchExplore();
        this.getStatusClass();
    },
    computed: {
        startPosition() {
            if (
                !this.explore ||
                !this.explore.current_page ||
                !this.explore.per_page
            ) {
                return 0;
            }
            return (this.explore.current_page - 1) * this.explore.per_page + 1;
        },
    },
    methods: {
        getStatusClass(status) {
            return {
                "bg-primary px-2 py-1 text-white": status == 1,
                "bg-danger px-2 py-1 text-white": status == 0,
            };
        },
        async fetchExplore(page = 1) {
            this.isLoading = true;
            const token = JSON.parse(localStorage.getItem("TOKEN"));

            await axios
                .get(process.env.VUE_APP_API_URL + `/allExplore?page=${page}`, {
                    headers: {
                        Authorization: `Bearer ${token.token}`,
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.explore = response.data.explore;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;

                    console.error("Error fetching posts", error);
                });
        },

        goToAddPost() {
            this.$router.push("/admin/add-explore");
        },

        deleteExplore(exploreId) {
            const token = JSON.parse(localStorage.getItem("TOKEN"));

            axios
                .get(
                    process.env.VUE_APP_API_URL +
                        `/explore/${exploreId}/delete`,
                    {
                        headers: {
                            Authorization: `Bearer ${token.token}`,
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .then((response) => {
                    this.successMessage = response.data.message;
                    this.fetchExplore();
                    this.showToast("Deleted SuccessFully");

                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                })
                .catch((error) => {
                    console.error("Error deleting post", error);
                });
        },
        showToast(message) {
            toast(message, {
                autoClose: 1000,
                type: "success",
                position: "top-right",
                pauseOnHover: false,
                closeOnClick: false,
            });
        },
    },
};
</script>
