import { createApp } from 'vue'
import App from './App.vue'
import router from './routes/routes';
import  'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import VueSocialSharing from 'vue-social-sharing';



const app = createApp(App)

import gAuthPlugin from 'vue3-google-oauth2';
// let gauthClientId = '759041167938-71ott7tknuo96n8as5hk5f1olissuko5.apps.googleusercontent.com';
  // live
   let gauthClientId = '759041167938-5k3m396i3935fi1b0pca13p5d9hnjrdu.apps.googleusercontent.com';

app.use(gAuthPlugin, {
  clientId: gauthClientId,
  scope: 'email',
  prompt: 'consent',
  plugin_name:'vue3-google-oauth2'
})
app.use(router);
app.use(VueSocialSharing);
app.mount('#app')
