<template>
    <div class="main-section">
        <div class="row card-table">
            <form
                @submit.prevent="submitForm"
                class="addPost-form mb-4 col-8"
                enctype="multipart/form-data"
            >
                <div class="mb-3">
                    <label class="form-label">Add Image </label>
                    <input
                        type="file"
                        class="form-control"
                        @change="handleImageUpload"
                    />
                </div>

                <div class="mb-3">
                    <label class="form-label">Add Title</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="formData.title"
                    />
                </div>

                <div class="mb-3">
                    <label class="form-label">Add Link</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="formData.link"
                    />
                </div>

                <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="isLoading"
                >
                    Submit
                    <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
    name: "AddPost",
    data() {
        return {
            formData: {
                title: "",
                image: null,
                link: "",
            },
            successMessage: "",
            errorMessages: [],
            isLoading: false,
        };
    },
    methods: {
        handleImageUpload(event) {
            this.formData.image = event.target.files[0];
        },
        submitForm() {
            this.isLoading = true;
            const formData = new FormData();
            formData.append("title", this.formData.title);
            if (this.formData.image) {
                formData.append("image", this.formData.image);
            }
            formData.append("link", this.formData.link);
            const token = JSON.parse(localStorage.getItem("TOKEN"));

            axios
                .post(
                    process.env.VUE_APP_API_URL + "/save/category",
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token.token}`,
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .then(() => {
                    this.showToast("Category Add successfully", "success");

                    window.scrollTo(0, 0);
                    this.isLoading = false;
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                })
                .catch((error) => {
                    this.isLoading = false;

                  if (error.response && error.response.data.errors) {
                    this.errorMessages = Object.values(
                        error.response.data.errors
                    ).flat();
                    this.showToast(
                        "Error updating post: " + this.errorMessages.join(", "),
                        "error"
                    );

                    window.scrollTo(0, 0);
                } else {
                        console.error("Error submitting form:", error);
                    }
                });
        },
        showToast(message, type) {
            toast(message, {
                autoClose: 1000,
                type: type,
                position: "top-right",
                pauseOnHover: false,
                closeOnClick: false,
            });
        },
    },
};
</script>
