<template>
    <div class="main-section">
        <div class="row card-table">
            <form
                @submit.prevent="submitForm"
                class="addPost-form mb-4 col-8"
                enctype="multipart/form-data"
            >
                <div class="mb-3">
                    <label class="form-label">Add Images</label>
                    <input
                        type="file"
                        class="form-control mb-2"
                        @change="handleImageUpload(1, $event)"
                    />
                    <input
                        type="file"
                        class="form-control mb-2"
                        @change="handleImageUpload(2, $event)"
                    />
                    <input
                        type="file"
                        class="form-control mb-2"
                        @change="handleImageUpload(3, $event)"
                    />
                </div>

                <div class="mb-3">
                    <label class="form-label">Add Title</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="formData.title"
                    />
                </div>

                <div class="mb-3">
                    <label class="form-label">Add Link</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="formData.link"
                    />
                </div>

                <div class="mb-3">
                    <label class="form-label">Add Description</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="formData.description"
                    />
                </div>

                <div class="mb-3">
                    <label for="category">Explore Status:</label>
                    <select
                        style="height: 40px"
                        v-model="formData.status"
                        class="form-select"
                    >
                        <option value="1" selected>Active</option>
                        <option value="0">Inactive</option>
                    </select>
                </div>
                <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="isLoading"
                >
                    Submit
                    <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
    name: "AddPost",
    data() {
        return {
            formData: {
                title: "",
                images: [null, null, null],
                link: "",
                description: "",
                status: "1",
            },
            successMessage: "",
            errorMessages: [],
            isLoading: false,
        };
    },
    methods: {
        handleImageUpload(index, event) {
            if (event.target.files.length > 0) {
                this.formData.images[index - 1] = event.target.files[0];
            }
        },
        submitForm() {
            this.isLoading = true;
            const token = JSON.parse(localStorage.getItem("TOKEN"));

            const formData = new FormData();
            formData.append("title", this.formData.title);
            formData.append("link", this.formData.link);
            formData.append("description", this.formData.description);
            formData.append("status", this.formData.status);

            // Append each image to FormData
            this.formData.images.forEach((image, index) => {
                if (image) {
                    formData.append(`image_${index + 1}`, image);
                }
            });

            axios
                .post(process.env.VUE_APP_API_URL + "/save/explore", formData, {
                    headers: {
                        Authorization: `Bearer ${token.token}`,
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {
                    this.showToast("Explore Add successfully", "success");

                    this.isLoading = false;
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                })
                .catch((error) => {
                    this.isLoading = false;

                    if (error.response && error.response.data.errors) {
                        this.errorMessages = Object.values(
                            error.response.data.errors
                        ).flat();
                        this.showToast(
                            "Error updating post: " +
                                this.errorMessages.join(", "),
                            "error"
                        );

                    } else {
                        console.error("Error submitting form:", error);
                    }
                });
        },
        showToast(message, type) {
            toast(message, {
                autoClose: 1000,
                type: type,
                position: "top-right",
                pauseOnHover: false,
                closeOnClick: false,
            });
        },
    },
};
</script>
