<template>
    <div>
        <div class="container">
            <h4 class="activity-title">
                Hi <span>{{ userData.name }}</span
                >, Continue Your Design Exploration
            </h4>
        </div>
        <section v-if="!loading" class="pick-banner category-banner">
            <div class="container activity-heading">Your Designs</div>
            <div class="container" v-if="userPosts.length">
                <div class="row">
                    <div
                        class="col-lg-3 col-md-4"
                        v-for="(item, index) in sortedAndSlicedUserPosts"
                        :key="index"
                    >
                        <div class="card-banner">
                            <img
                                :src="getPostImage(item.banner)"
                                alt="Post Image"
                            />
                            <div class="card-text">
                                <p>{{ item.title }}</p>
                                <router-link
                                    :to="{
                                        name: 'EditTemplate',
                                        query: {
                                            title: transformTitleToUrl(
                                                item.title
                                            ),
                                            id: item.id,
                                        },
                                    }"
                                    @click.prevent="startEditing(item)"
                                >
                                    <i class="fa fa-pencil"></i> Start editing
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="discover-btn" v-if="moreToShowPosts">
                    <button type="button" @click="loadMore">
                        Explore more <i class="fa fa-arrow-up"></i>
                    </button>
                </div>
            </div>
            <h3 v-else class="text-center section-heading mt-5">
                Data not found
            </h3>
        </section>

        <section v-if="!loading" class="pick-banner category-banner">
            <div class="container activity-heading">Your Activities</div>
            <div class="container" v-if="activities.length">
                <div class="row">
                    <div
                        class="col-lg-3 col-md-4"
                        v-for="(item, index) in sortedAndSlicedTemplates"
                        :key="index"
                    >
                        <div class="card-banner">
                            <img
                                :src="getPostImage(item.banner)"
                                alt="Post Image"
                            />
                            <div class="card-text">
                                <p>{{ item.title }}</p>
                                <router-link
                                    :to="{
                                        name: 'EditTemplate',
                                        query: {
                                            title: transformTitleToUrl(
                                                item.title
                                            ),
                                            id: item.id,
                                        },
                                    }"
                                    @click.prevent="startEditing(item)"
                                >
                                    <i class="fa fa-pencil"></i> Start editing
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="discover-btn" v-if="moreToShow">
                    <button type="button" @click="loadMore">
                        Explore more <i class="fa fa-arrow-up"></i>
                    </button>
                </div>
            </div>
            <h3 v-else class="text-center section-heading mt-5">
                Data not found
            </h3>
        </section>

        <div v-if="loading" class="text-center my-5">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";

export default {
    name: "UserActivities",
    data() {
        return {
            activities: [],
            userPosts: [],
            currentPage: 1,
            itemsPerPage: 8,
            search: "",
            sortBy: "popular",
            loading: true,
            userData: null,
        };
    },
    created() {
        this.fetchUserActivities();
        this.fetchUserBanner();
    },
    methods: {
        fetchUserActivities() {
            this.loading = true;
            const userData = localStorage.getItem("USER");
            this.userData = userData ? JSON.parse(userData) : null;
            const userID = this.userData ? this.userData.id : null;
            const token = JSON.parse(localStorage.getItem("TOKEN"));

            if (userID) {
                axios
                    .get(
                        process.env.VUE_APP_API_URL +
                            `/activity-logs/${userID}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token.token}`,
                                "Content-Type": "application/json",
                            },
                        }
                    )
                    .then((response) => {
                        const postData = response.data.activities
                            .filter((activity) => activity.post !== null)
                            .map((activity) => ({
                                id: activity.post.id,
                                title: activity.post.title,
                                clicks: activity.post.clicks,
                                banner: activity.post.banner,
                            }));
                        this.activities = postData;
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.error("Error fetching activities:", error);
                        this.loading = false;
                    });
            } else {
                console.error("User ID not found");
                this.loading = false;
            }
        },
        fetchUserBanner() {
            this.loading = true;
            const userData = localStorage.getItem("USER");
            this.userData = userData ? JSON.parse(userData) : null;
            const userID = this.userData ? this.userData.id : null;
            const token = JSON.parse(localStorage.getItem("TOKEN"));

            if (userID) {
                axios
                    .get(
                        process.env.VUE_APP_API_URL + `/user-posts/${userID}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token.token}`,
                                "Content-Type": "application/json",
                            },
                        }
                    )
                    .then((response) => {
                        this.userPosts = response.data.userPosts;
                    })
                    .catch((error) => {
                        console.error("Error fetching userPosts:", error);
                        this.loading = false;
                    });
            } else {
                console.error("User ID not found");
                this.loading = false;
            }
        },
        startEditing(item) {
            this.scrollToTop();
            axios.post(process.env.VUE_APP_API_URL + "/increment-clicks", {
                postId: item.id,
            });
        },
        loadMore() {
            this.itemsPerPage *= 2;
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        getPostImage(base64ImageData) {
            return base64ImageData;
            // return "data:image/png;base64," + base64ImageData;
        },
        transformTitleToUrl(title) {
            return title.toLowerCase().replace(/\s+/g, "-");
        },
    },
    computed: {
        sortedAndSlicedTemplates() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            return this.activities.slice(
                startIndex,
                startIndex + this.itemsPerPage
            );
        },

        sortedAndSlicedUserPosts() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            return this.userPosts.slice(
                startIndex,
                startIndex + this.itemsPerPage
            );
        },
        moreToShow() {
            return (
                this.currentPage * this.itemsPerPage < this.activities.length
            );
        },
        moreToShowPosts() {
            return this.currentPage * this.itemsPerPage < this.userPosts.length;
        },
    },
};
</script>
