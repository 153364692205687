<template>
    <div>
        <div
            v-if="!loading"
            class="carousel main-carousel categoryCarousel"
            ref="flickityElement"
            :class="{ 'is-wide-carousel': isWideCarousel }"
        >
            <div
                class="carousel-cell"
                v-for="(item, index) in carouselItems"
                :key="index"
            >
                <div class="cards">
                    <router-link :to="'banner/' + item.link">
                        <img
                            alt="card"
                            :src="imageUrl + '/categories/' + item.image"
                        />
                        <h5>{{ item.title }}</h5>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="loading" class="text-center mt-5">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { ref, onMounted, onBeforeUnmount, watch } from "vue";
import Flickity from "flickity";

export default {
    name: "CategorySlider",
    setup() {
        const loading = ref(true);
        const flickityElement = ref(null);
        const flickityElementWidth = ref(0);
        const carouselItems = ref([]);
        const flickityWidth = ref(0);
        const isWideCarousel = ref(false);
        const imageUrl = process.env.VUE_APP_IMAGE_URL;

        const fetchData = async () => {
            try {
                const response = await axios.get(
                    process.env.VUE_APP_API_URL + "/allCategory"
                );
                if (response.status === 200) {
                    carouselItems.value = response.data.category;
                }
            } catch (error) {
                console.error("Error retrieving data:", error);
            } finally {
                loading.value = false;
            }
        };

        const calculateFlickityWidth = () => {
            let totalWidth = 0;
            carouselItems.value.forEach(() => {
                const cellWidth = 200;
                totalWidth += cellWidth;
            });
            flickityElementWidth.value = flickityElement.value.offsetWidth;

            return totalWidth;
        };

        const updateCarouselState = () => {
            flickityWidth.value = calculateFlickityWidth();
            isWideCarousel.value =
                flickityWidth.value > flickityElementWidth.value;
        };

        onMounted(async () => {
            await fetchData();
            if (flickityElement.value && carouselItems.value.length > 0) {
                new Flickity(flickityElement.value, {
                    cellAlign: "left",
                    freeScroll: true,
                    pageDots: false,
                });

                updateCarouselState();

                window.addEventListener("resize", updateCarouselState);
            }
        });

        onBeforeUnmount(() => {
            window.removeEventListener("resize", updateCarouselState);
        });

        watch([carouselItems, flickityElementWidth], () => {
            if (flickityElement.value) {
                updateCarouselState();
            }
        });

        return {
            loading,
            flickityElement,
            flickityWidth,
            flickityElementWidth,
            carouselItems,
            isWideCarousel,
            imageUrl,
        };
    },
};
</script>
