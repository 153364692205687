<template>
    <div class="main-section">
        <div class="row card-table">
            <div
                v-if="successMessage"
                id="successAlert"
                class="alert alert-success"
            >
                {{ successMessage }}
            </div>
            <div v-if="errorMessages.length" class="alert alert-danger my-2">
                <ul>
                    <li v-for="(error, index) in errorMessages" :key="index">
                        {{ error }}
                    </li>
                </ul>
            </div>

            <form
                @submit.prevent="submitForm"
                class="addPost-form mb-4"
                enctype="multipart/form-data"
            >
                <textarea
                    id="title"
                    v-model="formData.title"
                    class="form-control"
                    placeholder="Enter Your Title"
                ></textarea>

                <div class="mb-3">
                    <input
                        type="file"
                        ref="bannerInput"
                        @change="displayBanner"
                        accept="image/png"
                        style="display: none"
                    />
                    <input type="hidden" v-model="formData.banner" />
                    <button
                        type="button"
                        class="mb-2"
                        @click="triggerFileInput"
                    >
                        Add Banner
                    </button>
                    <div class="banner-wrapper">
                        <div id="showBanner" class="image-previews">
                            <img
                                ref="cropPreview"
                                :src="bannerPreview"
                                id="crop-preview"
                            />
                        </div>
                    </div>
                </div>

                <button type="button" v-if="bannerPreview" @click="initCrop">
                    Draw Profile Rectangle
                </button>
                <div class="form-field">
                    <div>
                        <label for="bannerWidth">Banner Width:</label>
                        <input
                            type="text"
                            v-model="formData.bannerWidth"
                            class="form-control"
                            readonly
                        />
                    </div>
                    <div>
                        <label for="bannerHeight">Banner Height:</label>
                        <input
                            type="text"
                            v-model="formData.bannerHeight"
                            class="form-control"
                            readonly
                        />
                    </div>
                    <div>
                        <label for="cropperWidth">Profile Rect W:</label>
                        <input
                            type="text"
                            v-model="cropperWidth"
                            class="form-control"
                            readonly
                        />
                    </div>
                    <div>
                        <label for="cropperHeight">Profile Rect H:</label>
                        <input
                            type="text"
                            v-model="cropperHeight"
                            class="form-control"
                            readonly
                        />
                    </div>
                    <div>
                        <label for="cropperX">Profile Rect X:</label>
                        <input
                            type="text"
                            v-model="cropperX"
                            class="form-control"
                            readonly
                        />
                    </div>
                    <div>
                        <label for="cropperY">Profile Rect Y:</label>
                        <input
                            type="text"
                            v-model="cropperY"
                            class="form-control"
                            readonly
                        />
                        <input
                            type="hidden"
                            v-model="formData.clicks"
                            value="0"
                        />
                    </div>
                    <div>
                        <label for="category">Banner Category:</label>
                        <select
                            style="height: 40px"
                            v-model="formData.category"
                            class="form-select"
                        >
                            <option value="" selected disabled>
                                Choose a Category
                            </option>
                            <option
                                v-for="category in categories"
                                :key="category.id"
                                :value="category.id"
                            >
                                {{ category.label }}
                            </option>
                        </select>
                    </div>
                    <div>
                        <label for="category">Banner Status:</label>
                        <select
                            style="height: 40px"
                            v-model="formData.status"
                            class="form-select"
                        >
                            <option value="1" selected>Published</option>
                            <option value="0">Approvel</option>
                        </select>
                    </div>
                </div>

                <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="isLoading"
                >
                    Submit
                    <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";

export default {
    name: "AddPost",
    data() {
        return {
            formData: {
                title: "",
                banner: "",
                bannerWidth: "",
                bannerHeight: "",
                clicks: 0,
                status: "1",
                category: "",
            },
            successMessage: "",
            errorMessages: [],
            bannerPreview: "",
            categories: [],
            cropperData: {
                width: null,
                height: null,
                x: null,
                y: null,
            },
            isLoading: false,
        };
    },
    computed: {
        cropperWidth() {
            return this.cropperData.width;
        },
        cropperHeight() {
            return this.cropperData.height;
        },
        cropperX() {
            return this.cropperData.x;
        },
        cropperY() {
            return this.cropperData.y;
        },
    },
    mounted() {
        this.loadCategories();
    },
    methods: {
        triggerFileInput() {
            this.$refs.bannerInput.click();
        },
        displayBanner(event) {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    if (img.width >= 250 && img.width <= 850) {
                        this.bannerPreview = e.target.result;
                        this.formData.banner = e.target.result;
                        this.formData.bannerWidth = img.width;
                        this.formData.bannerHeight = img.height;
                    } else {
                        alert(
                            "Image Minimum 250px wide and Maximum 850px wide"
                        );
                    }
                };
                img.src = e.target.result;
            };

            reader.readAsDataURL(file);
        },
        initCrop() {
            this.showCrop();
        },
        showCrop() {
            if (!this.$refs.cropPreview) {
                return;
            }
            const width = this.$refs.cropPreview.clientWidth;
            $("#showBanner").css("max-width", width);

            setTimeout(() => {
                $(".cropper-bg").addClass("background-admin");
            }, 10);
            this.cropper = new Cropper(this.$refs.cropPreview, {
                responsive: true,
                autoCropArea: 0.35,
                zoomable: false,
                zoomOnTouch: false,
                zoomOnWheel: false,
                wheelZoomRatio: false,
                crop: (event) => {
                    this.cropperData.width = event.detail.width.toFixed(0);
                    this.cropperData.height = event.detail.height.toFixed(0);
                    this.cropperData.x = event.detail.x.toFixed(0);
                    this.cropperData.y = event.detail.y.toFixed(0);
                },
            });
            setTimeout(() => {
                this.cropper.setCropBoxData({
                    top: 40,
                    left: 10,
                });
            }, 10);
        },
        submitForm() {
            const token = JSON.parse(localStorage.getItem("TOKEN"));

            this.isLoading = true;
            this.formData.profileRectWidth = this.cropperWidth;
            this.formData.profileRectHeight = this.cropperHeight;
            this.formData.profileRectX = this.cropperX;
            this.formData.profileRectY = this.cropperY;
            axios
                .post(
                    process.env.VUE_APP_API_URL + "/save/post",
                    this.formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token.token}`,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then(() => {
                    this.showToast("Post Added successfully", "success");

                    this.isLoading = false;
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                })
                .catch((error) => {
                    if (error.response && error.response.data.errors) {
                        this.errorMessages = Object.values(
                            error.response.data.errors
                        ).flat();
                        this.showToast(
                            "Error updating post: " +
                                this.errorMessages.join(", "),
                            "error"
                        );

                        window.scrollTo(0, 0);
                    } else {
                        console.error("Error submitting form:", error);
                    }
                    this.isLoading = false;
                });
        },
        loadCategories() {
            const token = JSON.parse(localStorage.getItem("TOKEN"));

            axios
                .get(process.env.VUE_APP_API_URL + "/categoryList", {
                    headers: {
                        Authorization: `Bearer ${token.token}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    this.categories = response.data.categories;
                })
                .catch((error) => {
                    console.error("Error loading categories:", error);
                });
        },

         showToast(message, type) {
            toast(message, {
                autoClose: 1000,
                type: type,
                position: "top-right",
                pauseOnHover: false,
                closeOnClick: false,
            });
        },
    },
};
</script>

<style>
.background-admin {
    background: rgb(0, 0, 0);
}
</style>
