<template>
    <div class="main-section">
        <div class="row">
            <form
                @submit.prevent="updateForm"
                class="addPost-form mb-4 col-8"
                enctype="multipart/form-data"
            >
                <div class="mb-3">
                    <label class="form-label">Add Images</label>
                    <input
                        type="file"
                        class="form-control mb-2"
                        @change="handleImageUpload(1)"
                    />
                    <input
                        type="file"
                        class="form-control mb-2"
                        @change="handleImageUpload(2)"
                    />
                    <input
                        type="file"
                        class="form-control mb-2"
                        @change="handleImageUpload(3)"
                    />
                </div>

                <div class="mb-3">
                    <label class="form-label">Add Title</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="formData.title"
                    />
                </div>

                <div class="mb-3">
                    <label class="form-label">Add Link</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="formData.link"
                    />
                </div>

                <div class="mb-3">
                    <label class="form-label">Add Description</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="formData.description"
                    />
                </div>

                <div class="mb-3">
                    <label for="category">Explore Status:</label>
                    <select
                        style="height: 40px"
                        v-model="formData.status"
                        class="form-select"
                    >
                        <option value="1" selected>Active</option>
                        <option value="0">Inactive</option>
                    </select>
                </div>
                <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="isLoading"
                >
                    Submit
                    <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
    name: "UpdateCategory",
    data() {
        return {
            formData: {
                title: "",
                image_1: null,
                image_2: null,
                image_3: null,
                link: "",
                description: "",
                status: "",
            },
            successMessage: "",
            errorMessages: [],
            isLoading: false,
        };
    },
    mounted() {
        this.updateCategoryById();
    },
    methods: {
        handleImageUpload(index) {
            const fileInput = event.target;
            if (fileInput.files.length > 0) {
                switch (index) {
                    case 1:
                        this.formData.image_1 = fileInput.files[0];
                        break;
                    case 2:
                        this.formData.image_2 = fileInput.files[0];
                        break;
                    case 3:
                        this.formData.image_3 = fileInput.files[0];
                        break;
                    default:
                        break;
                }
            }
        },
        async updateCategoryById() {
            const token = JSON.parse(localStorage.getItem("TOKEN"));
            const url = `${process.env.VUE_APP_API_URL}/edit/${this.$route.params.id}/explore`;

            try {
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${token.token}`,
                        "Content-Type": "application/json",
                    },
                });
                this.formData.title = response.data.explore.title;
                this.formData.link = response.data.explore.link;
                this.formData.description = response.data.explore.description;
                this.formData.status = response.data.explore.status;
            } catch (error) {
                console.error("Error fetching category:", error);
            }
        },
        updateForm() {
            this.isLoading = true;
            const formData = new FormData();
            formData.append("title", this.formData.title);
            formData.append("link", this.formData.link);
            formData.append("description", this.formData.description);
            formData.append("status", this.formData.status);

            if (this.formData.image_1) {
                formData.append("image_1", this.formData.image_1);
            }
            if (this.formData.image_2) {
                formData.append("image_2", this.formData.image_2);
            }
            if (this.formData.image_3) {
                formData.append("image_3", this.formData.image_3);
            }

            const token = JSON.parse(localStorage.getItem("TOKEN"));
            const updateUrl = `${process.env.VUE_APP_API_URL}/update/${this.$route.params.id}/explore`;

            axios
                .post(updateUrl, formData, {
                    headers: {
                        Authorization: `Bearer ${token.token}`,
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {
                    this.showToast("Explore Updated successfully", "success");

                    this.isLoading = false;
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                })
                .catch((error) => {
                    if (error.response && error.response.data.errors) {
                        this.errorMessages = Object.values(
                            error.response.data.errors
                        ).flat();
                        this.showToast(
                            "Error updating post: " +
                                this.errorMessages.join(", "),
                            "error"
                        );
                    } else {
                        console.error("Error submitting form:", error);
                    }
                });
        },
        showToast(message, type) {
            toast(message, {
                autoClose: 1000,
                type: type,
                position: "top-right",
                pauseOnHover: false,
                closeOnClick: false,
            });
        },
    },
};
</script>
