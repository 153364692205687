<template>
  <TopHeader v-if="showHeader && !showSideBar" />
  <router-view></router-view>
  <FooterComponent v-if="showFooter && !showSideBar" />
  <SidebarAdmin v-if="showSideBar && !showHeader && !showFooter" />
</template>

<script>
import TopHeader from "./components/TopHeader.vue";
import FooterComponent from "./components/FooterComponent.vue";
import SidebarAdmin from "./components/admin/Sidebar.vue";

export default {
  name: "App",
  components: {
    TopHeader,
    FooterComponent,
    SidebarAdmin,
  },

  computed: {
    showHeader() {
      return !this.$route.meta.hideHeader;
    },
    showFooter() {
      return !this.$route.meta.hideFooter;
    },
    showSideBar() {
      return !this.$route.meta.hideSideBar;
    },
  },
};
</script>

<style lang="scss">
@import "../src/assets/scss/style.scss";
@import "../src/components/admin/style/admin.scss";
</style>
