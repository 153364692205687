<template>
    <div>
        <hr class="login-hr" />
        <div class="row">
            <div class="col-xl-4 col-lg-5 col-md-12">
                <div class="login-form-wrapper">
                    <a class="login-logo" href="/">
                        <img src="../../assets/logo.png" alt="logoss" />
                    </a>
                    <div class="login-from">
                        <h3>Nice to see you again!</h3>

                        <form @submit.prevent="LoginUser" novalidate>
                            <div class="mb-3">
                                <label class="form-label">Login</label>
                                <input
                                    type="email"
                                    class="form-control"
                                    placeholder="Enter username or email"
                                    v-model="formData.email"
                                />
                                <p class="text-danger">
                                    {{
                                        errors.email && errors.email.join(", ")
                                    }}
                                </p>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Password</label>
                                <input
                                    type="password"
                                    class="form-control"
                                    placeholder="Enter your password"
                                    v-model="formData.password"
                                />
                                <p class="text-danger">
                                    {{
                                        errors.password &&
                                        errors.password.join(", ")
                                    }}
                                </p>
                            </div>
                            <div class="password-remember">
                                <div class="form-check form-switch">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        v-model="formData.rememberMe"
                                    />
                                    <label
                                        class="form-check-label"
                                        for="flexSwitchCheckDefault"
                                        >Remember me</label
                                    >
                                </div>
                                <div>
                                    <!-- <router-link to="/forgot-password"
                                    >Forgot password?</router-link
                                > -->
                                </div>
                            </div>
                            <button
                                type="submit"
                                class="btn btn-primary submitBtn"
                                :disabled="isLoading"
                            >
                                Sign in
                                <span
                                    v-if="isLoading"
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            </button>
                        </form>
                        <h6>Or continue with</h6>

                        <div class="other-login">
                            <button @click="googleLogin">
                                <svg
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19.8684 10.4156C19.8693 9.73413 19.8113 9.0539 19.695 8.38232H10.1992V12.2335H15.638C15.5267 12.8486 15.2911 13.435 14.9455 13.9572C14.5999 14.4795 14.1513 14.9268 13.6269 15.2723V17.7721H16.8728C18.7734 16.0319 19.8684 13.4585 19.8684 10.4156Z"
                                        fill="#4285F4"
                                    />
                                    <path
                                        d="M10.1997 20.1875C12.9169 20.1875 15.2049 19.3014 16.8733 17.7737L13.6274 15.2739C12.7239 15.8822 11.5604 16.2294 10.1997 16.2294C7.57328 16.2294 5.34408 14.4711 4.54693 12.1018H1.20312V14.6781C2.0412 16.3342 3.32629 17.7264 4.91494 18.6993C6.50358 19.6722 8.33324 20.1874 10.1997 20.1875Z"
                                        fill="#34A853"
                                    />
                                    <path
                                        d="M4.54686 12.1015C4.12543 10.8599 4.12543 9.51543 4.54686 8.27389V5.69763H1.20305C0.498032 7.09083 0.130859 8.62846 0.130859 10.1877C0.130859 11.7469 0.498032 13.2845 1.20305 14.6777L4.54686 12.1015Z"
                                        fill="#FBBC04"
                                    />
                                    <path
                                        d="M10.1997 4.14629C11.6356 4.12299 13.0231 4.66179 14.0623 5.64622L16.9362 2.79219C15.1139 1.09238 12.6996 0.159159 10.1997 0.188156C8.33324 0.188241 6.50358 0.703484 4.91494 1.67636C3.32629 2.64924 2.0412 4.04147 1.20312 5.6976L4.54693 8.27386C5.34408 5.90454 7.57328 4.14629 10.1997 4.14629Z"
                                        fill="#EA4335"
                                    />
                                </svg>
                                Google
                            </button>
                        </div>

                        <p class="not-login">
                            Dont have an account?
                            <router-link to="/signUp">Sign up now</router-link>
                        </p>
                    </div>

                    <div class="login-footer">
                        © 2024 All Rights Reserved, Bellboost
                    </div>
                </div>
            </div>
            <div class="col-xl-8 col-lg-7 col-md-12 login-bg-img"></div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { inject } from "vue";

export default {
    name: "LoginPage",

    data() {
        return {
            formData: {
                name: "",
                email: "",
                password: "",
                rememberMe: false,
            },
            loginData: {
                device_name: "browser",
            },
            user: "",
            errors: {},
            isLoading: false,
        };
    },

    watch: {
        rememberMe(newVal) {
            this.formData.rememberMe = newVal;
        },
    },
    created() {
        this.checkDeviceType();
        this.checkTokenExpiration();

        window.addEventListener("resize", this.checkDeviceType);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.checkDeviceType);
    },

    methods: {
        checkDeviceType() {
            const isMobileScreen =
                window.matchMedia("(max-width: 767px)").matches;
            const isMobileAgent = /Mobi|Android|iPhone|iPad|iPod/i.test(
                navigator.userAgent
            );
            this.loginData.device_name =
                isMobileScreen || isMobileAgent ? "mobile" : "desktop";
        },
        checkTokenExpiration() {
            const tokenData = JSON.parse(localStorage.getItem("TOKEN"));
            if (tokenData) {
                const currentTime = new Date().getTime();
                if (currentTime > tokenData.expiration) {
                    localStorage.removeItem("TOKEN");
                    localStorage.removeItem("USER");
                    localStorage.removeItem("ROLE");
                    this.$router.push("/");
                }
            }
        },
        LoginUser() {
            this.isLoading = true;
            this.checkDeviceType();

            const requestData = {
                ...this.formData,
                device_name: this.loginData.device_name,
            };

            axios
                .post(process.env.VUE_APP_API_URL + "/login", requestData)
                .then((res) => {
                    const tokenExpiration = this.formData.rememberMe
                        ? 30 * 24 * 60 * 60 * 1000 // 30 days
                        : 24 * 60 * 60 * 1000; // 24 hours

                    const tokenData = {
                        token: res.data.token,
                        expiration: new Date().getTime() + tokenExpiration,
                    };

                    localStorage.setItem("TOKEN", JSON.stringify(tokenData));
                    localStorage.setItem("USER", JSON.stringify(res.data.user));
                    localStorage.setItem(
                        "ROLE",
                        JSON.stringify(res.data?.role)
                    );

                    this.showToast("Login Successful");
                    this.isLoading = false;

                    const redirectTo =
                        res.data.role.slug === "admin"
                            ? "/admin/dashboard"
                            : "/";
                    setTimeout(() => {
                        this.$router.push(redirectTo);
                    }, 1800);
                })
                .catch((errors) => {
                    this.errors = errors.response.data.errors;
                    this.isLoading = false;
                    toast("Login Error", {
                        autoClose: 1000,
                        type: "error",
                        position: "top-right",
                        pauseOnHover: false,
                        closeOnClick: false,
                    });
                });
        },

        async googleLogin() {
            try {
                const googleUser = await this.$gAuth.signIn();

                if (!googleUser) {
                    return null;
                }
                const idToken = googleUser.getAuthResponse().id_token;
                this.sendGoogleTokenToBackend(idToken);
            } catch (error) {
                console.log(error);
                return null;
            }
        },

        async sendGoogleTokenToBackend(idToken) {
            try {
                const data = {
                    idToken: idToken,
                    device_name: this.loginData.device_name,
                };

                const response = await axios.post(
                    process.env.VUE_APP_API_URL + "/login/google",
                    data
                );

                if (response.data.success) {
                    const tokenExpiration = this.formData.rememberMe
                        ? 30 * 24 * 60 * 60 * 1000 // 30 days
                        : 24 * 60 * 60 * 1000; // 24 hours
                    const tokenData = {
                        token: response.data.token,
                        expiration: new Date().getTime() + tokenExpiration,
                    };

                    localStorage.setItem("TOKEN", JSON.stringify(tokenData));
                    localStorage.setItem(
                        "USER",
                        JSON.stringify(response.data.user)
                    );
                    localStorage.setItem(
                        "ROLE",
                        JSON.stringify(response.data.role)
                    );
                    console.log(response);
                    this.user = response.data.user;

                    this.showToast("Logged in successfully");

                    if (response.data.role.slug == "admin") {
                        setTimeout(() => {
                            this.$router.push("/admin/dashboard");
                        }, 1800);
                    } else {
                        setTimeout(() => {
                            this.$router.push("/");
                        }, 1800);
                    }
                } else {
                    toast("Login Failed", {
                        autoClose: 1000,
                        type: "error",
                        position: "top-right",
                        pauseOnHover: false,
                        closeOnClick: false,
                    });
                }
            } catch (error) {
                console.log(error);
                toast("Login error", {
                    autoClose: 1000,
                    type: "error",
                    position: "top-right",
                    pauseOnHover: false,
                    closeOnClick: false,
                });
            }
        },

        showToast(message) {
            toast(message, {
                autoClose: 1000,
                type: "success",
                position: "top-right",
                pauseOnHover: false,
                closeOnClick: false,
            });
        },
    },
    setup() {
        const Vue3GoogleOauth = inject("Vue3GoogleOauth");

        return {
            Vue3GoogleOauth,
        };
    },
};
</script>
