<template>
  <section class="hero-section category-section search-section faq-section">
    <div class="container">
      <div class="hero-content">
        <h4 class="section-heading">FAQ</h4>
      </div>
    </div>
  </section>

  <section class="faq">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What services do you offer?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  We offer a wide range of services including web development,
                  mobile app development, AI-driven solutions, and WebRTC-based
                  communication tools. Our expertise spans across various
                  industries and niches.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  How long does it take to develop a mobile app?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The timeline for developing a mobile app varies depending on
                  factors such as complexity, features, and platform. Typically,
                  the development process can take anywhere from a few weeks to
                  several months.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What industries do you specialize in?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  We specialize in serving diverse industries including
                  education, business, gaming, healthcare, and more. Our team
                  has the expertise to tailor solutions to meet the unique needs
                  of each industry.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="accordion" id="accordionExample2">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse4"
                  aria-expanded="true"
                  aria-controls="collapse4"
                >
                  Can you customize solutions to fit our specific requirements?
                </button>
              </h2>
              <div
                id="collapse4"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample2"
              >
                <div class="accordion-body">
                  Absolutely! We pride ourselves on delivering bespoke solutions
                  tailored to our clients' exact specifications. Whether you
                  need a customized CRM system or a unique SaaS offering, we
                  have the expertise to bring your vision to life.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse2"
                  aria-expanded="false"
                  aria-controls="collapse2"
                >
                  What sets your AI-driven solutions apart?
                </button>
              </h2>
              <div
                id="collapse2"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample2"
              >
                <div class="accordion-body">
                  Our AI-driven solutions leverage state-of-the-art technology
                  such as data analytics, face detection, and natural language
                  processing to deliver cutting-edge capabilities. We prioritize
                  innovation and efficiency to ensure that our solutions provide
                  tangible benefits to our clients.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse3"
                  aria-expanded="false"
                  aria-controls="collapse3"
                >
                  How can we get started with your services?
                </button>
              </h2>
              <div
                id="collapse3"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample2"
              >
                <div class="accordion-body">
                  Getting started is easy! Simply reach out to us through our
                  website or contact us directly. We'll schedule a consultation
                  to discuss your requirements, goals, and how we can best
                  support your project.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FAQ",
};
</script>
