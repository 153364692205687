<template>
  <section class="contact-us" style="border-top: 1px solid #ececec">
    <div class="container">
      <h2 class="section-heading mb-3">Contact Us</h2>
      <p class="section-subheading" style="max-width: 750px">
        Please write, if you have any suggestions or feedback for us. We are
        continuously learning and improving. We give importance to our users’
        opinions. Here you go!
      </p>

      <form @submit.prevent="submitForm">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">Your name</label>
              <input
                v-model="contact.name"
                type="text"
                class="form-control"
                placeholder="Enter your full name"
              />
              <p class="text-danger mb-0 validation">
                {{ errors.name && errors.name.join(", ") }}
              </p>
            </div>
            <div class="mb-3">
              <label class="form-label">Subject</label>
              <input
                v-model="contact.subject"
                type="text"
                class="form-control"
                placeholder="Enter email subject"
              />
              <p class="text-danger mb-0 validation">
                {{ errors.subject && errors.subject.join(", ") }}
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">Your Email</label>
              <input
                v-model="contact.email"
                type="email"
                class="form-control"
                placeholder="Enter your email"
              />
              <p class="text-danger mb-0 validation">
                {{ errors.email && errors.email.join(", ") }}
              </p>
            </div>
            <div class="mb-3">
              <label class="form-label">Your Phone</label>
              <input
                v-model="contact.phone"
                type="text"
                class="form-control"
                placeholder="Enter your phone number"
              />
              <p class="text-danger mb-0 validation">
                {{ errors.phone && errors.phone.join(", ") }}
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">Messsage</label>
              <textarea
                class="form-control"
                placeholder="Enter your message"
                v-model="contact.message"
              ></textarea>
              <p class="text-danger mb-0 validation">
                {{ errors.message && errors.message.join(", ") }}
              </p>
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-primary submitBtn"
          :disabled="isLoading"
        >
          Send
          <span
            v-if="isLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  name: "ContactUs",

  data() {
    return {
      contact: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      },
      errors: {},
      isLoading: false,
    };
  },
  methods: {
    submitForm() {
      this.isLoading = true;
      axios
        .post(process.env.VUE_APP_API_URL + "/submit-form", this.contact)
        .then((response) => {
          response.data;
          console.log(response);
          this.contact.name = "";
          this.contact.email = "";
          this.contact.subject = "";
          this.contact.phone = "";
          this.contact.message = "";
          this.errors = {};
          this.showToast("Contact Submit Successfully!");
          this.isLoading = false;
        })

        .catch((errors) => {
          this.errors = errors.response.data.errors;
          this.isLoading = false;

          toast("Submit Failed", {
            autoClose: 1000,
            type: "error",
            position: "top-right",
            pauseOnHover: false,
            closeOnClick: false,
          });
        });
    },

    showToast(message) {
      toast(message, {
        autoClose: 1000,
        type: "success",
        position: "top-right",
        pauseOnHover: false,
        closeOnClick: false,
      });
    },
  },
};
</script>
