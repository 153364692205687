<template>
    <div class="main-section">
        <div class="row">
            <div
                v-if="successMessage"
                id="successAlert"
                class="alert alert-success"
            >
                {{ successMessage }}
            </div>
            <div v-if="errorMessages.length" class="alert alert-danger my-2">
                <ul>
                    <li v-for="(error, index) in errorMessages" :key="index">
                        {{ error }}
                    </li>
                </ul>
            </div>

            <form
                @submit.prevent="updateForm"
                class="addPost-form mb-4 col-8"
                enctype="multipart/form-data"
            >
                <div class="mb-3">
                    <label class="form-label">Add Image </label>
                    <input
                        type="file"
                        class="form-control"
                        @change="handleImageUpload"
                    />
                </div>

                <div class="mb-3">
                    <label class="form-label">Add Title</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="formData.title"
                    />
                </div>

                <div class="mb-3">
                    <label class="form-label">Add Link</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="formData.link"
                    />
                </div>

                <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="isLoading"
                >
                    Submit
                    <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
    name: "UpdateCategory",
    data() {
        return {
            formData: {
                title: "",
                image: null,
                link: "",
            },
            successMessage: "",
            errorMessages: [],
            isLoading: false,
        };
    },
    mounted() {
        this.updateCategoryById();
    },
    methods: {
        handleImageUpload(event) {
            this.formData.image = event.target.files[0];
        },

        async updateCategoryById() {
            const token = JSON.parse(localStorage.getItem("TOKEN"));

            let url =
                process.env.VUE_APP_API_URL +
                `/edit/${this.$route.params.id}/category`;
            await axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token.token}`,
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    this.formData.title = response.data.category.title;
                    this.formData.link = response.data.category.link;
                });
        },
        updateForm() {
            this.isLoading = true;
            const formData = new FormData();
            formData.append("title", this.formData.title);
            formData.append("link", this.formData.link);
            if (this.formData.image) {
                formData.append("image", this.formData.image);
            } else {
                // formData.append("image", this.formData.currentImage);
            }
            const token = JSON.parse(localStorage.getItem("TOKEN"));

            axios
                .post(
                    process.env.VUE_APP_API_URL +
                        `/update/${this.$route.params.id}/category`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token.token}`,
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .then(() => {
                    this.showToast("Category Updated successfully", "success");

                    window.scrollTo(0, 0);
                    this.isLoading = false;
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                })
                .catch((error) => {
                    this.isLoading = false;
                    if (error.response && error.response.data.errors) {
                        this.errorMessages = Object.values(
                            error.response.data.errors
                        ).flat();
                        this.showToast(
                            "Error updating post: " +
                                this.errorMessages.join(", "),
                            "error"
                        );

                        window.scrollTo(0, 0);
                    } else {
                        console.error("Error submitting form:", error);
                    }
                });
        },
        showToast(message, type) {
            toast(message, {
                autoClose: 1000,
                type: type,
                position: "top-right",
                pauseOnHover: false,
                closeOnClick: false,
            });
        },
    },
};
</script>
