<template>
  <section class="hero-section category-section search-section faq-section">
    <div class="container">
      <div class="hero-content">
        <h4 class="section-heading">Terms and Conditions</h4>
      </div>
    </div>
  </section>

  <section class="privacy-wrapper">
    <div class="container">
      <div class="privacy-text">
        <div class="text-wrapper">
          <p>
            Welcome to Bellboost, These terms and conditions outline the rules
            and regulations for the use of Bellboost's Website, located at
            www.bellboost.com.
          </p>
          <p>
            By accessing this website we assume you accept these terms and
            conditions. Do not continue to use Bellboost if you do not agree to
            take all of the terms and conditions stated on this page.
          </p>
          <p>
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice and all Agreements:
            "Client", "You" and "Your" refers to you, the person log on this
            website and compliant to the Company’s terms and conditions. "The
            Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
            "Party", "Parties", or "Us", refers to both the Client and
            ourselves. All terms refer to the offer, acceptance, and
            consideration of payment necessary to undertake the process of our
            assistance to the Client in the most appropriate manner for the
            express purpose of meeting the Client’s needs in respect of
            provision of the Company’s stated services, in accordance with and
            subject to, prevailing law of Pakistan.
          </p>
        </div>
        <div class="text-wrapper">
          <h4>Cookies</h4>
          <p>
            We employ the use of cookies. By accessing Bellboost, you agreed to
            use cookies in agreement with the Bellboost's Privacy Policy.
          </p>
          <p>
            Most interactive websites use cookies to let us retrieve the user’s
            details for each visit. Cookies are used by our website to enable
            the functionality of certain areas to make it easier for people
            visiting our website. Some of our affiliate/advertising partners may
            also use cookies.
          </p>
        </div>
        <div class="text-wrapper">
          <h4>Intellectual Property</h4>
          <p>
            The Website and its original content, features, and functionality
            are owned by Bellboost and are protected by international copyright,
            trademark, patent, trade secret, and other intellectual property or
            proprietary rights laws.
          </p>
        </div>
        <div class="text-wrapper">
          <h4>User Content</h4>
          <p>
            By posting or submitting any content on the Website, you grant
            Bellboost a non-exclusive, royalty-free, perpetual, irrevocable, and
            fully sublicensable right to use, reproduce, modify, adapt, publish,
            translate, create derivative works from, distribute, and display
            such content worldwide in any media.
          </p>
        </div>
        <div class="text-wrapper">
          <h4>Limitation of Liability</h4>
          <p>
            In no event shall [Company Name], nor its directors, employees,
            partners, agents, suppliers, or affiliates, be liable for any
            indirect, incidental, special, consequential, or punitive damages,
            including without limitation, loss of profits, data, use, goodwill,
            or other intangible losses, resulting from (i) your access to or use
            of or inability to access or use the Website; (ii) any conduct or
            content of any third party on the Website; (iii) any content
            obtained from the Website; and (iv) unauthorised access, use, or
            alteration of your transmissions or content.
          </p>
        </div>

        <div class="text-wrapper">
          <h4>Changes</h4>
          <p>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. If a revision is material, we will try to
            provide at least 30 days' notice prior to any new terms taking
            effect. What constitutes a material change will be determined at our
            sole discretion.
          </p>
        </div>
        <div class="text-wrapper">
          <h4>Contact Us</h4>
          <p>If you have any questions about these Terms, please contact us at support@bellboost.com.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TermsCondition",
};
</script>
