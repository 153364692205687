<template>
    <div>
        <section class="hero-section category-section">
            <div class="container">
                <div class="hero-content">
                    <h3 class="text-capitalize">
                        {{ this.$route.params.name }} Cards
                    </h3>
                    <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">
                            <i class="fas fa-search"></i>
                        </span>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Find Templates..."
                            @keyup.enter="updateSearch"
                        />
                    </div>
                    <p>
                        Elevate {{ this.$route.params.name }} with our charming
                        card templates designed for unforgettable celebrations.
                    </p>
                </div>
            </div>
        </section>

        <section v-if="!loading" class="pick-banner category-banner">
            <div class="container">
                <div class="filter-item" v-if="tamplateData.length">
                    <p>
                        Showing
                        <span>{{ (currentPage - 1) * itemsPerPage + 1 }}</span>
                        –
                        <span>{{
                            Math.min(
                                currentPage * itemsPerPage,
                                tamplateData.length
                            )
                        }}</span>
                        of
                        <span>{{ tamplateData.length }}</span>
                        results
                    </p>
                    <div>
                        <label class="form-label">Sort by</label>
                        <select
                            class="form-select"
                            v-model="sortBy"
                            aria-label="Default select example"
                        >
                            <option value="popular" selected>Popularity</option>
                            <option value="title">Title</option>
                        </select>
                    </div>
                </div>
                <h3 v-else class="text-center section-heading mt-5">
                    Data not found
                </h3>
                <div class="row">
                    <div
                        class="col-lg-3 col-md-4"
                        v-for="(item, index) in sortedAndSlicedTemplates"
                        :key="index"
                    >
                        <div class="card-banner">
                            <img
                                :src="getPostImage(item.banner)"
                                alt="Post Image"
                            />
                            <div class="card-text">
                                <p>{{ item.title }}</p>
                                <router-link
                                    :to="{
                                        name: 'EditTemplate',
                                        query: {
                                            title: transformTitleToUrl(
                                                item.title
                                            ),
                                            id: item.id,
                                        },
                                    }"
                                    @click.prevent="startEditing(item)"
                                >
                                    <i class="fa fa-pencil"></i>Start editing
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="discover-btn" v-if="moreToShow">
                    <button type="button" @click="loadMore">
                        Explore more <i class="fa fa-arrow-up"></i>
                    </button>
                </div>
            </div>
        </section>

        <div v-if="loading" class="text-center mt-5">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <Subscribe />
    </div>
</template>

<script>
import Subscribe from "./Subscribe.vue";
import axios from "axios";

export default {
    name: "PostData",

    components: {
        Subscribe,
    },

    data() {
        return {
            currentPage: 1,
            itemsPerPage: 8,
            search: "",
            sortBy: "popular",
            tamplateData: [],
            loading: true,
        };
    },
    created() {
        this.fetchData();
    },
    watch: {
        $route() {
            this.fetchData();
        },
    },
    methods: {
        startEditing(item) {
            this.scrollToTop();
            const userData = localStorage.getItem("USER");
            const token = JSON.parse(localStorage.getItem("TOKEN"));
            const user = userData ? JSON.parse(userData) : null;
            axios.post(process.env.VUE_APP_API_URL + "/increment-clicks", {
                postId: item.id,
            });
            if (user) {
                axios
                    .post(
                        process.env.VUE_APP_API_URL + `/user-activities`,
                        {
                            postId: item.id,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token.token}`,
                                "Content-Type": "application/json",
                            },
                        }
                    )
                    .catch((error) => {
                        console.error("Error logging user activity:", error);
                    });
            }
        },

        loadMore() {
            this.itemsPerPage *= 2;
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        updateSearch(event) {
            this.search = event.target.value;
            if (event.keyCode === 13 && this.search.trim() !== "") {
                this.$router.push({
                    name: "FilteredTemplates",
                    query: { search: this.search },
                });
            }
        },

        async fetchData() {
            try {
                this.loading = true;
                const name = this.$route.params.name;
                const response = await axios.get(
                    process.env.VUE_APP_API_URL + `/posts?banner=${name}`
                );
                if (response.status === 200) {
                    this.tamplateData = response.data.categories
                        .map((banner) => banner.posts)
                        .flat();
                }
            } catch (error) {
                console.error("Error retrieving data:", error);
            } finally {
                this.loading = false;
            }
        },
        getPostImage(base64ImageData) {
            // return "data:image/png;base64," + base64ImageData;
            return base64ImageData;
        },

        transformTitleToUrl(title) {
            return title.toLowerCase().replace(/\s+/g, "-");
        },
    },

    computed: {
        sortedTemplates() {
            let sortedData = [...this.tamplateData];
            if (this.sortBy === "popular") {
                sortedData.sort((a, b) => b.clicks - a.clicks);
            } else if (this.sortBy === "title") {
                sortedData.sort((a, b) => a.title.localeCompare(b.title));
            }
            return sortedData;
        },

        sortedAndSlicedTemplates() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            return this.sortedTemplates.slice(
                startIndex,
                startIndex + this.itemsPerPage
            );
        },

        moreToShow() {
            return (
                this.currentPage * this.itemsPerPage < this.tamplateData.length
            );
        },
    },
};
</script>
