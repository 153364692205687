<template>
<span>
  <hr class="login-hr" />
  <div class="row">
    <div class="col-xl-4 col-lg-5 col-md-12">
      <div class="login-form-wrapper">
        <router-link class="login-logo" to="/">
          <img src="../../assets/logo.png" alt="logoss" />
        </router-link>
        <div class="login-from">
          <h3>Join Us and Unleash Your Creativity with a Fresh Account!</h3>
          <form @submit.prevent="registrationUser" novalidate>
            <div class="mb-3">
              <label class="form-label">Full name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter your name"
                v-model="formData.name"
              />
              <p class="text-danger">
                {{ errors.name && errors.name.join(", ") }}
              </p>
            </div>

            <div class="mb-3">
              <label class="form-label">Email</label>
              <input
                type="email"
                class="form-control"
                placeholder="Enter username or email"
                v-model="formData.email"
              />

              <p class="text-danger">
                {{ errors.email && errors.email.join(", ") }}
              </p>
            </div>
            <div class="mb-3">
              <label class="form-label">Password</label>
              <input
                type="password"
                class="form-control"
                placeholder="Enter your password"
                v-model="formData.password"
              />

              <p class="text-danger">
                {{ errors.password && errors.password.join(", ") }}
              </p>
            </div>
            <button
              type="submit"
              class="btn btn-primary submitBtn"
              :disabled="isLoading"
            >
              Sign up
              <span
                v-if="isLoading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </form>
          <h6>Or continue with</h6>

          <div class="other-login">
            <button type="button" @click="googleSignUp">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.8684 10.4156C19.8693 9.73413 19.8113 9.0539 19.695 8.38232H10.1992V12.2335H15.638C15.5267 12.8486 15.2911 13.435 14.9455 13.9572C14.5999 14.4795 14.1513 14.9268 13.6269 15.2723V17.7721H16.8728C18.7734 16.0319 19.8684 13.4585 19.8684 10.4156Z"
                  fill="#4285F4"
                />
                <path
                  d="M10.1997 20.1875C12.9169 20.1875 15.2049 19.3014 16.8733 17.7737L13.6274 15.2739C12.7239 15.8822 11.5604 16.2294 10.1997 16.2294C7.57328 16.2294 5.34408 14.4711 4.54693 12.1018H1.20312V14.6781C2.0412 16.3342 3.32629 17.7264 4.91494 18.6993C6.50358 19.6722 8.33324 20.1874 10.1997 20.1875Z"
                  fill="#34A853"
                />
                <path
                  d="M4.54686 12.1015C4.12543 10.8599 4.12543 9.51543 4.54686 8.27389V5.69763H1.20305C0.498032 7.09083 0.130859 8.62846 0.130859 10.1877C0.130859 11.7469 0.498032 13.2845 1.20305 14.6777L4.54686 12.1015Z"
                  fill="#FBBC04"
                />
                <path
                  d="M10.1997 4.14629C11.6356 4.12299 13.0231 4.66179 14.0623 5.64622L16.9362 2.79219C15.1139 1.09238 12.6996 0.159159 10.1997 0.188156C8.33324 0.188241 6.50358 0.703484 4.91494 1.67636C3.32629 2.64924 2.0412 4.04147 1.20312 5.6976L4.54693 8.27386C5.34408 5.90454 7.57328 4.14629 10.1997 4.14629Z"
                  fill="#EA4335"
                />
              </svg>
              Google
            </button>
            <!-- <button type="button">
              <i class="fab fa-facebook-f"></i>Facebook
            </button> -->
          </div>

          <p class="not-login">
            Already have an account?
            <router-link to="/login">Login here</router-link>
          </p>
        </div>

        <div class="login-footer">© 2024 All Rights Reserved, Bellboost</div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-7 col-md-12 login-bg-img signup-bg-img"></div>
  </div>
  </span>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { inject } from "vue";

export default {
  name: "SignUp",

  data() {
    return {
      formData: {
        name: "",
        email: "",
        password: "",
      },
      signupData: {
        device_name: "browser",
      },
      errors: {},
      isLoading: false,
    };
  },
  methods: {
    checkDeviceType() {
      const isMobileScreen = window.matchMedia("(max-width: 767px)").matches;
      const isMobileAgent = /Mobi|Android|iPhone|iPad|iPod/i.test(
        navigator.userAgent
      );
      this.signupData.device_name =
        isMobileScreen || isMobileAgent ? "mobile" : "desktop";
    },
    registrationUser() {
      this.isLoading = true;
      this.checkDeviceType();

      const requestData = {
        ...this.formData,
        device_name: this.signupData.device_name,
      };

      axios
        .post(process.env.VUE_APP_API_URL + "/register", requestData)
        .then(() => {
          this.formData.name = "";
          this.formData.email = "";
          this.formData.password = "";
          this.errors = {};
          this.showToast("Registration successful!");
          this.isLoading = false;

          setTimeout(() => {
            this.$router.push("/login");
          }, 1200);
        })
        .catch((errors) => {
          this.errors = errors.response.data.errors;
          this.isLoading = false;

          toast("Registration Failed", {
            autoClose: 1000,
            type: "error",
            position: "top-right",
            pauseOnHover: false,
            closeOnClick: false,
          });
        });
    },
    async googleSignUp() {
      try {
        const googleUser = await this.$gAuth.signIn();

        if (!googleUser) {
          return null;
        }
        const idToken = googleUser.getAuthResponse().id_token;
        this.sendGoogleTokenToBackend(idToken);
      } catch (error) {
        console.log(error);
        return null;
      }
    },

    async sendGoogleTokenToBackend(idToken) {
      try {
        const data = {
          idToken: idToken,
          device_name: this.signupData.device_name,
        };

        const response = await axios.post(
          process.env.VUE_APP_API_URL + "/login/google",
          data
        );

        if (response.data.success) {
          localStorage.setItem("TOKEN", response.data.token);
          localStorage.setItem("USER", JSON.stringify(response.data.user));
          localStorage.setItem("ROLE", JSON.stringify(response.data.role));

          this.user = response.data.user;

          this.showToast("Logged in successfully");

          if (response.data.role.slug == "admin") {
            setTimeout(() => {
              this.$router.push("/admin/dashboard");
            }, 1800);
          } else {
            setTimeout(() => {
              this.$router.push("/");
            }, 1800);
          }
        } else {
          toast("Signup Failed", {
            autoClose: 1000,
            type: "error",
            position: "top-right",
            pauseOnHover: false,
            closeOnClick: false,
          });
        }
      } catch (error) {
        console.log(error);
        toast("Signup error ", {
          autoClose: 1000,
          type: "error",
          position: "top-right",
          pauseOnHover: false,
          closeOnClick: false,
        });
      }
    },

    showToast(message) {
      toast(message, {
        autoClose: 1000,
        type: "success",
        position: "top-right",
        pauseOnHover: false,
        closeOnClick: false,
      });
    },
  },
  created() {
    this.checkDeviceType();
    window.addEventListener("resize", this.checkDeviceType);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkDeviceType);
  },
  setup() {
    const Vue3GoogleOauth = inject("Vue3GoogleOauth");

    return {
      Vue3GoogleOauth,
    };
  },
};
</script>
