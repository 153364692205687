<template>
  <section class="hero-section category-section search-section faq-section">
    <div class="container">
      <div class="hero-content">
        <h4 class="section-heading">Privacy Policy</h4>
      </div>
    </div>
  </section>

  <section class="privacy-wrapper">
    <div class="container">
      <div class="privacy-text">
        <div class="text-wrapper">
          <h4>Effective Date: [May 8, 2024]</h4>
          <p>
            Our company Bellboost ("we", "us", or "our") is committed to
            protecting the privacy of our users. This Privacy Policy outlines
            the types of personal information we collect, how it is used, and
            the choices you have regarding your information.
          </p>
        </div>
        <div class="text-wrapper">
          <h4>Information We Collect</h4>
          <p>
            We may collect personal information such as your name, email
            address, phone number, and other contact details when you interact
            with our website, products, or services. We may also collect
            non-personal information such as your IP address, browser type, and
            device information.
          </p>
        </div>
        <div class="text-wrapper">
          <h4>How We Use Your Information</h4>
          <p>
            We use the information we collect for various purposes, including:
          </p>
          <ul>
            <li>Providing and improving our products and services</li>
            <li>Communicating with you about your account or transactions</li>
            <li>Sending you promotional offers and marketing communications</li>
            <li>Responding to your inquiries and providing customer support</li>
            <li>
              Analyzing trends and user behavior to improve our website and
              services
            </li>
          </ul>
        </div>
        <div class="text-wrapper">
          <h4>Data Security</h4>
          <p>
            We take reasonable measures to protect the security of your personal
            information and prevent unauthorized access, disclosure, or
            alteration. However, please be aware that no method of transmission
            over the internet or electronic storage is completely secure.
          </p>
        </div>
        <div class="text-wrapper">
          <h4>Third-Party Services</h4>

          <p>
            We may use third-party services such as analytics tools and
            advertising networks that may collect information about your use of
            our website and services. These third-party services may have their
            own privacy policies governing the use of your information.
          </p>
        </div>
        <div class="text-wrapper">
          <h4>Your Choices</h4>
          <p>
            You have the right to access, update, or delete your personal
            information at any time. You may also opt out of receiving
            promotional emails or marketing communications from us by following
            the instructions provided in the emails.
          </p>
        </div>
        <div class="text-wrapper">
          <h4>Changes to This Privacy Policy</h4>
          <p>
            We reserve the right to update or modify this Privacy Policy at any
            time. Any changes will be effective immediately upon posting the
            revised Privacy Policy on our website.
          </p>
        </div>
        <div class="text-wrapper">
          <h4>Contact Us</h4>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at support@bellboost.com
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>
