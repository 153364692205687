 
<template>
    <span>
        <div class="row" v-if="templates.length">
            <div
                class="col-lg-3 col-md-4"
                v-for="(item, index) in templates"
                :key="index"
            >
                <div class="card-banner">
                    <img :src="getPostImage(item.banner)" alt="Post Image" />
                    <div class="card-text">
                        <p>{{ item.title }}</p>
                        <router-link
                            :to="{
                                name: 'EditTemplate',
                                query: {
                                    title: transformTitleToUrl(item.title),
                                    id: item.id,
                                },
                            }"
                            @click.prevent="startEditing(item)"
                        >
                            <i class="fa fa-pencil"></i>Start editing
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <h3 v-else class="text-center section-heading mt-5">Data not found</h3>
        <div class="discover-btn" v-if="moreToShow">
            <button type="button" @click="loadMore" :disabled="isLoading">
                Explore more <i class="fa fa-arrow-up"  v-if="!isLoading"></i>

                <span
                    v-if="isLoading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
            </button>
        </div>
    </span>
</template>

<script>
import axios from "axios";

export default {
    name: "NewlyAdded",
    data() {
        return {
            currentPage: 1,
            itemsPerPage: 8,
            templates: [],
            postCount: 0,
            isLoading:false

        };
    },
    methods: {
        async startEditing(item) {
            this.scrollToTop();
            const userData = localStorage.getItem("USER");
            const token = JSON.parse(localStorage.getItem("TOKEN"));

            const user = userData ? JSON.parse(userData) : null;
            axios.post(process.env.VUE_APP_API_URL + "/increment-clicks", {
                postId: item.id,
            });
            if (user) {
                axios
                    .post(
                        process.env.VUE_APP_API_URL + `/user-activities`,
                        {
                            postId: item.id,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token.token}`,
                                "Content-Type": "application/json",
                            },
                        }
                    )
                    .catch((error) => {
                        console.error("Error logging user activity:", error);
                    });
            }
        },
        async loadMore() {
            this.currentPage += 1;
            await this.fetchData();
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        async fetchData() {
                    this.isLoading=true;

            try {
                const response = await axios.get(
                    process.env.VUE_APP_API_URL + "/new-posts",
                    {
                        params: {
                            page: this.currentPage,
                            item: this.itemsPerPage,
                        },
                    }
                );
                if (response.status === 200) {
                    this.templates = [...this.templates, ...response.data.newpost];
                    this.postCount = response.data.cont;
                    this.isLoading=false;
                }
            } catch (error) {
                console.error("Error retrieving data:", error);
            }
        },
        getPostImage(base64ImageData) {
            return base64ImageData;
        },
        transformTitleToUrl(title) {
            return title.toLowerCase().replace(/\s+/g, "-");
        },
    },
    computed: {
        moreToShow() {
            return this.templates.length < this.postCount;
        },
    },
    created() {
        this.fetchData();
    },
};
</script>

