<template>
    <section class="hero-section">
        <div class="container">
            <div class="hero-content">
                <h2>Make your occasion special</h2>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"
                        ><i class="fas fa-search"></i
                    ></span>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Find Templates..."
                        @input="updateSearch"
                        @keyup.enter="updateSearch"
                    />
                </div>
                <p>
                    Explore thousands of beautiful free templates. With
                    Bellboost's drag and drop feature, you can customize your
                    design for any occasion in just a few clicks.
                </p>
            </div>
        </div>
    </section>

    <!-- Browse by categories    -->

    <section class="categories">
        <div class="container">
            <h2 class="section-heading">Browse by categories</h2>
            <CategorySlider />
        </div>
    </section>

    <!-- card templates -->

    <section class="pick-banner">
        <div class="container">
            <h2 class="section-heading">Top Picks</h2>
            <p class="section-subheading mt-2">
                Explore our top picks for an instant burst of creativity –
                handpicked templates to elevate your designs effortlessly.
            </p>

            <TopRated />
        </div>
    </section>

    <!-- type card -->

    <Explore />

    <!-- newly add template -->

    <section class="pick-banner">
        <div class="container">
            <h2 class="section-heading">Newly Added</h2>
            <p class="section-subheading mt-2">
                Discover Fresh Inspiration: Dive into our newly added templates,
                curated to ignite your creative journey.
            </p>

            <NewlyAdded />
        </div>
    </section>

    <!-- subscribe -->

    <Subscribe />
</template>

<script>
import CategorySlider from "./CategorySlider.vue";
import TopRated from "./TopRated.vue";
import NewlyAdded from "./NewlyAdded.vue";
import Subscribe from "./Subscribe.vue";
import Explore from "./ExplorePage.vue";

export default {
    name: "HomePage",
    components: {
        CategorySlider,
        NewlyAdded,
        TopRated,
        Subscribe,
        Explore,
    },
    data() {
        return {
            search: "",
            filteredTemplates: [],
        };
    },
    methods: {
        updateSearch(event) {
            this.search = event.target.value;
            if (event.keyCode === 13 && this.search.trim() !== "") {
                this.$router.push({
                    name: "FilteredTemplates",
                    query: { search: this.search },
                });
            }
        },
    },
};
</script>
