<template>
  <section class="hero-section about-section">
    <div class="container">
      <div class="hero-content">
        <h4 class="section-heading">
          We are small design team with a big passion
        </h4>
      </div>
    </div>
  </section>

  <section class="about-wrapper">
    <div class="container">
      <div class="about-text-section">
        <h4>We help businesses also designers</h4>
        <p>
          We help people to grow their business using coca landing page ui kit
          with professional and powerfull digital solution. Let’s collaborate
          with our young talented designers.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>
