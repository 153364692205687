<template>
    <div class="main-section dashboard-section">
        <div class="about-web">
            <div class="row p-0">
                <div class="col-md-4 ps-0">
                    <div class="style-about">
                        <div class="icon-about">
                            <svg
                                width="25"
                                height="20"
                                viewBox="0 0 25 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M17.5 19V17C17.5 14.7909 15.7091 13 13.5 13H5.5C3.29086 13 1.5 14.7909 1.5 17V19"
                                    stroke="#7C2CE7"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <circle
                                    cx="9.5"
                                    cy="5"
                                    r="4"
                                    stroke="#7C2CE7"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M23.5 19V17C23.4986 15.1771 22.265 13.5857 20.5 13.13"
                                    stroke="#7C2CE7"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M16.5 1.13C18.2699 1.58317 19.5078 3.178 19.5078 5.005C19.5078 6.83201 18.2699 8.42684 16.5 8.88"
                                    stroke="#7C2CE7"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <h3>
                            {{ formattedUserCount ? formattedUserCount : 0 }}
                        </h3>
                        <p>Users</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="style-about">
                        <div class="icon-about">
                            <svg
                                width="19"
                                height="22"
                                viewBox="0 0 19 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M11.5156 1H3.45321C2.34003 1 1.43762 1.89543 1.43762 3V19C1.43762 20.1046 2.34003 21 3.45321 21H15.5467C16.6599 21 17.5623 20.1046 17.5623 19V7L11.5156 1Z"
                                    stroke="#7C2CE7"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M11.5156 1V7H17.5623"
                                    stroke="#7C2CE7"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M13.5312 12H5.4688"
                                    stroke="#7C2CE7"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M13.5312 16H5.4688"
                                    stroke="#7C2CE7"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M7.48439 8H6.4766H5.4688"
                                    stroke="#7C2CE7"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <h3>
                            {{
                                formattedDesignCount ? formattedDesignCount : 0
                            }}
                        </h3>
                        <p>Total Designs</p>
                    </div>
                </div>
                <div class="col-md-4 pe-0">
                    <div class="style-about">
                        <div class="icon-about">
                            <svg
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M20.9458 14.89C19.1792 19.0289 14.8427 21.492 10.3447 20.9115C5.84673 20.331 2.292 16.8494 1.6547 12.4003C1.0174 7.95116 3.45407 3.62729 7.61146 1.82999"
                                    stroke="#7C2CE7"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M21.7432 11C21.7432 8.34784 20.6797 5.8043 18.7867 3.92893C16.8937 2.05357 14.3262 1 11.6491 1V11H21.7432Z"
                                    stroke="#7C2CE7"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <h3>
                            {{
                                formattedViewerCount ? formattedViewerCount : 0
                            }}
                        </h3>
                        <p>Viewers</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pb-2" style="background: #fff; border-radius: 6px">
            <div class="chart-header">
                <p>Site Visitors</p>

                <select
                    v-model="selectedYear"
                    @change="fetchChartData"
                    class="form-select"
                    style="width: 100px"
                >
                    <option
                        v-for="year in availableYears"
                        :key="year"
                        :value="year"
                    >
                        {{ year }}
                    </option>
                </select>
            </div>
            <Bar
                v-if="isVistorGraph"
                :options="chartOptions"
                :data="chartData"
            />
            <div
                v-else
                class="d-flex justify-content-center align-items-center"
                style="height: 100px"
            >
                <span
                    class="spinner-border spinner-border"
                    role="status"
                    aria-hidden="true"
                    style="color: #7c2ce7"
                ></span>
            </div>
        </div>
        <div class="row device-section" style="margin-top: 2rem !important">
            <div
                class="col-md-8 p-3"
                style="background: #fff; border-radius: 6px"
            >
                <table class="posts-wrapper table" v-if="postData">
                    <tr class="post-header">
                        <th style="width: 40%">Design Posts</th>
                        <th>Post Date</th>
                        <th class="text-center">Category</th>
                    </tr>
                    <tbody class="post-body">
                        <tr
                            valign="middle"
                            v-for="(post, index) in banners"
                            :key="index"
                        >
                            <td>
                                <div>
                                    <img
                                        :src="post.banner"
                                        alt="post-img"
                                        width="50"
                                    />
                                    <h6>{{ post.title }}</h6>
                                </div>
                            </td>
                            <td>
                                <p>{{ formatDate(post.created_at) }}</p>
                            </td>
                            <td class="text-center">
                                <span
                                    v-for="(
                                        category, cIndex
                                    ) in post.categories"
                                    :key="cIndex"
                                >
                                    {{ category.name }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div
                    v-else
                    class="d-flex justify-content-center align-items-center"
                    style="height: 100px"
                >
                    <span
                        class="spinner-border spinner-border"
                        role="status"
                        aria-hidden="true"
                        style="color: #7c2ce7"
                    ></span>
                </div>
            </div>
            <div
                class="col-md-4 p-3"
                style="background: #fff; border-radius: 6px"
            >
                <div class="w-50 mx-auto doughuntgraph" v-if="isDoughuntGraph">
                    <h5>USED DEVICE</h5>
                    <Doughnut :data="doughnutData" :options="doughnutOptions" />
                    <div class="user-device">
                        <div>
                            <p></p>
                            Mobile
                        </div>
                        <div>
                            <p></p>
                            Web
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="d-flex justify-content-center align-items-center"
                    style="height: 100px"
                >
                    <span
                        class="spinner-border spinner-border"
                        role="status"
                        aria-hidden="true"
                        style="color: #7c2ce7"
                    ></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { Bar, Doughnut } from "vue-chartjs";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    BarElement,
    CategoryScale,
    LinearScale,
    ArcElement,
} from "chart.js";

ChartJS.register(
    Title,
    Tooltip,
    BarElement,
    CategoryScale,
    LinearScale,
    ArcElement
);

export default {
    name: "DashboardPage",
    components: { Bar, Doughnut },

    data() {
        return {
            totalUsers: null,
            totalDesigns: null,
            totalViewers: null,
            banners: [],
            userDate: [],
            formattedUserCount: "",
            formattedDesignCount: "",
            formattedViewerCount: "",
            chartData: {
                labels: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
                datasets: [
                    {
                        label: "Monthly Visitors",
                        backgroundColor: "#7C2CE7",
                        borderColor: "#7C2CE7",
                        borderWidth: 1,
                        hoverBackgroundColor: "#6a1b9a",
                        hoverBorderColor: "#6a1b9a",
                        data: [],
                        borderRadius: 30,
                        barPercentage: 0.5,
                        categoryPercentage: 0.5,
                    },
                ],
            },

            chartOptions: {
                responsive: true,
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            stepSize: 1,
                        },
                    },
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                },
            },

            selectedYear: new Date().getFullYear(),
            availableYears: [2024, 2025, 2026, 2027, 2028],
            isVistorGraph: false,
            isDoughuntGraph: false,
            postData: false,
            doughnutData: {
                labels: ["Desktop", "Mobile"],
                datasets: [
                    {
                        barPercentage: 0.5,
                        backgroundColor: ["#7C2CE7", "#280047"],
                        data: [],
                    },
                ],
            },
            doughnutOptions: {
                responsive: true,
                cutout: "50%",
            },
        };
    },
    created() {
        this.fetchTotalUsers();
        this.fetchTotalDesigns();
        this.fetchDashboardPost();
    },
    methods: {
        async fetchTotalUsers() {
            try {
                const token = JSON.parse(localStorage.getItem("TOKEN"));

                const response = await axios.get(
                    process.env.VUE_APP_API_URL + `/total/users`,
                    {
                        headers: {
                            Authorization: `Bearer ${token.token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (response.status === 200) {
                    this.totalUsers = response.data.userCount;
                    this.formattedUserCount = this.formatUserCount(
                        this.totalUsers
                    );
                    const userData = response.data.usersByMonth;
                    const monthlyCounts = this.aggregateMonthlyCounts(userData);
                    this.chartData.datasets[0].data = [...monthlyCounts];
                    this.isVistorGraph = true;
                    let doughnutDataArray = [0, 0];

                    response.data.deviceName.forEach((item) => {
                        if (item.device_name === "mobile") {
                            doughnutDataArray[1] = item.count;
                        } else if (item.device_name === "desktop") {
                            doughnutDataArray[0] = item.count;
                        }
                    });

                    this.doughnutData.datasets[0].data = doughnutDataArray;
                    this.isDoughuntGraph = true;
                }
            } catch (error) {
                console.error("Error retrieving user data:", error);
            }
        },
        async fetchTotalDesigns() {
            try {
                const token = JSON.parse(localStorage.getItem("TOKEN"));

                const response = await axios.get(
                    process.env.VUE_APP_API_URL + `/total/posts`,

                    {
                        headers: {
                            Authorization: `Bearer ${token.token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (response.status === 200) {
                    this.totalDesigns = response.data.totalPosts;
                    this.formattedDesignCount = this.formatUserCount(
                        this.totalDesigns
                    );
                    const clicksData = response.data.totalViewers;
                    this.formattedViewerCount =
                        this.formatUserCount(clicksData);
                }
            } catch (error) {
                console.error("Error retrieving design data:", error);
            }
        },
        async fetchDashboardPost() {
            try {
                const token = JSON.parse(localStorage.getItem("TOKEN"));

                const response = await axios.get(
                    process.env.VUE_APP_API_URL + `/dashboard/posts`,
                    {
                        headers: {
                            Authorization: `Bearer ${token.token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (response.status === 200) {
                    this.banners = response.data.post;
                    this.postData = true;
                }
            } catch (error) {
                console.error("Error retrieving design data:", error);
            }
        },
        formatDate(date) {
            return moment(date).format("DD MMM YYYY");
        },

        aggregateMonthlyCounts(userData) {
            const monthlyCounts = Array(12).fill(0);
            userData.forEach((monthData) => {
                const monthIndex = monthData.month - 1;
                monthlyCounts[monthIndex] = monthData.count;
            });

            return monthlyCounts;
        },

        formatUserCount(count) {
            if (count >= 1000000) {
                return (count / 1000000).toFixed(1) + "M";
            } else if (count >= 1000) {
                return (count / 1000).toFixed(1) + "k";
            } else {
                return count.toString();
            }
        },

        async fetchChartData() {
            try {
                const token = JSON.parse(localStorage.getItem("TOKEN"));
                const response = await axios.get(
                    process.env.VUE_APP_API_URL +
                        `/users/year?year=${this.selectedYear}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token.token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                this.isVistorGraph = false;
                if (response.status === 200) {
                    const userData = response.data.usersByMonth;
                    const monthlyCounts = this.aggregateMonthlyCounts(userData);
                    this.chartData.datasets[0].data = [...monthlyCounts];
                    setTimeout(() => {
                        this.isVistorGraph = true;
                    }, 500);
                }
            } catch (error) {
                console.error("Error retrieving data:", error);
                this.isVistorGraph = false;
            }
        },
    },
};
</script>
