<template>
    <div class="main-section">
        <div class="mb-3 px-3 d-flex align-items-center gap-4 card-table">
            <h3>All Posts</h3>
            <button class="btn btn-primary" @click="goToAddPost">
                Add New Post
            </button>
        </div>
        <div
            class="card-table position-relative px-3"
            :class="isLoading ? 'pb-5' : ''"
        >
        
            <table id="allpost" class="table table-striped" style="width: 100%">
                <thead>
                    <tr>
                        <th style="width: 18%">Title</th>
                        <th style="text-align: left">Banner</th>
                        <th style="text-align: left">Banner Width</th>
                        <th style="text-align: left">Banner Height</th>
                        <th style="text-align: left">Category</th>
                        <th style="text-align: left">Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody v-if="!isLoading">
                    <tr
                        v-for="(post, index) in posts.data"
                        :key="index"
                        valign="middle"
                    >
                        <td>
                            <span
                                style="
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                "
                            >
                                {{ post.title }}
                            </span>
                        </td>
                        <td style="text-align: left">
                            <img :src="post.banner" alt="img" width="60px" />
                        </td>
                        <td style="text-align: left">{{ post.bannerWidth }}</td>
                        <td style="text-align: left">
                            {{ post.bannerHeight }}
                        </td>
                        <td style="text-align: left">
                            <span
                                v-for="(category, cIndex) in post.categories"
                                :key="cIndex"
                            >
                                {{ category.name }}
                            </span>
                        </td>
                        <td style="text-align: left">
                            <span
                                style="border-radius: 5px"
                                :class="getStatusClass(post.status)"
                            >
                                {{
                                    post.status == 1 ? "Published" : "Approval"
                                }}
                            </span>
                        </td>
                        <td>
                            <router-link
                                :to="{
                                    name: 'UpdatePost',
                                    params: { id: post.id },
                                }"
                                class="btn btn-success btn-sm me-2"
                            >
                                Update
                            </router-link>
                            <button
                                @click="deletePost(post.id)"
                                class="btn btn-danger btn-sm"
                            >
                                Delete
                            </button>
                        </td>
                    </tr>
                </tbody>
                <div
                    v-else
                    class="d-flex justify-content-center align-items-center position-absolute"
                    style="height: 44px; left: 50%; transform: translateX(-50%)"
                >
                    <span
                        class="spinner-border spinner-border"
                        role="status"
                        aria-hidden="true"
                        style="color: #7c2ce7"
                    ></span>
                </div>
                <tfoot :style="isLoading ? 'transform: translateY(41px);' : ''">
                    <tr>
                        <th>Title</th>
                        <th style="text-align: left">Banner</th>
                        <th style="text-align: left">Banner Width</th>
                        <th style="text-align: left">Banner Height</th>
                        <th style="text-align: left">Category</th>
                        <th style="text-align: left">Status</th>
                        <th>Action</th>
                    </tr>
                </tfoot>
            </table>

            <div
                :style="isLoading ? 'transform: translateY(31px);' : ''"
                class="d-flex justify-content-between w-100 align-items-center table-pagination"
            >
                <div>
                    Showing {{ startPosition }} to {{ posts.to }} of
                    {{ posts.total }} entries
                </div>
                <Bootstrap5Pagination
                    :data="posts"
                    @pagination-change-page="fetchPosts"
                    class="mb-0"
                />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { Bootstrap5Pagination } from "laravel-vue-pagination";

export default {
    data() {
        return {
            posts: [],
            successMessage: "",
            isLoading: false,
        };
    },
    components: {
        Bootstrap5Pagination,
    },
    async mounted() {
        await this.fetchPosts();
        this.getStatusClass();
    },
    computed: {
        startPosition() {
            if (
                !this.posts ||
                !this.posts.current_page ||
                !this.posts.per_page
            ) {
                return 0;
            }
            return (this.posts.current_page - 1) * this.posts.per_page + 1;
        },
    },
    methods: {
        getStatusClass(status) {
            return {
                "bg-primary px-2 py-1 text-white": status == 1,
                "bg-danger px-2 py-1 text-white": status == 0,
            };
        },
        async fetchPosts(page = 1) {
            this.isLoading = true;
            const token = JSON.parse(localStorage.getItem("TOKEN"));

            await axios
                .get(process.env.VUE_APP_API_URL + `/get-posts?page=${page}`, {
                    headers: {
                        Authorization: `Bearer ${token.token}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.posts = response.data.post;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.error("Error fetching posts", error);
                });
        },

        goToAddPost() {
            this.$router.push("/admin/add-post");
        },

        deletePost(postId) {
            const token = JSON.parse(localStorage.getItem("TOKEN"));

            axios
                .get(process.env.VUE_APP_API_URL + `/post/${postId}/delete`, {
                    headers: {
                        Authorization: `Bearer ${token.token}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    this.successMessage = response.data.message;
                    this.fetchPosts();
                    this.showToast("Deleted SuccessFully");

                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                })
                .catch((error) => {
                    console.error("Error deleting post", error);
                });
        },
        showToast(message) {
            toast(message, {
                autoClose: 1000,
                type: "success",
                position: "top-right",
                pauseOnHover: false,
                closeOnClick: false,
            });
        },
    },
};
</script>
