<template>
    <div
        class="modal fade custom-modal"
        id="embedModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="section-subheading">Embed in your website</div>
                    <div class="embed-div">
                        <div>{{ embedCode }}</div>
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            class="copy"
                            @click="copyToClipboard"
                        >
                            <i class="fa fa-copy"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EmbedModel",
    props: {
        embedCode: {
            type: String,
            required: true,
        },
    },
    methods: {
        copyToClipboard() {
            navigator.clipboard
                .writeText(this.embedCode)

                .catch((err) => {
                    console.error("Failed to copy: ", err);
                });
        },
    },
};
</script>
