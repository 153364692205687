<template>
    <section class="hero-section category-section search-section">
        <div class="container">
            <div class="hero-content">
                <h4 class="section-heading">
                    Search results: “ {{ $route.query.search }} ”
                </h4>
            </div>
        </div>
    </section>

    <section class="pick-banner category-banner">
        <div class="container">
            <div>
                <div class="filter-item" v-if="allTemplates.length">
                    <p>
                        Showing
                        <span>{{ (currentPage - 1) * itemsPerPage + 1 }}</span>
                        –
                        <span>{{
                            Math.min(
                                currentPage * itemsPerPage,
                                allTemplates.length
                            )
                        }}</span>
                        of
                        <span>{{ postCount }}</span>
                        results
                    </p>
                    <div>
                        <label class="form-label">Sort by</label>
                        <select
                            class="form-select"
                            v-model="sortBy"
                            aria-label="Default select example"
                        >
                            <option value="popular" selected>Popularity</option>
                            <option value="title">Title</option>
                        </select>
                    </div>
                </div>
                <h3 v-else class="text-center section-heading mt-5">
                    Data not found
                </h3>
                <div class="row">
                    <div
                        class="col-lg-3 col-md-4"
                        v-for="(item, index) in sortedTemplates"
                        :key="index"
                    >
                        <div class="card-banner">
                            <img
                                :src="getPostImage(item.banner)"
                                alt="Post Image"
                            />
                            <div class="card-text">
                                <p>{{ item.title }}</p>

                                <router-link
                                    :to="{
                                        name: 'EditTemplate',
                                        query: {
                                            title: transformTitleToUrl(
                                                item.title
                                            ),
                                            id: item.id,
                                        },
                                    }"
                                    @click.prevent="startEditing(item)"
                                >
                                    <i class="fa fa-pencil"></i>Start editing
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="discover-btn" v-if="moreToShow">
                    <button
                        type="button"
                        @click="loadMore"
                        :disabled="isLoading"
                    >
                        Explore more
                        <i class="fa fa-arrow-up" v-if="!isLoading"></i>

                        <span
                            v-if="isLoading"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                    </button>
                </div>
            </div>
        </div>
    </section>
    <!-- <div v-if="loading" class="text-center mt-5">
        <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div> -->

    <Subscribe />
</template>

<script>
import Subscribe from "./Subscribe.vue";
import axios from "axios";

export default {
    name: "FilteredTemplates",
    components: {
        Subscribe,
    },
    data() {
        return {
            currentPage: 1,
            itemsPerPage: 8,
            sortBy: "popular",
            filteredTemplates: [],
            allTemplates: [],
            loading: true,
            postCount: 0,
            isLoading: false,
        };
    },
    created() {
        this.fetchData();
    },
    watch: {
        "$route.query.search": "fetchFilteredTemplates",
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                this.isLoading = true;

                const response = await axios.get(
                    process.env.VUE_APP_API_URL + "/filter-posts",
                    {
                        params: {
                            search: this.$route.query.search,
                            page: this.currentPage,
                            item: this.itemsPerPage,
                        },
                    }
                );
                if (response.status === 200) {
                    this.allTemplates = [
                        ...this.allTemplates,
                        ...response.data.post,
                    ];

                    this.postCount = response.data.count;

                    // this.allTemplates = response.data.post;
                    this.fetchFilteredTemplates();
                }
            } catch (error) {
                console.error("Error retrieving data:", error);
            } finally {
                this.loading = false;
                this.isLoading = false;
            }
        },
        // fetchFilteredTemplates() {
        //     const search = this.$route.query.search;
        //     if (search) {
        //         this.filteredTemplates = this.allTemplates.filter((item) =>
        //             item.title.toLowerCase().includes(search.toLowerCase())
        //         );
        //     } else {
        //         this.filteredTemplates = this.allTemplates;
        //     }
        // },
        startEditing(item) {
            this.scrollToTop();
            const userData = localStorage.getItem("USER");
            const token = JSON.parse(localStorage.getItem("TOKEN"));

            const user = userData ? JSON.parse(userData) : null;
            axios.post(process.env.VUE_APP_API_URL + "/increment-clicks", {
                postId: item.id,
            });
            if (user) {
                axios
                    .post(
                        process.env.VUE_APP_API_URL + `/user-activities`,
                        {
                            postId: item.id,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token.token}`,
                                "Content-Type": "application/json",
                            },
                        }
                    )
                    .catch((error) => {
                        console.error("Error logging user activity:", error);
                    });
            }
        },
        async loadMore() {
            this.currentPage += 1;
            await this.fetchData();
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        getPostImage(banner) {
            return banner;
        },
        transformTitleToUrl(title) {
            return title.replace(/\s+/g, "-").toLowerCase();
        },
    },
    computed: {
        sortedTemplates() {
            let sortedData = [...this.allTemplates];
            if (this.sortBy === "popular") {
                sortedData.sort((a, b) => b.clicks - a.clicks);
            } else if (this.sortBy === "title") {
                sortedData.sort((a, b) => a.title.localeCompare(b.title));
            }
            return sortedData;
        },
        sortedAndSlicedTemplates() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            return this.sortedTemplates.slice(
                startIndex,
                startIndex + this.itemsPerPage
            );
        },
        moreToShow() {
            // return (
            //     // this.currentPage * this.itemsPerPage <
            //     // this.filteredTemplates.length
            // );
            return this.allTemplates.length < this.postCount;
        },
    },
};
</script>

<style scoped>
/* Add your styles here */
</style>
