import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import LoginPage from "../components/auth/LoginPage.vue";
import HomePage from "../components/HomePage.vue";
import SignUp from "../components/auth/SignUp.vue";
// import NotFound from "../components/NotFound.vue";
import FilteredTemplates from "../components/FilteredTemplates.vue";
import EditTemplate from "../components/EditTemplate.vue";
import Embed from "../components/component/Embed.vue";
import ContactUs from "../components/ContactUs.vue";
import FAQ from "../components/FAQ.vue";
import PrivacyPolicy from "../components/PrivacyPolicy.vue";
import TermsCondition from "../components/TermsCondition.vue";
import AboutUs from "../components/AboutUs.vue";
import UserActivities from "../components/UserActivities.vue";
import PostData from "../components/PostData.vue";
import UserBanner from "../components/UserBanner.vue";
import AllPosts from "../components/admin/AllPosts.vue";
import AddPost from "../components/admin/AddPost.vue";
import UpdatePost from "../components/admin/UpdatePost.vue";
import AllCategory from "../components/admin/AllCategory.vue";
import AddCategory from "../components/admin/AddCategory.vue";
import UpdateCategory from "../components/admin/UpdateCategory.vue";
import AllExplore from "../components/admin/AllExplore.vue";
import AddExplore from "../components/admin/AddExplore.vue";
import UpdateExplore from "../components/admin/UpdateExplore.vue";
import Dashboard from "../components/admin/Dashboard.vue";
import ForgotPassword from "../components/auth/ForgotPassword.vue";
import ResetPassword from "../components/auth/ResetPassword.vue";
import CustomCode from "../components/admin/CustomCode.vue";



const routes = [
  {
    path: "/login",
    component: LoginPage,
    meta: { hideHeader: true, hideFooter: true, hideSideBar: true },
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    meta: { hideHeader: true, hideFooter: true, hideSideBar: true },
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    meta: { hideHeader: true, hideFooter: true, hideSideBar: true },
  },
  {
    path: "/signUp",
    component: SignUp,
    meta: { hideHeader: true, hideFooter: true, hideSideBar: true },
  },
  {
    path: "/",
    component: HomePage,
    meta: { requiresAuth: false, hideSideBar: true },
  },
  // {
  //   path: "/:pathMatch(.*)*",
  //   component: NotFound,
  // },

  {
    path: "/banner/:name",
  name: "PostData",
    component: PostData,
    meta: { requiresAuth: false, hideSideBar: true },
  },
  {
    path: "/category/:name",
    redirect: to => {
      return { name: "PostData", params: { name: to.params.name } };
    }
  },
  {
    path: "/filtered-banner",
    name: "FilteredTemplates",
    component: FilteredTemplates,
    meta: { requiresAuth: false, hideSideBar: true },
  },
  {
    path: "/add-banner",
    name: "UserBanner",
    component: UserBanner,
    meta: { requiresAuth: true, hideSideBar: true, hideFooter: true },
  },
  {
    path: "/edit",
    name: "EditTemplate",
    component: EditTemplate,
    meta: { requiresAuth: false, hideSideBar: true },
  },

  {
    path: "/embed",
    name: "EmbedTemplate",
    component: Embed,
    meta: { hideHeader: true, hideFooter: true, hideSideBar: true },
  },
  {
    path: "/contact",
    name: "ContactUs",
    component: ContactUs,
    meta: { requiresAuth: false, hideSideBar: true },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
    meta: { requiresAuth: false, hideSideBar: true },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: { requiresAuth: false, hideSideBar: true },
  },
  {
    path: "/terms-condition",
    name: "TermsCondition",
    component: TermsCondition,
    meta: { requiresAuth: false, hideSideBar: true },
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
    meta: { requiresAuth: false, hideSideBar: true },
  },
  {
    path: "/activity",
    name: "UserActivities",
    component: UserActivities,
    meta: { requiresAuth: true, hideSideBar: true },
  },
  {
    path: "/admin/all-posts",
    name: "AllPosts",
    component: AllPosts,
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideFooter: true,
      isAdminRoute: true,
    },
  },
  {
    path: "/admin/add-post",
    name: "AddPost",
    component: AddPost,
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideFooter: true,
      isAdminRoute: true,
    },
  },
  {
    path: "/admin/custom-code",
    name: "CustomCode",
    component: CustomCode,
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideFooter: true,
      isAdminRoute: true,
    },
  },

  {
    path: "/post/edit/:id?",
    name: "UpdatePost",
    component: UpdatePost,
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideFooter: true,
      isAdminRoute: true,
    },
  },

  {
    path: "/admin/all-categories",
    name: "AllCategory",
    component: AllCategory,
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideFooter: true,
      isAdminRoute: true,
    },
  },

  {
    path: "/admin/add-category",
    name: "AddCategory",
    component: AddCategory,
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideFooter: true,
      isAdminRoute: true,
    },
  },

  {
    path: "/category/edit/:id?",
    name: "UpdateCategory",
    component: UpdateCategory,
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideFooter: true,
      isAdminRoute: true,
    },
  },
  {
    path: "/admin/all-explore",
    name: "AllExplore",
    component: AllExplore,
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideFooter: true,
      isAdminRoute: true,
    },
  },

  {
    path: "/admin/add-explore",
    name: "AddExplore",
    component: AddExplore,
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideFooter: true,
      isAdminRoute: true,
    },
  },

  {
    path: "/explore/edit/:id?",
    name: "UpdateExplore",
    component: UpdateExplore,
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideFooter: true,
      isAdminRoute: true,
    },
  },

  {
    path: "/admin/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideFooter: true,
      isAdminRoute: true,
    },
  },
  {
    path: "/admin",
    redirect: { name: "Dashboard" },
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideFooter: true,
      isAdminRoute: true,
    },
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = JSON.parse(localStorage.getItem("TOKEN"));
  const role = JSON.parse(localStorage.getItem("ROLE"));
  window.scrollTo(0, 0);

  if (to.meta.requiresAuth) {
    if (token) {
      axios
        .get(process.env.VUE_APP_API_URL + "/verifyToken", {
          headers: {
            Authorization: `Bearer ${token.token}`,
          },
        })
        .then((res) => {
          localStorage.setItem("ROLE", JSON.stringify(res.data.role));
          localStorage.setItem("USER", JSON.stringify(res.data.user));

          if (to.meta.isAdminRoute && role.slug !== "admin") {
            next("/admin/not-found");
          } else {
            next();
          }
        })
        .catch(() => {
          next("/login");
        });
    } else {
      next("/not-found");
    }
  } else {
    next();
  }
});

export default router;
