<template>
    <section class="pick-banner category-banner" v-if="shouldShowBanner">
        <div class="container">
            <h2 class="section-heading">Related templates</h2>
            <p class="section-subheading mt-2">
                Unlock creativity with our diverse template collection –
                tailored for every creative need.
            </p>
            <div>
                <div class="row">
                    <div
                        class="col-lg-3 col-md-4"
                        v-for="(item, index) in sortedAndSlicedTemplates"
                        :key="index"
                    >
                        <div class="card-banner">
                            <img
                                :src="getPostImage(item.banner)"
                                alt="Post Image"
                            />
                            <div class="card-text">
                                <p>{{ item.title }}</p>
                                <router-link
                                    :to="{
                                        name: 'EditTemplate',
                                        query: {
                                            title: transformTitleToUrl(
                                                item.title
                                            ),
                                            id: item.id,
                                        },
                                    }"
                                    @click.prevent="startEditing(item)"
                                >
                                    <i class="fa fa-pencil"></i>Start editing
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="discover-btn" v-if="moreToShow">
                    <button type="button" @click="loadMore">
                        Explore more <i class="fa fa-arrow-up"></i>
                    </button>
                </div>
            </div>
        </div>
    </section>

    <Subscribe v-else />
</template>

<script>
import axios from "axios";
import Subscribe from "./Subscribe.vue";

export default {
    name: "CategoryPage",
    props: {
        imageId: {
            type: String,
            required: true,
        },
    },
    components: {
        Subscribe,
    },
    data() {
        return {
            currentPage: 1,
            itemsPerPage: 8,
            allTemplates: [],
            filteredTemplates: [],
        };
    },
    created() {
        this.fetchData();
    },
    watch: {
        "$route.query.id": "fetchFilteredTemplates",
    },
    methods: {
        async fetchData() {
               const userData = localStorage.getItem("USER");
            const user = userData ? JSON.parse(userData) : null;
            const userID = user ? user.id : null;
            const id = parseInt(this.$route.query.id);

            try {
                const url =
                    process.env.VUE_APP_API_URL +
                    `/edit-post?post_id=${id}` +
                    (userID ? `&user_id=${userID}` : "");
                const response = await axios.get(url);
                if (response.status === 200) {
                    const posts = response.data.category[0].posts;
                    this.allTemplates = posts;
                    this.fetchFilteredTemplates();
                }
            } catch (error) {
                console.error("Error retrieving data:", error);
            }
        },
        fetchFilteredTemplates() {
            const imageId = this.$route.query.id;
            const selectedTemplate = this.allTemplates.find(
                (template) => template.id == imageId
            );
            this.categories = selectedTemplate;
            const idCategory = this.categories.category;
            // if (idCategory.startsWith("psl-")) {
            //   this.filteredTemplates = this.filteredTemplates.filter(
            //     (item) =>
            //       item.category.startsWith("psl-") &&
            //       item.banner !== selectedTemplate.banner
            //   );
            // } else {
            this.filteredTemplates = this.allTemplates.filter(
                (item) =>
                    item.category === idCategory &&
                    item.banner !== selectedTemplate.banner
            );
            // }
        },
        startEditing(item) {
            this.scrollToTop();
            const userData = localStorage.getItem("USER");
            const token = JSON.parse(localStorage.getItem("TOKEN"));
            const user = userData ? JSON.parse(userData) : null;
            axios.post(process.env.VUE_APP_API_URL + "/increment-clicks", {
                postId: item.id,
            });
            if (user) {
                axios
                    .post(
                        process.env.VUE_APP_API_URL + `/user-activities`,
                        {
                            postId: item.id,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token.token}`,
                                "Content-Type": "application/json",
                            },
                        }
                    )
                    .catch((error) => {
                        console.error("Error logging user activity:", error);
                    });
            }
            this.$emit("related-template-clicked", item);
        },
        loadMore() {
            this.itemsPerPage *= 2;
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        getPostImage(base64ImageData) {
            return base64ImageData;
            // return "data:image/png;base64," + base64ImageData;
        },
        transformTitleToUrl(title) {
            return title.toLowerCase().replace(/\s+/g, "-");
        },
    },
    computed: {
        sortedAndSlicedTemplates() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            return this.filteredTemplates.slice(
                startIndex,
                startIndex + this.itemsPerPage
            );
        },

        moreToShow() {
            return (
                this.currentPage * this.itemsPerPage <
                this.filteredTemplates.length
            );
        },
        shouldShowBanner() {
            return this.filteredTemplates.length > 0;
        },
    },
};
</script>
